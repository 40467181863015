.convoCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 16px;
}

.chatProfile {
  border-radius: 50%;
}

.entityCont {
  width: 240px;
}

.entityName,
.entityDesc {
  margin: 0 0 0 0;
}
