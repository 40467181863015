form {
  padding: 0;
}
.modal-dialog {
  align-items: center;
}

.modal-content {
  align-items: center;
  text-align: center;
  border: 0px;
  border-radius: 40px;
  background-color: #f8f8ff;
  width: 659px;
  height: 490px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0px;
}

.thank-you-msg {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
  margin-top: 32px;
  margin-bottom: 33px;
}

.modal-text {
  width: 540px;
  height: 69px;
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
  padding: 0px;
  margin-left: 60px;
  margin-right: 60px;
}

.modal-header {
  border: 0px;
  padding: 0px;
  margin-top: 48px;
}

.modal-body {
  padding: 0px;
  padding-top: 0;
}

.modal-footer {
  border: 0px;
  padding: 0px;
  margin-top: 32px;
  margin-bottom: 44px;
}

.btn {
  padding: 0px;
  background: #ff531a;
  color: #f8f8ff;
  width: 178px;
  height: 44px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  border: 0px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-submit {
  width: 178px;
  height: 44px;
  color: #f8f8ff;
  font-size: 20px;
  font-weight: bold;
  background-color: #ff531a;
  border-color: #ff531a;
  border-radius: 16px;
  margin: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 300ms;
}

.btn-submit:hover {
  color: #f8f8ff;
}

.modal {
  position: fixed;
  top: calc(50% - 301px);
}

.CtaArea {
  width: 520px;
  height: 88px;
}

.Ctae {
  width: 318px;
  height: 28px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 24px;
}

.submit-button {
  position: relative;
  background: #ff531a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: bold;
  border-radius: 16px;
  border: 0px;
  width: 178px;
  height: 44px;
  margin-left: 20px;
  transition: 300ms;
}

.submit-button:hover {
  background: #de4917;
  transform: scale(0.98);
}

.submit-button-err {
  top: -31px;
  cursor: not-allowed;
}

.cta2-email {
  color: #717070;
  padding: 1%;
  background: #f8f8ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0px solid #f8f8ff;
  width: 318px;
  float: left;
  margin: 0;
  transition: 200ms;
}

.cta2-email:focus {
  transform: scale(1.05);
}

.error {
  border: 3px solid #ff0000;
}

.err-sign {
  position: relative;
  float: left;
  top: 6px;
  right: 40px;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .CtaArea {
    width: 375px;
  }

  .submit-button {
    margin: 16px 0 0 0;
  }

  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }
  .modal-content {
    align-items: center;
    text-align: center;
    border: 0px;
    background-color: #f8f8ff;
    width: unset;
    margin-inline: -10px;
  }
  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding: 0px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .cta2-email {
    width: 365px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }
  .modal-content {
    align-items: center;
    text-align: center;
    border: 0px;
    background-color: #f8f8ff;
    width: unset;
    margin-inline: -10px;
  }
  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding: 0px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .submit-button {
    margin: 28px 0 0 0;
  }

  .CtaArea {
    width: 345px;
  }

  form {
    width: 345px;
  }

  .cta2-email {
    width: 345px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }
  .modal-content {
    align-items: center;
    text-align: center;
    border: 0px;
    background-color: #f8f8ff;
    width: unset;
    margin-inline: -10px;
  }
  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding: 0px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .submit-button {
    margin: 28px 0 0 0;
  }

  .CtaArea {
    width: 280px;
  }

  .Ctae {
    font-weight: lighter;
    font-size: 16px;
  }

  form {
    width: 280px;
  }

  .cta2-email {
    width: 280px;
  }
}
