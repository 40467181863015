.Smi {
  border-radius: 8px;
  transition: transform 100ms, box-shadow 600ms;
}

.Smi:hover {
  transform: translate(0px, -10%);
  box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.322);
}

/* Smi stands for Social Media Icon */

.bld {
  font-weight: bold;
}

#ContactUs {
  text-align: unset;
}

#consec {
  margin-top: 0;
}

#consec a {
  text-decoration: underline;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #ContactUs {
    text-align: center;
  }

  #consec {
    margin-top: 78px;
    margin-bottom: 56px;
  }

  .mobile {
    margin-top: 72px;
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  #ContactUs {
    text-align: unset;
  }

  #consec {
    margin: 0;
  }

  .mobile {
    width: auto;
  }

  .Smi {
    margin: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  #ContactUs {
    text-align: unset;
  }
  #consec {
    margin: 0;
  }
  .mobile {
    width: auto;
  }
  .Smi {
    margin: 12px;
  }
}
