.carousel-slide-label {
  color: #000;
  font-family: 'Open Sans';
  font-size: 17px;
  font-weight: 700;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 8px;
}

.carousel-number-container {
  border: 1px solid #fff;
  border-radius: 8px;
  padding-inline: 20px;
  padding-block: 10px;
  filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  color: #000;
  font-size: 25px;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 700;
}

.carousel-number-title {
  color: #000;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  width: 70px;
  text-align: center;
  margin: auto;
  padding-top: 8px;
}
.prc-dotGroup {
  position: absolute;
  text-align: center;
  bottom: 0;
  padding-top: 8px;
  padding-bottom: 16px;
}
.carousel__dot {
  height: 15px;
  width: 15px;
  margin: 4px;
  border-radius: 50%;
  border: 1px solid black;
}
.carousel__dot--selected {
  background-color: black;
}
