@media (min-width: 250px) and (max-width: 560px) {
  .searchBarComponent {
    display: flex;
    flex-direction: column;
  }

  .searchBar {
    border: 2px solid gray;
    width: 375px;
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    justify-content: center;
    margin: auto;
  }

  .searchBarProfilePicture img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    top: 5px;
  }

  .searchInputArea {
    display: flex;
    flex-direction: row;
    width: 256px;
  }

  .searchBarLabel {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    position: relative;
    left: 40px;
  }

  .searchBarLabel span {
    position: relative;
    left: 3px;
    bottom: 1px;
  }

  .magnifyingGlass {
    height: 17px;
    width: 17px;
    margin: auto;
  }

  .searchInputArea input[type='text'] {
    width: 256px;
    height: 32px;
    background: #fff;
    border: 1px solid #263238;
    border-radius: 4px;
    font-size: 13px;
    padding-left: 44px;
    font-weight: 400;
    margin-top: 5px;
  }

  .emailIconSearchBar {
    width: 18px;
    height: 14px;
    position: relative;
    top: 15px;
  }

  .grayPlaceholder {
    width: 375px;
    height: 8px;
    background: #d8d8d8;
    margin: auto;
  }

  .searchResults {
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    width: 375px;
    height: 586px;
    overflow-y: auto;
    margin: auto;
  }

  .matchingUser {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    border: 1px solid #000;
    height: 48px;
    width: auto;
    border-radius: 4px;
  }

  .searchBarProfilePictureResults img {
    width: 50px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    margin: auto;
    left: 5px;
  }

  .matchingUserInformation {
    display: flex;
    flex-direction: column;
  }

  .matchingUserFullName {
    float: left;
    position: relative;
    height: 22px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    left: 6px;
    top: 4px;
  }

  .matchingUserLocation {
    float: left;
    position: relative;

    width: auto;
    height: 16px;
    left: 82px;
    top: 198px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #000000;

    left: 5px;
    top: 4px;
  }

  .showMoreResults {
    width: 200px;
    height: 22px;
    left: 16px;
    top: 494px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    color: #2c77e7;
  }
}
