@media (min-width: 561px) and (max-width: 1049px) {
  .signupPagenavBar:first-child {
    max-height: 119px;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: hidden;
  }

  .signupPagenavBarContents {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin: auto;
  }

  .signupPagesignUpArea {
    margin: auto;
    display: flex;
  }

  .signupPageactionText {
    padding-top: 16px;
    padding-right: 24px;
    font-weight: 500;
    columns: #263238;
  }

  .signupPagesignUpArea p {
    font-size: 18px;
  }

  .signupPagelogo {
    display: inline-flex;
    position: relative;
  }

  .signupPagenavbarLogo {
    margin-left: 36px;
  }

  .signupPageloginButton {
    width: 178px;
    height: 44px;
    background-color: #faf9f9;
    color: blue;
    text-decoration: underline;
    text-align: center;
    border-radius: 16px;
    border: none;
    outline: none;
    margin: 6px;
    font-size: 20px;
  }

  .signupPageformContainer {
    min-height: 812px;
    padding-top: 30px;
    margin: auto;
  }

  .signupPagesignupModal {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signupPagetitleSignup {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    padding-top: 40px;
  }

  .signupPagesignupForm {
    position: relative;
    margin: auto;
    width: 95%;
  }

  .signupPageformPlacement {
    width: 100%;
    margin: auto;
  }

  .signupPageentityText {
    padding-bottom: 120px;
    margin: auto;
    text-align: center;
    font-weight: 600;
  }

  .signupPagestepOne {
    width: 100%;
    margin: auto;
  }

  .signupPagesignupForm .signupPagestepOne .signupPagenextButton {
    float: right;
    right: -7%;
    top: 220px;
  }

  .signupPagepreviousButton {
    width: 165px;
    height: 44px;
    background-color: #faf9f9;
    color: blue;
    text-align: center;
    border-radius: 16px;
    border: 2px solid #2c77e7;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagepreviousButton {
    color: #2c77e7;
  }

  .signupPagenextButton {
    position: relative;
    width: 165px;
    height: 44px;
    background-color: rgba(44, 119, 231, 0.7);
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagenextButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .signupPagepaginationButtons {
    min-width: 378px;
    display: flex;
    flex-direction: row;
    column-gap: 6%;
    margin-top: 185px;
    margin-left: 180px;
  }

  .signupPageindividualDiv {
    margin: auto;
    width: 345px;
    height: 60px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-bottom: 28px;
  }

  .signupPageindividualDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageindividualDiv:focus,
  .signupPageindividualDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageindividualDiv p {
    background-color: #fff;
    margin-left: 17%;
    width: 30%;
    height: 30px;
    position: relative;
    bottom: 33px;
    overflow-y: hidden;
  }

  .signupPageindividualDiv img {
    width: 32px;
    height: 32px;
    margin-left: 15px;
    margin-top: 15px;
  }

  .signupPagenonProfitDiv {
    margin: auto;
    width: 345px;
    height: 60px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-bottom: 28px;
  }

  .signupPagenonProfitDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPagenonProfitDiv:focus,
  .signupPagenonProfitDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPagenonProfitDiv p {
    background-color: #fff;
    margin-left: 18%;
    width: 30%;
    height: 28px;
    position: relative;
    bottom: 32px;
    overflow-y: hidden;
  }

  .signupPagenonProfitDiv img {
    width: 32px;
    height: 32px;
    margin-left: 15px;
    margin-top: 15px;
  }

  .signupPageorganizationDiv {
    margin: auto;
    width: 345px;
    height: 60px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPageorganizationDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageorganizationDiv:focus,
  .signupPageorganizationDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageorganizationDiv p {
    background-color: #fff;
    margin-left: 16%;
    width: 35%;
    height: 44px;
    position: relative;
    bottom: 36px;
    overflow-y: hidden;
  }

  .signupPageorganizationDiv img {
    width: 39px;
    height: 39px;
    margin-left: 8px;
    margin-top: 9px;
  }

  .signupPageOAuthSection {
    width: 377px;
    display: flex;
    flex-direction: column;
    row-gap: 1px;
  }

  .signupPageOAuthButtons {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
  }

  .fancyLines {
    border: 1px solid #000000;
    height: 2px;
    width: 150px;
  }

  .wordBetweenLines {
    position: relative;
    bottom: 1px;
  }

  .signupPagelines {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    padding-top: 2px;
    height: 10px;
    justify-content: center;
  }

  .googleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 33px;
    left: 15px;
  }

  .appleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 32px;
    left: 15px;
  }

  .googleOAuthButton {
    width: 372px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .appleOAuthButton {
    width: 372px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .verticalLayoutSignup {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    margin-left: 17px;
  }

  .signupPageformPlacement .signupPagepaginationButtons {
    position: relative;
    bottom: 45px;
    right: 83px;
  }

  .signupPagepaginationButtons.userSignupInfo.usersInfoFormSubClass {
    position: relative;
    left: 20px;
    top: 100px;
    margin: 0 auto;
    width: 50%;
  }

  .signupPageuserForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .entitySignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    top: 100px;
    right: 15px;
    margin: 0 auto;
    width: 50%;
  }

  .entitySignupForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .entitySignupForm.signupPagepaginationButtons {
    position: relative;
    left: -96px !important;
  }

  .userSignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    top: 50px;
    left: 20px;
    margin: 0 auto;
    width: 50%;
  }

  .signupPageuserInfoForm {
    min-height: 448px;
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    align-items: center;
  }

  .signupPageuserInfoForm .wordBetweenLines {
    top: 0.5px;
  }

  .signupPageuserSignUpForm .signupPagedobUser {
    position: relative;
    right: 97%;
  }

  .signupPageemailInput {
    display: flex;
    flex-direction: column;
  }

  .signupPagepasswordInput {
    display: flex;
    flex-direction: column;
  }

  .loginPagepasswordIcon {
    position: relative;
    margin-top: 5px;
    margin-left: 25px;
    height: 22px;
    width: 22px;
  }

  .entitySignupForm.signupPagepaginationButtons {
    position: relative;
    left: 40px;
  }

  .entitySignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    left: -10px;
  }

  .signupPageuserInfoForm input {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    width: 372px;
    height: 44px;
    padding-left: 2%;
    margin-left: 15px;
  }

  .signupPageuserInfoForm label {
    font-weight: 400;
    font-size: 18px;
    margin-left: 15px;
  }

  .signupPageuserSignUpForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageentityLocationForm {
    margin-top: 14px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
  }

  .signupPageentityLocationForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageuserSignUpForm .signupPagepaginationButtons {
    position: relative;
    right: 83px;
    bottom: 15px;
  }

  .signupPageentityLocationForm > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .signupPageentityLocationForm > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }

  .signupPageentityLocationForm > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
  }

  .signupPageentityLocationForm > div:nth-child(3) {
    margin: auto;
    position: relative;
    left: 7px;
    top: 297px;
  }

  .signupPageentityLocationForm input {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    width: 353px;
    height: 44px;
    padding-left: 2%;
  }

  .signupPageentityLocationForm label {
    font-weight: 400;
    font-size: 18px;
  }

  .signupPagecausesExtends {
    margin: 0px 110px 0px 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signupPagecausesExtends .signupPagepaginationButtons {
    position: relative;
    right: 86px;
    bottom: 84px;
  }

  .signupPagecausesExtends > div:nth-child(1) {
    font-weight: 600;
    font-size: 16px;
  }

  .signupPagecause:hover {
    background: #ffffff;
    border: 1px solid #2c77e7;
    box-sizing: border-box;
    border-radius: 5px;
    color: #2c77e7;
    height: 80%;
  }

  .signupPageuserSignUpForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 20px;
    margin-top: 1px;
    margin-left: 22px;
  }

  .signupPageuserSignUpForm .signupPagepaginationButtons {
    margin-top: 27px;
  }

  .signupPageuserSignUpForm input {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    width: 345px;
    height: 44px;
    padding-left: 2%;
  }

  /* Space out each form block */
  .signupPageuserSignUpForm > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  .signupPageuserSignUpForm > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
  }

  .signupPageuserSignUpForm > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
  }

  .signupPageuserSignUpForm > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
  }

  .signupPageuserSignUpForm > div:nth-child(4) {
    display: flex;
    flex-direction: row;
    position: relative;
    bottom: 25px;
    width: 95%;
  }

  .signupPageuserSignUpForm > div:nth-child(4) > div:nth-child(1) input {
    min-width: 135px;
    margin-right: 15px !important;
    padding-left: 15px;
  }

  .signupPageuserSignUpForm > div:nth-child(4) > div:nth-child(n + 1) input {
    width: 90px;
    margin-right: 15px !important;
    padding-left: 15px;
  }

  /* Set the causes display as a column flex */
  .signupPagecausesDiv .causeRender {
    width: auto;
    display: flex;
    flex-direction: column;
    column-gap: 16px;
    row-gap: 16px;
  }

  /* Select each individual div within a cause block, flex it */
  .causeRender > div:nth-child(n) > div {
    border: 2px solid #d8d8d8;
    background: #fff;
    border-radius: 8px;
    height: 44px;
    color: #717070;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    margin-right: 15px;
  }

  /* Then make those individual cause blocks, flex rows */
  .causeRender > div:nth-child(n) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 16px;
  }

  .signupPagecheckboxAll {
    display: flex;
    flex-direction: row;
    align-self: start;
    position: relative;
    top: 25px;
  }

  .signupPagecheckboxAll > div:first-child input {
    height: 23px;
    width: 23px;
    margin-right: 8px;
  }

  .signupPagecheckboxAll > div:last-child label {
    position: relative;
    bottom: 2px;
  }

  .signupPageavailabilityDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signupPageavailabilityDisplay input {
    width: 94px;
    height: 40px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPageavailabilityDisplay label {
    font-size: 18px;
    color: #717070;
  }

  .signupPageavailabilityDisplay input[type='checkbox'] {
    width: 24px;
    height: 24px;
    position: relative;
    top: 8px;
  }

  /* The individual day/time rows & spacing between */
  .signupPageavailabilityDisplay > div:nth-child(n) {
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 25px;
    margin-right: 0;
  }

  /* The checkbox day container section */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child {
    display: flex;
  }

  /* Inputs on the left side of each row */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child input {
    padding-right: 0;
    margin-right: 9px;
  }

  /* Labels on the left side of each row */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child label {
    padding-right: 0;
    margin-right: 15px;
    position: relative;
    font-size: 14px;
  }

  /* Inputs on the right side of each row */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child {
    display: flex;
    font-size: 15px;
  }

  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child input {
    padding-left: 2%;
  }

  /* Labels on the right side of each row */
  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    input:first-child {
    display: flex;
    margin-right: 8px;
  }

  /* Middle string between both time pickers */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child label {
    display: flex;
    margin-right: 8px;
  }

  /* Next following selectors divide up space between each different day string and time picker */
  .signupPageavailabilityDisplay > div:nth-child(1) > div:first-child {
    margin-right: 23px;
  }
  .signupPageavailabilityDisplay > div:nth-child(2) > div:first-child {
    margin-right: 21px;
  }
  .signupPageavailabilityDisplay > div:nth-child(3) > div:first-child {
    margin-right: 0 !important;
  }
  .signupPageavailabilityDisplay > div:nth-child(4) > div:first-child {
    margin-right: 15px;
  }
  .signupPageavailabilityDisplay > div:nth-child(5) > div:first-child {
    margin-right: 36px;
  }
  .signupPageavailabilityDisplay > div:nth-child(6) > div:first-child {
    margin-right: 16px;
  }
  .signupPageavailabilityDisplay > div:nth-child(7) > div:first-child {
    margin-right: 25px;
  }

  .signupPageavailabilityDisplay > div:last-child {
    margin-bottom: 60px;
  }

  .signupPageavailabilityFormInput .signupPagepaginationButtons {
    justify-content: center;
    position: relative;
    right: 105px;
    bottom: 65px;
  }

  .causesPickedBlue {
    background: #2c77e7;
    color: #fff;
  }

  .stepOnesignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    left: 127px;
    padding-top: 10px;
    width: 40%;
  }

  .stepTwosignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 175px;
    padding-top: 10px;
    width: 35%;
  }

  .stepTwosignUpFormUser {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 130px;
    padding-top: 10px;
    width: 35%;
  }

  .stepThreesignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 121px;
    padding-top: 10px;
    width: 40%;
  }

  .stepThreesignUpForm.entity {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 105px;
    padding-top: 10px;
    width: 40%;
  }

  .stepFoursignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    padding-top: 10px;
    width: 30%;
  }

  .entityFinalStepForm {
    margin-top: 20px;
  }

  .stepFivesignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 185px;
    padding-top: 10px;
    width: 30%;
  }

  .signupPagemonth .dropDownArrowPlacement {
    position: relative;
    bottom: 38px;
    left: 107px;
    height: 10px;
  }

  .signupPageyear .dropDownArrowPlacement {
    position: relative;
    bottom: 38px;
    left: 63px;
    height: 10px;
  }

  .signupPageday .dropDownArrowPlacement {
    position: relative;
    bottom: 38px;
    left: 63px;
    height: 10px;
  }

  .signupPagemonth .dropDownContent {
    max-height: 138px;
    width: 135px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: relative;
    bottom: 32px;
  }

  .signupPageyear .dropDownContent {
    max-height: 138px;
    width: 90px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: relative;
    bottom: 32px;
  }

  .signupPageday .dropDownContent {
    max-height: 138px;
    width: 90px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: relative;
    bottom: 32px;
  }

  .dropDownContent {
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-width: auto;
    z-index: 5;
  }

  .dropDownContent p {
    margin-left: 14px;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.am {
    max-height: 128px;
    width: 95px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: absolute;
    margin: 37px 35px 0px 0px;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.pm {
    max-height: 128px;
    width: 95px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: absolute;
    margin: 37px 0px 0px 148px;
  }

  .amdropDownArrowPlacement {
    position: relative;
    left: -29px;
    top: 16px;
    height: 10px;
  }

  .pmdropDownArrowPlacement {
    position: relative;
    left: -47px;
    top: 16px;
    height: 10px;
  }

  .blueText {
    color: #2c77e7;
  }
}
