.chatFeedCon {
  height: 508px;
  margin: 8px 0 0 0;
  padding: 0 4% 10px 4%;
  background: #ffffff;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.messageBubble {
  background-color: #eaeaea;
  padding: 8px;
  max-width: 290px;
  border-radius: 4px;
  margin: 0 0 0 0;
}

.you {
  align-self: flex-end;
}

.other {
  align-self: flex-start;
}

.you,
.other {
  margin: 9px 0;
}

.otherFlex {
  display: flex;
}

.messageBubble p {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 14.06px;
  font-family: 'Roboto';
}

.mTimeStamp {
  margin-bottom: 0;
  font-size: 12px;
  text-align: start;
}

.other .mTimeStamp {
  margin-left: 58px;
}

.you .mTimeStamp {
  text-align: end;
}

.mProfile {
  border-radius: 50%;
  margin-right: 8px;
}
