::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

@media (min-width: 1061px) {
  .loginPage {
    width: 100%;
    height: 100vh;
    padding: 0;
    overflow-y: hidden;
  }

  .loginPagenavBar {
    height: 119px;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .loginPagenavBarContents {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin: auto;
  }

  .loginPagesignUpArea {
    margin: auto;
    display: flex;
    height: 119px;
    padding-top: 30px;
  }

  .loginPageactionText {
    padding-top: 16px;
    padding-right: 24px;
    font-weight: 500;
    columns: #263238;
  }

  .loginPagesignUpArea p {
    font-size: 18px;
  }

  .loginPagelogo {
    display: inline-flex;
    position: relative;
    height: 119px;
  }

  .loginPagenavbarLogo {
    position: relative;
    margin-left: 32px;
  }

  .loginPagesignUpButton {
    width: 178px;
    height: 44px;
    background-color: #faf9f9;
    color: #2c77e7;
    text-align: center;
    text-decoration: underline;
    border-radius: 16px;
    border: none;
    outline: none;
    margin: 6px;
    margin-right: 48px;
    font-size: 20px;
  }

  .loginPagesignUpButton:hover {
    background-color: #1553b0;
  }

  .loginPageformContainer {
    height: 90%;
    margin-bottom: 3%;
    padding-top: 2.25%;
    background: url('../../../assets/img/cta2.jpeg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.5);
  }

  .loginPageloginModal {
    padding-top: auto;
    padding-bottom: auto;
    margin-right: 9%;
    margin-left: 9%;
    align-items: center;
    border-radius: 24px;
    background-color: #faf9f9;
    display: flex;
    flex-direction: column;
    column-gap: 4px;
    align-items: center;
    font-size: 20px;
    width: 81%;
    height: 91%;
    overflow: scroll;
  }

  .loginPagetitleLogin {
    height: 54px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 6%;
    margin-bottom: 4%;
  }

  .loginPagetitleLogin p {
    font-size: 38px;
    line-height: 52px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .loginPagewarningMessage {
    color: #ff0000;
    width: 432px;
  }

  .loginPageerrorIcon {
    float: left;
    padding-top: 5px;
  }

  .loginPagewarningMessage p {
    margin-bottom: 0px;
    margin-right: 0px;
    padding-right: 25px;
  }

  .loginPageerrorDisplay {
    text-align: center;
    width: 432px;
  }

  .loginPageloginForm {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 660;
  }

  .signupPageOAuthButtons {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
  }

  .fancyLines {
    border: 1px solid #000000;
    min-height: 144px;
    width: 2px;
  }

  .wordBetweenLines {
    position: relative;
    top: 9px;
    right: 9px;
  }

  .signupPagelines {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    height: 10px;
    margin-left: 35px;
    margin-right: 35px;
  }
  /* Test */
  .googleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 33px;
    left: 15px;
  }

  .appleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 32px;
    left: 15px;
  }

  .googleOAuthButton {
    width: 296px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .appleOAuthButton {
    width: 296;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .signupPageOAUthButtonVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 60px;
  }

  .loginNoOAuth {
    display: flex;
    flex-direction: column;
    row-gap: 32px;
  }

  .loginPageformPlacement {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    margin-left: 11%;
    margin-right: 50%;
  }

  .loginPageformPlacement label {
    padding-bottom: 16px;
    position: relative;
    right: 100px;
  }

  .loginPageresetLink {
    margin-bottom: 60px;
  }

  .loginPageemailInput {
    height: 105px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    column-gap: 1px;
  }

  .loginPageemailInput label {
    position: relative;
    left: -119px;
  }

  .loginPagepasswordInput {
    height: 112px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
  }

  .loginPageloginButton {
    width: 178px;
    height: 44px;
    background-color: rgba(44, 119, 231, 0.7);
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    font-size: 20px;
    position: relative;
    top: 350px;
    right: 730px;
  }

  .loginPagesubmitButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 20px;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .loginPageloginButton:hover {
    background-color: #2c77e7;
  }

  .loginPagepasswordIcon {
    background-color: white;
    height: 22px;
    width: 22px;
    position: relative;
    bottom: 32px !important;
  }

  .loginPagevalidEmailLogin {
    color: #088f26;
    border-color: #088f26;
    border-width: 3px;
    background-image: url('../../../assets/img/success.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .loginPagevalidPassLogin {
    color: #088f26;
    border-color: #088f26;
    border-width: 3px;
    background-image: url('../../../assets/img/success.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .loginPagevalidPassLogin + img {
    right: 50px;
  }

  .loginPageinvalidEmailLogin {
    color: #f00;
    border-color: #f00;
    border-width: 3px;
    background-image: url('../../../assets/img/warning.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .loginPageinvalidPassLogin {
    color: #f00;
    border-color: #f00;
    border-width: 3px;
    background-image: url('../../../assets/img/warning.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .loginPageinvalidPassLogin + img {
    right: 50px;
  }

  .loginPagepasswordInput input[type='text'] {
    width: 296px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .loginPagepasswordInput input[type='password'] {
    overflow-y: hidden;
    width: 296px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 30px;
  }

  .loginPagepasswordInput
    input[type='password']:active::-webkit-input-placeholder {
    color: transparent;
  }

  .loginPageemailInput input[type='email'] {
    width: 296px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .loginPageemailInput input[type='email']:active::-webkit-input-placeholder {
    color: transparent;
  }

  .loginPageresetLink a {
    text-decoration: underline;
    font-size: 18px;
    height: 5px;
    margin: auto;
  }

  .loginPageformPlacement input,
  label {
    margin: auto;
    display: block;
  }
}
