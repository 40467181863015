.CreatePostTextAreaWrapper {
  border: solid 1px #263238;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}

.CreatePostTextArea {
  background-color: transparent;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  font-family: 'Roboto';
  max-height: 15rem;

  width: 100%;

  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
}

.CreatePostTextArea {
  border: none;
}

.CreatePostTextArea::placeholder {
  color: #717070;
}

.CreatePostImagesWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
}

.CreatePostImageUpload {
  height: 5rem;
  width: 5rem;
  padding: 0.25rem;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  position: relative;
  /* overflow: hidden; */
}

.CreatePostImageUploadPic {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.CreatePostRemoveImageUpload {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #f00;
  right: -0.2rem;
  top: -0.1rem;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
}
