.nav-menu-mobile {
  height: 80px;
  min-width: 375px;
  width: 100%;
  background-color: #faf9f9;
  box-shadow: 0px -2px 18px 2px #00000040;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 16px;
  user-select: none;
}

.nav-menu-mobile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #263238;
  cursor: pointer;
}

.nav-menu-mobile-item p {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.23999999463558197px;
  text-align: center;
  margin: 0;
  color: #c4c4c4;
}

.nav-menu-mobile-item svg {
  height: 22.5px;
  width: 22.5px;

  color: #c4c4c4;
}

/* Active */

.nav-menu-mobile-item-active svg {
  color: #2c77e7;
}
.nav-menu-mobile-item-active p {
  color: #263238;
}
