/* icon-button button styles */

.icon-button {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;

  padding: 0;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  background: none;
}

.icon-button-icon-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.icon-button-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button-circle svg {
  width: 24px;
  height: 24px;
  fill: #ffffff;
  stroke-width: 1px;
  stroke: #263238;
}

.icon-button-icon {
  position: relative;
  right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button-icon svg {
  height: 9px;
  width: 10px;
  left: 1px;
  top: 1px;
  border-radius: 0px;

  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 16.67%;
  fill: #263238;
}

.icon-button-text {
  height: 21px;
  position: relative;
  right: 4px;
}

/* Hover over button */

.icon-button:hover .icon-button-icon svg {
  fill: #ffffff;
}

.icon-button:hover .icon-button-circle svg {
  fill: #263238;
}

/* Active */

.icon-button-active .icon-button-circle svg {
  fill: #263238;
  stroke-width: 1px;
  stroke: #263238;
}

.icon-button-active .icon-button-icon svg {
  fill: #ffffff;
}
