@media (min-width: 250px) and (max-width: 560px) {
  .loginPagenavBar {
    height: 119px;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }

  .loginPagelogo {
    position: relative;
    margin-top: 24px;
    margin-left: 18px;
  }

  .loginPagesignUpArea {
    display: flex;
    flex-direction: row;
    margin-top: 38px;
  }

  .loginPageactionText {
    font-size: 18px;
    line-height: 21px;
    margin-right: 24px;
    color: #263238;
  }

  .loginPagenavBarContets {
    margin-right: 7px;
  }

  .loginPagesignUpButton {
    padding: 0;
    border: none;
    background: none;
    color: #2c77e7;
    text-decoration: underline;
    font-weight: 600;
    position: relative;
    bottom: 11px;
  }

  .loginPageformContainer {
    width: 375px;
    margin: auto;
  }

  .loginPageloginModal {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .loginPagetitleLogin {
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 52px;
    text-align: center;
  }

  .loginPageloginForm {
    margin-top: 90px;
  }

  .loginPageformPlacement {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .loginPageemailInput {
    padding-bottom: 20px;
  }

  .loginPagepasswordInput {
    height: 90px;
  }

  .loginPageemailInput label {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: #000;
  }

  .loginPageemailInput input {
    background: #ffffff;
    border: 2px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px;
    width: 350px;
  }

  .loginPageemailInput input[type='email']:active::-webkit-input-placeholder {
    color: transparent;
  }

  .loginPagepasswordInput
    input[type='password']:active::-webkit-input-placeholder {
    color: transparent;
  }

  .loginPagepasswordInput label {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: #000;
  }

  .loginPagepasswordInput input {
    background: #ffffff;
    border: 2px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px;
    width: 350px;
  }

  #passwordIcon {
    position: relative;
    left: 315px;
    bottom: 40px;
  }

  .loginPageresetLink {
    text-decoration: underline;
    margin-bottom: 37px;
  }

  .wordBetweenLines {
    position: relative;
    bottom: 15px !important;
  }

  .signupPagelines {
    width: 100%;
    margin: auto;
    padding-right: 0.75rem;
  }

  .fancyLines {
    width: 150px;
  }

  .signupPageOAUthButtonVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 60px;
  }

  .loginPageloginButton {
    width: 178px;
    height: 44px;
    background-color: rgba(44, 119, 231, 0.7);
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    font-size: 20px;
    position: relative;
    top: 100px;
    left: 1px;
  }

  .loginPagesubmitButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 20px;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .loginPageloginButton:hover {
    background-color: #2c77e7;
  }

  .loginPageerrorDisplay {
    max-width: 355px;
    margin-left: 39px;
  }

  .loginPagewarningMessage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: red;
  }

  .loginPageerrorIcon {
    height: 21px;
    width: 21px;
    position: relative;
    top: 5px;
    margin-right: 9px;
  }
}
