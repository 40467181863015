.card2 {
  border-style: solid;
  border-color: var(--blueBold);
  border-width: 0 0 0 4px;
  border-radius: 0 16px 16px 0;
  margin: 5% 0%;
  padding: 1% 56px;
  width: 888px;
  background-color: #ffffff;
}

.Icon {
  object-fit: scale-down;
  min-width: 126px;
}

.offset-me {
  margin-left: 8.3333334%;
}

.blueBold {
  position: relative;
  color: var(--blueBold);
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 8px;
  top: 36px;
  margin-bottom: 22px;
  width: fit-content;
}

.answer {
  font-size: 20px;
  margin-left: 40px;
  margin-top: -16px;
}

.underline {
  text-decoration: underline;
}

.question {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-left: 42px;
  margin-top: -15px;
}

.sectionFaQ {
  margin: 130px auto;
  padding: 0 44px 0 48px;
  /* height: 2290px; */
}

.msmall {
  font-size: 20px;
  font-weight: 600;
}

.mbold {
  font-size: 38px;
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .sectionFaQ {
    padding-top: 60px;
  }

  .offset-me {
    margin-left: 0;
  }

  .msmall {
    margin-bottom: 20px;
  }

  .Icon {
    margin: 86px 0 0 0;
    height: 148px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .sectionFaQ {
    padding: 0 15px;
  }

  .Icon {
    margin: 35px 0 67px 0;
    height: 148px;
  }

  .card2 {
    width: 100%;
    padding: 1% 24px;
    margin: 0 0 0 0;
  }

  .answer {
    font-size: 16px;
    line-height: 30px;
    padding-top: 5px;
  }

  .question {
    margin-top: -10px;
    font-size: 20px;
  }

  .blueBold {
    margin-bottom: 16px;
  }

  .mbold {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
  }

  .msmall {
    font-size: 16px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .sectionFaQ {
    padding: 0 15px;
  }

  .card2 {
    width: 100%;
    padding: 1% 24px;
    margin: 0 0 0 0;
  }

  .Icon {
    margin: 35px 0 67px 0;
    height: 148px;
  }

  /* .offset-me {
    margin-left: 0;
  } */

  .answer {
    font-size: 16px;
    line-height: 30px;
  }

  .tri {
    overflow-wrap: anywhere;
  }

  .question {
    font-size: 20px;
  }

  .mbold {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
  }

  .msmall {
    font-size: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .sectionFaQ {
    padding: 0 15px;
  }

  .card2 {
    width: 100%;
    padding: 1% 24px;
    margin: 0 0 0 0;
  }

  .Icon {
    margin: 35px 0 67px 0;
    height: 148px;
  }

  /* .offset-me {
    margin-left: 0;
  } */

  .answer {
    font-size: 16px;
    word-break: break-word;
    line-height: 30px;
  }

  .question {
    font-size: 20px;
  }

  .mbold {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
  }

  .msmall {
    font-size: 16px;
  }
}
