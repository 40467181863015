.postInteraction {
  font-family: Roboto;
  min-width: 343px;
  width: 100%;
  padding: 8px 16px 8px 16px;
  background-color: #faf9f9;
  user-select: none;
}

/* top portion of postInteraction section */
.postInteraction-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.postInteraction-info p {
  margin: 0;
}

.postInteraction-likes {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.postInteraction-comments {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* likes count icon */
.postInteraction-likes-icon-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.postInteraction-likes-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postInteraction-likes-circle svg {
  width: 24px;
  height: 24px;
  fill: #ff5f2a;
  stroke-width: 1px;
  stroke: #ff5f2a;
}

.postInteraction-likes-icon {
  position: relative;
  right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postInteraction-likes-icon svg {
  height: 9px;
  width: 10px;
  left: 1px;
  top: 1px;
  border-radius: 0px;

  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 16.67%;
  fill: #ffffff;
}

/* bottom portion of postInteraction */
.postInteraction-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 17px;
  border-top: 1px solid #263238;
}

.postInteraction-buttons > div + div:hover {
  background-color: #eaeaea;
}
