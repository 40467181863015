input {
  width: unset;
  height: unset;
  margin-right: unset;
}
input[type='radio'] {
  height: 20px;
  width: 20px;
}
input[type='radio']:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  /* top: -2px;
  left: -1px; */
  position: relative;
  background-color: black;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 4px solid white;
}
#unsubFormTextInput {
  margin-left: 40px;
}
.form-check-input {
  position: unset;
}
.form-check {
  margin-bottom: 8px;
}
.form-check-label {
  margin-left: 14px;
}
.modalHeaderCloseButton {
  margin-top: 28px;
  padding-right: 35px;
}
.modalTitle {
  padding-bottom: 24px;
  font-size: 38px;
  font-weight: bold;
}
.buttonRows {
  margin: unset;
  margin-left: unset;
  margin-right: unset;
}
#stay {
  margin-bottom: 24px;
}
#confirmModalBody {
  padding-bottom: 122px;
  padding-top: 12px;
}
#confirmModalFooter {
  padding-bottom: 120px;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
}
#unsubForm {
  width: unset;
  height: unset;
  margin-left: 58px;
  padding-top: 48px;
  padding-bottom: 45px;
  padding-left: 0px;
  position: unset;
  justify-content: right;
  text-align: start;
}

.modal-dialog {
  align-items: center;
}

.modal-content {
  align-items: center;
  text-align: center;
  border: 0px;
  border-radius: 40px;
  background-color: #f8f8ff;
  width: 659px;
  height: unset;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0px;
}

.orangeButton,
.orangeButton:disabled {
  background: #ff531a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.orangeButton:hover {
  background: #de4917;
  transform: scale(0.98);
}
.btn-outline {
  background: transparent;
  color: #ff531a;
  border-color: #ff531a;
  border-style: solid;
  border-width: 1px;
}
.modal-text {
  width: 540px;
  height: 69px;
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
  padding: 0px;
  margin-left: 60px;
  margin-right: 60px;
}

.modal-body {
  padding: 0px;
}

.modal-footer {
  border: 0px;
  padding: 0px;
  margin-top: unset;
  margin-bottom: unset;
  padding-bottom: 40px;
}
.modal-footer > :not(:first-child) {
  margin-left: unset;
}
.modal-footer > :not(:last-child) {
  margin-right: unset;
}

.btn:hover {
  color: #f8f8ff;
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }
  .modal-content {
    align-items: center;
    text-align: center;
    border: 0px;
    background-color: #f8f8ff;
    width: unset;
    margin-inline: -10px;
  }
  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding: 0px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }

  .modal-content {
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
  }

  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding-inline: 45px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .submit-button {
    /* top: 68px;
    right: 340px; */
    margin: 28px 0 0 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }

  .modal-content {
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
    margin-inline: 0;
  }

  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding-inline: 45px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .submit-button {
    /* top: 68px;
    right: 340px; */
    margin: 28px 0 0 0;
  }
}
