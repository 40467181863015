.CeoMessageBg {
  background-image: linear-gradient(
      92.55deg,
      rgba(44, 119, 231, 0.79) 99.99%,
      rgba(44, 119, 231, 0) 100%
    ),
    url('../../../../assets/img/Houston.png');
  height: 884px;
  width: 1440px;
  background-repeat: round;
  margin-top: 48px;
}

.Houston {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 24px;
}

.beta {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
  margin-bottom: 88px;
}

.ceoq {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 48px;
  width: 776px;
  height: 102px;
}

.Ceocta {
  width: 520px;
  margin: auto;
}

h5 {
  margin: auto;
}

.ceoName {
  width: 313px;
  height: 76px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  margin: 40px auto;
}

.ceoSpace {
  margin-bottom: 128px;
}

.MessageContainer {
  margin-top: 92px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .CeoMessageBg {
    background-position-x: 47%;
    background-position-y: center;
    background-repeat: space;
    background-size: cover;
    height: 847px;
    padding: 0 32px 0 32px;
  }

  .Houston {
    font-weight: normal;
    margin: 0 0 30px 0;
  }

  .beta {
    letter-spacing: 1px;
  }

  .ceoq {
    width: 720px;
    margin-top: 80px;
  }

  .ceoName {
    margin: 30px auto;
  }

  .ceoSpace {
    margin-bottom: 70px;
  }
  .MessageContainer {
    margin-top: 112px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .Houston {
    margin: 25px 0 16px 0;
    font-size: 16px;
    font-weight: normal;
  }

  .CeoMessageBg {
    background-repeat: space;
    background-position: 46%;
    height: 696px;
  }

  .ceoSpace {
    margin-bottom: 56px;
  }

  .ceoq {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 37px;
    width: 345px;
    margin-top: 56px;
  }

  .ceoName {
    font-size: 16px;
    margin: 16px auto;
  }

  .beta {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin-top: 0px;
    margin-bottom: 56px;
  }

  .Ceocta {
    width: 400px;
    margin: auto;
  }

  .MessageContainer {
    margin-top: 24px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .Houston {
    margin: 25px 0 16px 0;
    font-size: 16px;
    font-weight: normal;
  }

  .CeoMessageBg {
    background-repeat: space;
    background-position: 46%;
    height: 696px;
  }

  .ceoSpace {
    margin-bottom: 50px;
  }

  .ceoq {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 37px;
    width: 345px;
    margin-top: 56px;
  }

  .ceoName {
    font-size: 16px;
    margin: 16px auto;
  }

  .beta {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin-top: 0px;
    margin-bottom: 56px;
  }

  .MessageContainer {
    margin-top: 24px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .Houston {
    margin: 25px 0 16px 0;
  }

  .CeoMessageBg {
    background-repeat: space;
    background-position: 46%;
    height: 696px;
  }

  .ceoSpace {
    margin-bottom: 30px;
  }

  .ceoq {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 37px;
    width: 300px;
    margin-top: 56px;
  }

  .ceoName {
    font-size: 16px;
    margin: 16px auto;
  }

  .beta {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .MessageContainer {
    margin-top: 16px;
  }
}
