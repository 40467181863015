.mailto {
  text-decoration: underline;
}

.btn {
  padding: 0px;
  background: #ff531a;
  color: #f8f8ff;
  width: 178px;
  height: 44px;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  border: 0px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.image404 {
  display: flex;
  margin: auto;
  height: 400px;
  width: 625px;
  margin-top: 140px;
  object-fit: contain;
}

.titleText {
  font-family: 'Open Sans';
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 16px;
}

.subTitleText {
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 24px;
}

.footerText {
  font-family: 'Roboto';
  font-size: 18px;
  text-align: center;
  margin-inline: auto;
  margin-top: 96px;
  margin-bottom: 65px;
}

/* tablet small/mobile large screen range */
@media screen and (min-width: 425px) and (max-width: 991px) {
  .image404 {
    height: 325px;
    width: 600px;
    margin-top: 150px;
  }

  .titleText {
    font-size: 38px;
  }

  .subTitleText {
    font-size: 20px;
    margin-bottom: 32px;
  }

  .footerText {
    font-size: 18px;
    margin-top: 144px;
  }
}

/* mobile mid screen range */
@media screen and (min-width: 375px) and (max-width: 424px) {
  .image404 {
    height: 150px;
    width: 295px;
    margin-top: 150px;
  }

  .titleText {
    font-size: 30px;
    font-weight: 700;
    margin-top: 44px;
    margin-bottom: 16px;
  }

  .subTitleText {
    font-size: 18px;
    margin-bottom: 32px;
  }

  .footerText {
    font-size: 16px;
    max-width: 270px;
    margin-top: 120px;
  }
}

/* mobile tiny screen range */
@media screen and (min-width: 320px) and (max-width: 374px) {
  .image404 {
    height: 145px;
    width: 290px;
    margin-top: 15px;
  }

  .titleText {
    font-size: 28px;
    font-weight: 700;
    margin-top: 44px;
    margin-bottom: 16px;
  }

  .subTitleText {
    font-size: 16px;
    margin-bottom: 32px;
  }

  .footerText {
    font-size: 14px;
    max-width: 270px;
    margin-top: 80px;
  }
}
