.profile-page {
  position: relative;
  background-color: #d8d8d8;
  height: 100vh;
  margin: auto;
}

.header {
  position: absolute;
  top: 0;
}

.search-bar {
  position: absolute;
  width: 100vw;
  top: 125px;
  left: 0;
  background-color: #faf9f9;
}

.summary-carousel {
  position: absolute;
  height: 125px;
  top: 183px;
}

.user-feed {
  position: absolute;
  top: 420px;
  padding-bottom: 15%;
}

.footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}
