:root {
  --blueBold: #2c77e7;
  --background: #faf9f9;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 200px;
}

body {
  background-color: #faf9f9;
  background-color: var(--background);
  font-family: 'Open Sans';
}

h4 {
  color: #2c77e7;
  color: var(--blueBold);
  font-family: 'Open Sans';
}

.tri {
  font-family: 'Roboto';
}

@media screen and (min-width: 751px) and (max-width: 752px) {
  html,
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 425px) {
  html,
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 375px) {
  html,
  body {
    overflow-x: hidden;
  }
}

nav {
  background-color: #ffffff;
}
/* nav con is how we style the navbar container */
.navbar {
  padding: 32px 42.75px 32px 48px;
  box-shadow: 0px -75px 100px;
}
/* have to use the id to space the navbar */
#basic-navbar-nav {
  justify-content: right;
}

span.navbar-brand {
  padding: 0px;
  margin-left: 0px;
}

.nav-link {
  color: #263238 !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  background: transparent;
  margin-left: 24px;
  transition: 80ms;
}

.nav-link:hover {
  font-weight: 600;
  border-style: solid;
  border-width: 0 0 3px 0;
  border-color: #ff531a;
  border-radius: 0 0 3px 3px;
  height: 42px;
}

.nav-act {
  color: #263238 !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  background: transparent;
  margin-left: 24px;
  border-style: solid;
  border-width: 0 0 3px 0;
  border-color: #ff531a;
  border-radius: 0 0 3px 3px;
  height: 42px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar {
    padding: 24px 48px 24px 48px;
    align-items: center;
  }

  .NavCon {
    margin: 15px;
  }

  .navbar-nav {
    margin: 0px;
    text-align: center;
    justify-content: center;
  }

  .navbar-collapse {
    width: 100vw;
    height: 100vh;
    transition: linear;
    margin-top: 95px;
  }

  .navbar-toggler {
    padding: 0px;
    margin: 0px;
  }

  .nav-link {
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #2c77e7;
  }

  .nav-link:hover {
    font-weight: 600;
    height: 88px;
  }

  span.navbar-brand {
    margin: 0px;
    padding: 0px;
  }

  #no-bottom {
    border-bottom: none;
  }

  html {
    scroll-padding-top: 125px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .navbar {
    padding: 15px 16px;
    position: fixed;
    top: 0;
    width: 100%;
    /* margin-bottom: 20px; */
  }

  .navbar-collapse {
    width: 100vw;
    height: 100vh;
    transition: linear;
    margin-top: 95px;
  }

  .navbar-toggler {
    border: none;
    font-weight: bolder;
    color: #000000;
  }

  .navbar-nav {
    margin: 0px;
    text-align: center;
    justify-content: center;
  }

  .nav-link {
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #2c77e7;
  }

  .nav-link:hover {
    height: 88px;
  }
}

form {
  padding: 0;
}
.modal-dialog {
  align-items: center;
}

.modal-content {
  align-items: center;
  text-align: center;
  border: 0px;
  border-radius: 40px;
  background-color: #f8f8ff;
  width: 659px;
  height: 490px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0px;
}

.thank-you-msg {
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
  margin-top: 32px;
  margin-bottom: 33px;
}

.modal-text {
  width: 540px;
  height: 69px;
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
  padding: 0px;
  margin-left: 60px;
  margin-right: 60px;
}

.modal-header {
  border: 0px;
  padding: 0px;
  margin-top: 48px;
}

.modal-body {
  padding: 0px;
  padding-top: 0;
}

.modal-footer {
  border: 0px;
  padding: 0px;
  margin-top: 32px;
  margin-bottom: 44px;
}

.btn {
  padding: 0px;
  background: #ff531a;
  color: #f8f8ff;
  width: 178px;
  height: 44px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  border: 0px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.btn-submit {
  width: 178px;
  height: 44px;
  color: #f8f8ff;
  font-size: 20px;
  font-weight: bold;
  background-color: #ff531a;
  border-color: #ff531a;
  border-radius: 16px;
  margin: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transition: 300ms;
}

.btn-submit:hover {
  color: #f8f8ff;
}

.modal {
  position: fixed;
  top: calc(50% - 301px);
}

.CtaArea {
  width: 520px;
  height: 88px;
}

.Ctae {
  width: 318px;
  height: 28px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-bottom: 24px;
}

.submit-button {
  position: relative;
  background: #ff531a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-weight: bold;
  border-radius: 16px;
  border: 0px;
  width: 178px;
  height: 44px;
  margin-left: 20px;
  transition: 300ms;
}

.submit-button:hover {
  background: #de4917;
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}

.submit-button-err {
  top: -31px;
  cursor: not-allowed;
}

.cta2-email {
  color: #717070;
  padding: 1%;
  background: #f8f8ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 0px solid #f8f8ff;
  width: 318px;
  float: left;
  margin: 0;
  transition: 200ms;
}

.cta2-email:focus {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.error {
  border: 3px solid #ff0000;
}

.err-sign {
  position: relative;
  float: left;
  top: 6px;
  right: 40px;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .CtaArea {
    width: 375px;
  }

  .submit-button {
    margin: 16px 0 0 0;
  }

  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }
  .modal-content {
    align-items: center;
    text-align: center;
    border: 0px;
    background-color: #f8f8ff;
    width: unset;
    margin-inline: -10px;
  }
  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding: 0px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .cta2-email {
    width: 365px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }
  .modal-content {
    align-items: center;
    text-align: center;
    border: 0px;
    background-color: #f8f8ff;
    width: unset;
    margin-inline: -10px;
  }
  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding: 0px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .submit-button {
    margin: 28px 0 0 0;
  }

  .CtaArea {
    width: 345px;
  }

  form {
    width: 345px;
  }

  .cta2-email {
    width: 345px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }
  .modal-content {
    align-items: center;
    text-align: center;
    border: 0px;
    background-color: #f8f8ff;
    width: unset;
    margin-inline: -10px;
  }
  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding: 0px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .submit-button {
    margin: 28px 0 0 0;
  }

  .CtaArea {
    width: 280px;
  }

  .Ctae {
    font-weight: lighter;
    font-size: 16px;
  }

  form {
    width: 280px;
  }

  .cta2-email {
    width: 280px;
  }
}

.Cta2-hero {
  background-image: linear-gradient(
      92.55deg,
      rgba(44, 119, 231, 0.79) 99.99%,
      rgba(44, 119, 231, 0) 100%
    ),
    url(/static/media/cta2.5d6df2c1.png);
  height: 376px;
  background-size: cover;
  margin-top: 168px;
}

.cta2 {
  padding-top: 8rem;
  font-family: 'Open sans';
  font-size: 38px;
  line-height: 51.75px;
}

.cta2-form {
  font-size: 20px;
  line-height: 30px;
  position: relative;
  left: 0px;
  top: 0px;
  padding-left: 30px;
}

.cta2-h2 {
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
}

.cta2-h3 {
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
}

.cta2-mobile {
  padding-left: 48px;
}
/* .concen {
  justify-content: unset;
} */

@media screen and (min-width: 1200px) and (max-width: 1350px) {
  .cta2-form {
    padding-left: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .cta2 {
    padding-top: 0;
    width: 100%;
  }

  .cta2-mobile {
    margin-top: 40px;
    text-align-last: center;
    width: -webkit-max-content;
    width: max-content;
  }

  .cta2-form {
    text-align: -webkit-center;
    margin-top: 90px;
  }

  .Cta2-hero {
    width: 100%;
  }
  /* .concen {
    justify-content: center;
  } */
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Cta2-hero {
    height: 379px;
    background-position-x: center;
    padding-bottom: 450px;
    padding-top: 50px;
  }

  .cta2 {
    padding-top: 2rem;
    width: 768px;
    padding-left: 32px;
  }

  .cta2-h2,
  .cta2-h3 {
    margin: 0;
    padding: 0;
  }

  .cta2-mobile {
    margin-top: 25px;
    padding-left: 32px;
  }

  .cta2-form {
    margin-top: 60px;
    padding: 0 0 0 30px;
    text-align: unset;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .cta2 {
    padding: 40px 15px;
    width: 100%;
  }
  .cta2-h2 {
    font-size: 32px;
    line-height: 43px;
  }
  .cta2-h3 {
    font-size: 32px;
    line-height: 43px;
  }

  .Cta2-hero {
    height: 468px;
    background-position: 63%;
    width: 100%;
    margin-top: 96px;
  }

  .cta2-mobile {
    margin-top: 0;
    text-align-last: unset;
    padding-left: 30px;
  }

  .cta2-form {
    margin-top: 30px;
    padding: 0 30px;
    text-align: unset;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .cta2 {
    padding: 40px 15px;
    width: 375px;
  }
  .cta2-h2 {
    font-size: 32px;
    line-height: 43px;
  }
  .cta2-h3 {
    font-size: 32px;
    line-height: 43px;
  }
  .Cta2-hero {
    height: 458px;
    background-position: 63%;
    width: 100%;
    margin-top: 48px;
  }
  .cta2-mobile {
    padding: 0;
    margin: 40px 15px;
  }

  .cta2-form {
    margin-top: 0;
    padding: 0 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .cta2 {
    padding: 40px 15px;
    width: 375px;
  }
  .cta2-h2 {
    font-size: 30px;
    line-height: 43px;
  }
  .cta2-h3 {
    font-size: 30px;
    line-height: 43px;
  }
  .Cta2-hero {
    height: 458px;
    background-position: 63%;
    width: 100%;
  }
  .cta2-mobile {
    margin-bottom: 50px;
    margin-top: 0;
    padding-left: 5px;
  }

  .cta2-form {
    margin-top: 0;
    padding: 0 15px;
  }
}

.card2 {
  border-style: solid;
  border-color: var(--blueBold);
  border-width: 0 0 0 4px;
  border-radius: 0 16px 16px 0;
  margin: 5% 0%;
  padding: 1% 56px;
  width: 888px;
  background-color: #ffffff;
}

.Icon {
  object-fit: scale-down;
  min-width: 126px;
}

.offset-me {
  margin-left: 8.3333334%;
}

.blueBold {
  position: relative;
  color: var(--blueBold);
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: 8px;
  top: 36px;
  margin-bottom: 22px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.answer {
  font-size: 20px;
  margin-left: 40px;
  margin-top: -16px;
}

.underline {
  text-decoration: underline;
}

.question {
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-left: 42px;
  margin-top: -15px;
}

.sectionFaQ {
  margin: 130px auto;
  padding: 0 44px 0 48px;
  /* height: 2290px; */
}

.msmall {
  font-size: 20px;
  font-weight: 600;
}

.mbold {
  font-size: 38px;
  font-weight: 600;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .sectionFaQ {
    padding-top: 60px;
  }

  .offset-me {
    margin-left: 0;
  }

  .msmall {
    margin-bottom: 20px;
  }

  .Icon {
    margin: 86px 0 0 0;
    height: 148px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .sectionFaQ {
    padding: 0 15px;
  }

  .Icon {
    margin: 35px 0 67px 0;
    height: 148px;
  }

  .card2 {
    width: 100%;
    padding: 1% 24px;
    margin: 0 0 0 0;
  }

  .answer {
    font-size: 16px;
    line-height: 30px;
    padding-top: 5px;
  }

  .question {
    margin-top: -10px;
    font-size: 20px;
  }

  .blueBold {
    margin-bottom: 16px;
  }

  .mbold {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
  }

  .msmall {
    font-size: 16px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .sectionFaQ {
    padding: 0 15px;
  }

  .card2 {
    width: 100%;
    padding: 1% 24px;
    margin: 0 0 0 0;
  }

  .Icon {
    margin: 35px 0 67px 0;
    height: 148px;
  }

  /* .offset-me {
    margin-left: 0;
  } */

  .answer {
    font-size: 16px;
    line-height: 30px;
  }

  .tri {
    overflow-wrap: anywhere;
  }

  .question {
    font-size: 20px;
  }

  .mbold {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
  }

  .msmall {
    font-size: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .sectionFaQ {
    padding: 0 15px;
  }

  .card2 {
    width: 100%;
    padding: 1% 24px;
    margin: 0 0 0 0;
  }

  .Icon {
    margin: 35px 0 67px 0;
    height: 148px;
  }

  /* .offset-me {
    margin-left: 0;
  } */

  .answer {
    font-size: 16px;
    word-break: break-word;
    line-height: 30px;
  }

  .question {
    font-size: 20px;
  }

  .mbold {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
  }

  .msmall {
    font-size: 16px;
  }
}

.Smi {
  border-radius: 8px;
  transition: box-shadow 600ms, -webkit-transform 100ms;
  transition: transform 100ms, box-shadow 600ms;
  transition: transform 100ms, box-shadow 600ms, -webkit-transform 100ms;
}

.Smi:hover {
  -webkit-transform: translate(0px, -10%);
          transform: translate(0px, -10%);
  box-shadow: 0 0 100px 1px rgba(0, 0, 0, 0.322);
}

/* Smi stands for Social Media Icon */

.bld {
  font-weight: bold;
}

#ContactUs {
  text-align: unset;
}

#consec {
  margin-top: 0;
}

#consec a {
  text-decoration: underline;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  #ContactUs {
    text-align: center;
  }

  #consec {
    margin-top: 78px;
    margin-bottom: 56px;
  }

  .mobile {
    margin-top: 72px;
    padding-bottom: 0px;
  }
}

@media screen and (min-width: 375px) and (max-width: 767px) {
  #ContactUs {
    text-align: unset;
  }

  #consec {
    margin: 0;
  }

  .mobile {
    width: auto;
  }

  .Smi {
    margin: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  #ContactUs {
    text-align: unset;
  }
  #consec {
    margin: 0;
  }
  .mobile {
    width: auto;
  }
  .Smi {
    margin: 12px;
  }
}

.CeoMessageBg {
  background-image: linear-gradient(
      92.55deg,
      rgba(44, 119, 231, 0.79) 99.99%,
      rgba(44, 119, 231, 0) 100%
    ),
    url(/static/media/Houston.595df770.png);
  height: 884px;
  width: 1440px;
  background-repeat: round;
  margin-top: 48px;
}

.Houston {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 24px;
}

.beta {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
  margin-bottom: 88px;
}

.ceoq {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 48px;
  width: 776px;
  height: 102px;
}

.Ceocta {
  width: 520px;
  margin: auto;
}

h5 {
  margin: auto;
}

.ceoName {
  width: 313px;
  height: 76px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 35px;
  margin: 40px auto;
}

.ceoSpace {
  margin-bottom: 128px;
}

.MessageContainer {
  margin-top: 92px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .CeoMessageBg {
    background-position-x: 47%;
    background-position-y: center;
    background-repeat: space;
    background-size: cover;
    height: 847px;
    padding: 0 32px 0 32px;
  }

  .Houston {
    font-weight: normal;
    margin: 0 0 30px 0;
  }

  .beta {
    letter-spacing: 1px;
  }

  .ceoq {
    width: 720px;
    margin-top: 80px;
  }

  .ceoName {
    margin: 30px auto;
  }

  .ceoSpace {
    margin-bottom: 70px;
  }
  .MessageContainer {
    margin-top: 112px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .Houston {
    margin: 25px 0 16px 0;
    font-size: 16px;
    font-weight: normal;
  }

  .CeoMessageBg {
    background-repeat: space;
    background-position: 46%;
    height: 696px;
  }

  .ceoSpace {
    margin-bottom: 56px;
  }

  .ceoq {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 37px;
    width: 345px;
    margin-top: 56px;
  }

  .ceoName {
    font-size: 16px;
    margin: 16px auto;
  }

  .beta {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin-top: 0px;
    margin-bottom: 56px;
  }

  .Ceocta {
    width: 400px;
    margin: auto;
  }

  .MessageContainer {
    margin-top: 24px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .Houston {
    margin: 25px 0 16px 0;
    font-size: 16px;
    font-weight: normal;
  }

  .CeoMessageBg {
    background-repeat: space;
    background-position: 46%;
    height: 696px;
  }

  .ceoSpace {
    margin-bottom: 50px;
  }

  .ceoq {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 37px;
    width: 345px;
    margin-top: 56px;
  }

  .ceoName {
    font-size: 16px;
    margin: 16px auto;
  }

  .beta {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin-top: 0px;
    margin-bottom: 56px;
  }

  .MessageContainer {
    margin-top: 24px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .Houston {
    margin: 25px 0 16px 0;
  }

  .CeoMessageBg {
    background-repeat: space;
    background-position: 46%;
    height: 696px;
  }

  .ceoSpace {
    margin-bottom: 30px;
  }

  .ceoq {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 37px;
    width: 300px;
    margin-top: 56px;
  }

  .ceoName {
    font-size: 16px;
    margin: 16px auto;
  }

  .beta {
    font-size: 32px;
    font-weight: 600;
    line-height: 44px;
    margin-top: 0px;
    margin-bottom: 50px;
  }

  .MessageContainer {
    margin-top: 16px;
  }
}

.sectionWwd {
  margin: auto;
}

.card {
  border-style: solid;
  border-color: var(--blueBold);
  border-width: 0 0 0 4px;
  border-radius: 0px 16px 16px 0;
  margin: -10% auto;
  padding: 32px 32px 32px 64px;
  height: 200px;
  width: 380px;
  background-color: #ffffff;
}

.auto {
  position: relative;
  left: 2vw;
  margin: 120px 125px;
  margin-bottom: 67px;
}

.tri {
  font-family: 'Roboto';
}

.concen {
  justify-content: center;
}

.shead {
  font-size: 20px;
  font-weight: 600;
}

.bhead {
  font-size: 38px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .concen {
    justify-content: center;
  }

  .card {
    margin: -10% 10%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .sectionWwd {
    margin: 100px 0;
  }

  .bhead {
    padding: 0;
    margin: 35px 15px 0 10px;
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 3px;
  }

  .shead {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }

  .card {
    margin: 20px auto;
    margin-bottom: 0;
    text-align: left;
    width: 410px;
  }

  .auto {
    margin: 121px 307px 24px 190px;
  }

  .concen {
    text-align: center;
    justify-content: center;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .sectionWwd {
    margin: auto;
  }

  .auto {
    left: 0;
    margin: 20% 30%;
  }

  .card {
    width: 80%;
    height: 164px;
    margin: -10% 10%;
    text-align: left;
    padding: 16px 40px 20px 24px;
  }

  .concen {
    text-align: center;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .sectionWwd {
    margin: auto;
  }

  .auto {
    left: 0;
    margin: 32px 100px;
  }

  .card {
    margin: 0;
    width: 100%;
    padding: 32px;
    padding-left: 28px;
  }

  .shead {
    font-size: 16px;
  }

  .bhead {
    font-size: 32px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .sectionWwd {
    margin: auto;
  }

  .auto {
    left: 0;
    margin: 20% 28%;
  }

  .card {
    margin: 0;
    width: 99%;
    padding: 8px 30px;
  }
}

input {
  width: unset;
  height: unset;
  margin-right: unset;
}
input[type='radio'] {
  height: 20px;
  width: 20px;
}
input[type='radio']:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  /* top: -2px;
  left: -1px; */
  position: relative;
  background-color: black;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 4px solid white;
}
#unsubFormTextInput {
  margin-left: 40px;
}
.form-check-input {
  position: unset;
}
.form-check {
  margin-bottom: 8px;
}
.form-check-label {
  margin-left: 14px;
}
.modalHeaderCloseButton {
  margin-top: 28px;
  padding-right: 35px;
}
.modalTitle {
  padding-bottom: 24px;
  font-size: 38px;
  font-weight: bold;
}
.buttonRows {
  margin: unset;
  margin-left: unset;
  margin-right: unset;
}
#stay {
  margin-bottom: 24px;
}
#confirmModalBody {
  padding-bottom: 122px;
  padding-top: 12px;
}
#confirmModalFooter {
  padding-bottom: 120px;
  /* justify-content: center; */
  display: flex;
  flex-direction: column;
}
#unsubForm {
  width: unset;
  height: unset;
  margin-left: 58px;
  padding-top: 48px;
  padding-bottom: 45px;
  padding-left: 0px;
  position: unset;
  justify-content: right;
  text-align: start;
}

.modal-dialog {
  align-items: center;
}

.modal-content {
  align-items: center;
  text-align: center;
  border: 0px;
  border-radius: 40px;
  background-color: #f8f8ff;
  width: 659px;
  height: unset;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 0px;
}

.orangeButton,
.orangeButton:disabled {
  background: #ff531a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.orangeButton:hover {
  background: #de4917;
  -webkit-transform: scale(0.98);
          transform: scale(0.98);
}
.btn-outline {
  background: transparent;
  color: #ff531a;
  border-color: #ff531a;
  border-style: solid;
  border-width: 1px;
}
.modal-text {
  width: 540px;
  height: 69px;
  font-size: 20px;
  font-style: normal;
  line-height: 30px;
  padding: 0px;
  margin-left: 60px;
  margin-right: 60px;
}

.modal-body {
  padding: 0px;
}

.modal-footer {
  border: 0px;
  padding: 0px;
  margin-top: unset;
  margin-bottom: unset;
  padding-bottom: 40px;
}
.modal-footer > :not(:first-child) {
  margin-left: unset;
}
.modal-footer > :not(:last-child) {
  margin-right: unset;
}

.btn:hover {
  color: #f8f8ff;
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }
  .modal-content {
    align-items: center;
    text-align: center;
    border: 0px;
    background-color: #f8f8ff;
    width: unset;
    margin-inline: -10px;
  }
  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding: 0px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }

  .modal-content {
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
  }

  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding-inline: 45px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .submit-button {
    /* top: 68px;
    right: 340px; */
    margin: 28px 0 0 0;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .modal-backdrop {
    background-color: #f8f8ff;
  }
  .modal-backdrop.show {
    opacity: 1;
  }
  .modal-header {
    margin-top: 90px;
  }

  .modal-content {
    width: 100%;
    box-shadow: none;
    border-radius: 0px;
    margin-inline: 0;
  }

  .thank-you-msg {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43.58px;
    margin-top: 96px;
    margin-bottom: 24px;
  }
  .modal-text {
    width: 375px;
    font-size: 16px;
    font-style: normal;
    line-height: 30px;
    padding-inline: 45px;
    margin-left: 45px;
    margin-right: 45px;
    margin-bottom: 64px;
    word-break: break-word;
  }

  .modal-footer {
    padding-bottom: 0px;
    margin-bottom: 135px;
  }

  .submit-button {
    /* top: 68px;
    right: 340px; */
    margin: 28px 0 0 0;
  }
}

.jumbotron {
  background: linear-gradient(
      90deg,
      #2c77e7 40.46%,
      rgba(30, 108, 226, 0.145595) 54.17%,
      rgba(27, 106, 225, 0) 104.18%,
      rgba(31, 109, 226, 0.216167) 130.95%
    ),
    url(/static/media/hero-img.31bd9d89.svg);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: 645px;
  border: 0px;
  border-radius: 0%;
  padding-left: 0rem;
}

.text-container {
  color: #f8f8ff;
  margin: 32px 0 0 3rem;
  width: 541px;
  height: 305px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}

.headline {
  font-style: normal;
  font-size: 38px;
  line-height: 52px;
  margin-bottom: 24px;
}

.headline b {
  font-style: normal;
  font-weight: bolder;
}

.subheading {
  margin-bottom: 24px;
  font-family: 'Roboto';
}

.email-updates {
  font-style: normal;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 24px;
}

input {
  width: 318px;
  height: 44px;
  margin-right: 24px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .jumbotron {
    background: linear-gradient(
        0deg,
        #2c77e7 45.16%,
        rgba(30, 108, 226, 0.145595) 87.49%
      ),
      url(/static/media/hero-img-new.5edcfb0a.png);
    height: 949px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .text-container {
    margin: 455px 0 0 48px;
  }

  .headline {
    font-size: 35px;
    line-height: 47.66px;
  }

  .subheading {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .jumbotron {
    background: linear-gradient(
        0deg,
        #2c77e7 68.88%,
        rgba(30, 108, 226, 0.145595) 87.49%
      ),
      url(/static/media/hero-img-new.5edcfb0a.png);
    width: 100%;
    height: 811.52px;
    background-size: contain;

    /* pushes this down so the navbar doesn't partially 
    cover it */
    margin-top: 90px;
  }

  .text-container {
    margin: 260px 0 0 16px;
    width: 90%;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .jumbotron {
    background: linear-gradient(
        0deg,
        #2c77e7 68.88%,
        rgba(30, 108, 226, 0.145595) 87.49%
      ),
      url(/static/media/hero-img-new.5edcfb0a.png);
    height: 811px;
    width: 100%;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: contain;
    background-repeat: no-repeat;

    /* pushes this down so the navbar doesn't partially 
    cover it */
    margin-top: 90px;
  }

  .text-container {
    margin: 240px 0 0 16px;
    width: 345px;
    height: 305px;
  }

  .headline {
    font-size: 32px;
    width: 345px;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
  }

  .subheading {
    width: 345px;
    font-size: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .jumbotron {
    background: linear-gradient(
        0deg,
        #2c77e7 68.88%,
        rgba(30, 108, 226, 0.145595) 87.49%
      ),
      url(/static/media/hero-img-new.5edcfb0a.png);
    height: 811px;
    width: 100%;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: contain;
    background-repeat: no-repeat;
    /* pushes this down so the navbar doesn't partially 
    cover it */
    margin-top: 90px;
  }

  .text-container {
    margin: 210px 0 0 10px;
    width: 300px;
    height: 305px;
  }

  .headline {
    font-size: 26px;
    width: 300px;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
  }

  .subheading {
    width: 300px;
    font-size: 16px;
  }
}

::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}

@media (min-width: 1061px) {
  .loginPage {
    width: 100%;
    height: 100vh;
    padding: 0;
    overflow-y: hidden;
  }

  .loginPagenavBar {
    height: 119px;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .loginPagenavBarContents {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
    margin: auto;
  }

  .loginPagesignUpArea {
    margin: auto;
    display: flex;
    height: 119px;
    padding-top: 30px;
  }

  .loginPageactionText {
    padding-top: 16px;
    padding-right: 24px;
    font-weight: 500;
    -webkit-columns: #263238;
            columns: #263238;
  }

  .loginPagesignUpArea p {
    font-size: 18px;
  }

  .loginPagelogo {
    display: inline-flex;
    position: relative;
    height: 119px;
  }

  .loginPagenavbarLogo {
    position: relative;
    margin-left: 32px;
  }

  .loginPagesignUpButton {
    width: 178px;
    height: 44px;
    background-color: #faf9f9;
    color: #2c77e7;
    text-align: center;
    text-decoration: underline;
    border-radius: 16px;
    border: none;
    outline: none;
    margin: 6px;
    margin-right: 48px;
    font-size: 20px;
  }

  .loginPagesignUpButton:hover {
    background-color: #1553b0;
  }

  .loginPageformContainer {
    height: 90%;
    margin-bottom: 3%;
    padding-top: 2.25%;
    background: url(/static/media/cta2.f204006a.jpeg) no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.5);
  }

  .loginPageloginModal {
    padding-top: auto;
    padding-bottom: auto;
    margin-right: 9%;
    margin-left: 9%;
    align-items: center;
    border-radius: 24px;
    background-color: #faf9f9;
    display: flex;
    flex-direction: column;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
    align-items: center;
    font-size: 20px;
    width: 81%;
    height: 91%;
    overflow: scroll;
  }

  .loginPagetitleLogin {
    height: 54px;
    width: 100%;
    text-align: center;
    margin: auto;
    margin-top: 6%;
    margin-bottom: 4%;
  }

  .loginPagetitleLogin p {
    font-size: 38px;
    line-height: 52px;
    font-weight: 600;
    margin-bottom: 0px;
  }

  .loginPagewarningMessage {
    color: #ff0000;
    width: 432px;
  }

  .loginPageerrorIcon {
    float: left;
    padding-top: 5px;
  }

  .loginPagewarningMessage p {
    margin-bottom: 0px;
    margin-right: 0px;
    padding-right: 25px;
  }

  .loginPageerrorDisplay {
    text-align: center;
    width: 432px;
  }

  .loginPageloginForm {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    width: 660;
  }

  .signupPageOAuthButtons {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .fancyLines {
    border: 1px solid #000000;
    min-height: 144px;
    width: 2px;
  }

  .wordBetweenLines {
    position: relative;
    top: 9px;
    right: 9px;
  }

  .signupPagelines {
    display: flex;
    flex-direction: column;
    grid-row-gap: 9px;
    row-gap: 9px;
    height: 10px;
    margin-left: 35px;
    margin-right: 35px;
  }
  /* Test */
  .googleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 33px;
    left: 15px;
  }

  .appleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 32px;
    left: 15px;
  }

  .googleOAuthButton {
    width: 296px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .appleOAuthButton {
    width: 296;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .signupPageOAUthButtonVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 60px;
  }

  .loginNoOAuth {
    display: flex;
    flex-direction: column;
    grid-row-gap: 32px;
    row-gap: 32px;
  }

  .loginPageformPlacement {
    display: flex;
    flex-direction: row;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    margin-left: 11%;
    margin-right: 50%;
  }

  .loginPageformPlacement label {
    padding-bottom: 16px;
    position: relative;
    right: 100px;
  }

  .loginPageresetLink {
    margin-bottom: 60px;
  }

  .loginPageemailInput {
    height: 105px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    grid-column-gap: 1px;
    -webkit-column-gap: 1px;
            column-gap: 1px;
  }

  .loginPageemailInput label {
    position: relative;
    left: -119px;
  }

  .loginPagepasswordInput {
    height: 112px;
    font-weight: 400;
    display: flex;
    flex-direction: column;
  }

  .loginPageloginButton {
    width: 178px;
    height: 44px;
    background-color: rgba(44, 119, 231, 0.7);
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    font-size: 20px;
    position: relative;
    top: 350px;
    right: 730px;
  }

  .loginPagesubmitButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 20px;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .loginPageloginButton:hover {
    background-color: #2c77e7;
  }

  .loginPagepasswordIcon {
    background-color: white;
    height: 22px;
    width: 22px;
    position: relative;
    bottom: 32px !important;
  }

  .loginPagevalidEmailLogin {
    color: #088f26;
    border-color: #088f26;
    border-width: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHJSURBVHgBnZU9TsMwGIZfO6EyTAEuEARIbMAJCFtHGNngBBBxgIod1HbrVrgBa6e2N+iIWpDCAQqZwP1JjB3Tyi1p0/BKkeLYfvz5+wtBhpyy63AOR70zhjD0g3DZerIQAvtaTnv6MTeIjohRBR21uB8EmcCN+92zyLLqcsLBcgWIxR2/7T2aHy1zwMp7JUGtmoQxZMsBIWd2cRPjxkf7j4XsYf8SlNTxD1FE51/+2/MUyMoHLhA35aubg/MEdW2gJCDCdYx2VMBoMhVHXl4Y97uXDMOKAEIC4vC4cKOtVWZScp0XpjJhgLXmNHhUnCQsnSKFz8lqfeLCCM/ABMiROSkt3qQczNwcEAyPJfYqL0yJS0NomhncV7k1A82ETUQZuFlKLlBosrLrGtCVYUy7S6XNfnOuxAJgeDoprVVg0vedgd89tpNRTNoy3l6Kpafq1CyYkqzxanLlxEKq82lujaugq8D0jUatKVBluCzqtMi6K8CkZJP4dc+0OYwa/Re7uK186iGfJKxXmQxmus240W/Zxa13aKuWti/lIgvRxbf/WjO/pzZY3SwijwhyIwgOZ0kygES0VB2nde9UoCldmrqaVM5m/QJ+AAAGzx8yLXqdAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .loginPagevalidPassLogin {
    color: #088f26;
    border-color: #088f26;
    border-width: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHJSURBVHgBnZU9TsMwGIZfO6EyTAEuEARIbMAJCFtHGNngBBBxgIod1HbrVrgBa6e2N+iIWpDCAQqZwP1JjB3Tyi1p0/BKkeLYfvz5+wtBhpyy63AOR70zhjD0g3DZerIQAvtaTnv6MTeIjohRBR21uB8EmcCN+92zyLLqcsLBcgWIxR2/7T2aHy1zwMp7JUGtmoQxZMsBIWd2cRPjxkf7j4XsYf8SlNTxD1FE51/+2/MUyMoHLhA35aubg/MEdW2gJCDCdYx2VMBoMhVHXl4Y97uXDMOKAEIC4vC4cKOtVWZScp0XpjJhgLXmNHhUnCQsnSKFz8lqfeLCCM/ABMiROSkt3qQczNwcEAyPJfYqL0yJS0NomhncV7k1A82ETUQZuFlKLlBosrLrGtCVYUy7S6XNfnOuxAJgeDoprVVg0vedgd89tpNRTNoy3l6Kpafq1CyYkqzxanLlxEKq82lujaugq8D0jUatKVBluCzqtMi6K8CkZJP4dc+0OYwa/Re7uK186iGfJKxXmQxmus240W/Zxa13aKuWti/lIgvRxbf/WjO/pzZY3SwijwhyIwgOZ0kygES0VB2nde9UoCldmrqaVM5m/QJ+AAAGzx8yLXqdAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .loginPagevalidPassLogin + img {
    right: 50px;
  }

  .loginPageinvalidEmailLogin {
    color: #f00;
    border-color: #f00;
    border-width: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADYSURBVHgBrZXtEYMgDECD1wFwAzpJV3AENmg3oJvVTlA7ASPQDWhyTfUOQeJH7p7+ML4jQqKCSkQADT8oPgqBtUESxCEPJCa8EIsYqaxDQkaU4klckzmBKMWVZHaD7E+XygyXUHrhxpSehzhtnGh1hlnKuZOrYecV9seFLoqXGirJZ777Sl5LK9RwXOiTMNEI88QlS6NtuDf7SuIbeVZyhrHPacsPODZ2VPMwCDsOtp8NCx4KcSM2+xEEpRc7pBjchl4gCrOhsCA1LB4yop4ryTaEEshX/QK+Y/pVkTZ7PfcAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .loginPageinvalidPassLogin {
    color: #f00;
    border-color: #f00;
    border-width: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADYSURBVHgBrZXtEYMgDECD1wFwAzpJV3AENmg3oJvVTlA7ASPQDWhyTfUOQeJH7p7+ML4jQqKCSkQADT8oPgqBtUESxCEPJCa8EIsYqaxDQkaU4klckzmBKMWVZHaD7E+XygyXUHrhxpSehzhtnGh1hlnKuZOrYecV9seFLoqXGirJZ777Sl5LK9RwXOiTMNEI88QlS6NtuDf7SuIbeVZyhrHPacsPODZ2VPMwCDsOtp8NCx4KcSM2+xEEpRc7pBjchl4gCrOhsCA1LB4yop4ryTaEEshX/QK+Y/pVkTZ7PfcAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .loginPageinvalidPassLogin + img {
    right: 50px;
  }

  .loginPagepasswordInput input[type='text'] {
    width: 296px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .loginPagepasswordInput input[type='password'] {
    overflow-y: hidden;
    width: 296px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 30px;
  }

  .loginPagepasswordInput
    input[type='password']:active::-webkit-input-placeholder {
    color: transparent;
  }

  .loginPageemailInput input[type='email'] {
    width: 296px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .loginPageemailInput input[type='email']:active::-webkit-input-placeholder {
    color: transparent;
  }

  .loginPageresetLink a {
    text-decoration: underline;
    font-size: 18px;
    height: 5px;
    margin: auto;
  }

  .loginPageformPlacement input,
  label {
    margin: auto;
    display: block;
  }
}

@media (min-width: 250px) and (max-width: 560px) {
  .loginPagenavBar {
    height: 119px;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }

  .loginPagelogo {
    position: relative;
    margin-top: 24px;
    margin-left: 18px;
  }

  .loginPagesignUpArea {
    display: flex;
    flex-direction: row;
    margin-top: 38px;
  }

  .loginPageactionText {
    font-size: 18px;
    line-height: 21px;
    margin-right: 24px;
    color: #263238;
  }

  .loginPagenavBarContets {
    margin-right: 7px;
  }

  .loginPagesignUpButton {
    padding: 0;
    border: none;
    background: none;
    color: #2c77e7;
    text-decoration: underline;
    font-weight: 600;
    position: relative;
    bottom: 11px;
  }

  .loginPageformContainer {
    width: 375px;
    margin: auto;
  }

  .loginPageloginModal {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .loginPagetitleLogin {
    font-style: normal;
    font-weight: 500;
    font-size: 38px;
    line-height: 52px;
    text-align: center;
  }

  .loginPageloginForm {
    margin-top: 90px;
  }

  .loginPageformPlacement {
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .loginPageemailInput {
    padding-bottom: 20px;
  }

  .loginPagepasswordInput {
    height: 90px;
  }

  .loginPageemailInput label {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: #000;
  }

  .loginPageemailInput input {
    background: #ffffff;
    border: 2px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px;
    width: 350px;
  }

  .loginPageemailInput input[type='email']:active::-webkit-input-placeholder {
    color: transparent;
  }

  .loginPagepasswordInput
    input[type='password']:active::-webkit-input-placeholder {
    color: transparent;
  }

  .loginPagepasswordInput label {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: #000;
  }

  .loginPagepasswordInput input {
    background: #ffffff;
    border: 2px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px;
    width: 350px;
  }

  #passwordIcon {
    position: relative;
    left: 315px;
    bottom: 40px;
  }

  .loginPageresetLink {
    text-decoration: underline;
    margin-bottom: 37px;
  }

  .wordBetweenLines {
    position: relative;
    bottom: 15px !important;
  }

  .signupPagelines {
    width: 100%;
    margin: auto;
    padding-right: 0.75rem;
  }

  .fancyLines {
    width: 150px;
  }

  .signupPageOAUthButtonVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 60px;
  }

  .loginPageloginButton {
    width: 178px;
    height: 44px;
    background-color: rgba(44, 119, 231, 0.7);
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    font-size: 20px;
    position: relative;
    top: 100px;
    left: 1px;
  }

  .loginPagesubmitButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 20px;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .loginPageloginButton:hover {
    background-color: #2c77e7;
  }

  .loginPageerrorDisplay {
    max-width: 355px;
    margin-left: 39px;
  }

  .loginPagewarningMessage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: red;
  }

  .loginPageerrorIcon {
    height: 21px;
    width: 21px;
    position: relative;
    top: 5px;
    margin-right: 9px;
  }
}

@media (min-width: 561px) and (max-width: 1060px) {
  .loginPagenavBar {
    height: 104px;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
  }

  .loginPagelogo {
    position: relative;
    margin-top: 24px;
    margin-left: 48px;
  }

  .loginPagesignUpArea {
    display: flex;
    flex-direction: row;
    margin-top: 38px;
  }

  .loginPageactionText {
    font-size: 18px;
    line-height: 21px;
    margin-right: 24px;
    color: #263238;
  }

  .loginPagenavBarContets {
    margin-right: 47px;
  }

  .loginPagesignUpButton {
    padding: 0;
    border: none;
    background: none;
    color: #2c77e7;
    text-decoration: underline;
    font-weight: 600;
    position: relative;
    bottom: 11px;
  }

  .loginPageformContainer {
    width: 395px;
    margin: auto;
  }

  .loginPageloginModal {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
  }

  .loginPagetitleLogin {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 52px;
    text-align: center;
  }

  .loginPageloginForm {
    margin-top: 120px;
  }

  .loginPageformPlacement {
    display: flex;
    flex-direction: column;
  }

  .loginPageemailInput {
    padding-bottom: 20px;
  }

  .loginPagepasswordInput {
    height: 90px;
  }

  .loginPageemailInput label {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: #000;
  }

  .loginPageemailInput input {
    background: #ffffff;
    border: 2px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px;
    width: 372px;
  }

  .loginPagepasswordInput label {
    font-size: 18px;
    line-height: 25px;
    font-weight: 500;
    color: #000;
  }

  .loginPagepasswordInput input {
    background: #ffffff;
    border: 2px solid #d8d8d8;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 15px;
    width: 372px;
  }

  .loginPageemailInput input[type='email']:active::-webkit-input-placeholder {
    color: transparent;
  }

  .loginPagepasswordInput
    input[type='password']:active::-webkit-input-placeholder {
    color: transparent;
  }

  #passwordIcon {
    position: relative;
    left: 315px;
    bottom: 40px;
  }

  .loginPageresetLink {
    text-decoration: underline;
    margin-bottom: 37px;
  }

  .wordBetweenLines {
    position: relative;
    bottom: 15px !important;
  }

  .signupPagelines {
    width: 100%;
    margin-left: 3px;
  }

  .fancyLines {
    width: 150px;
  }

  .loginPageloginButton {
    width: 178px;
    height: 44px;
    background-color: rgba(44, 119, 231, 0.7);
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    font-size: 20px;
    position: relative;
    top: 100px;
    left: 1px;
  }

  .loginPagesubmitButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 20px;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .loginPageloginButton:hover {
    background-color: #2c77e7;
  }

  .loginPageerrorDisplay {
    max-width: 355px;
    margin-left: 39px;
  }

  .loginPagewarningMessage {
    display: flex;
    flex-direction: row;
    justify-content: center;
    color: red;
  }

  .loginPageerrorIcon {
    height: 21px;
    width: 21px;
    position: relative;
    top: 5px;
    margin-right: 9px;
  }
}



.mailto {
  text-decoration: underline;
}

.btn {
  padding: 0px;
  background: #ff531a;
  color: #f8f8ff;
  width: 178px;
  height: 44px;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  border: 0px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.image404 {
  display: flex;
  margin: auto;
  height: 400px;
  width: 625px;
  margin-top: 140px;
  object-fit: contain;
}

.titleText {
  font-family: 'Open Sans';
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 16px;
}

.subTitleText {
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 24px;
}

.footerText {
  font-family: 'Roboto';
  font-size: 18px;
  text-align: center;
  margin-inline: auto;
  margin-top: 96px;
  margin-bottom: 65px;
}

/* tablet small/mobile large screen range */
@media screen and (min-width: 425px) and (max-width: 991px) {
  .image404 {
    height: 325px;
    width: 600px;
    margin-top: 150px;
  }

  .titleText {
    font-size: 38px;
  }

  .subTitleText {
    font-size: 20px;
    margin-bottom: 32px;
  }

  .footerText {
    font-size: 18px;
    margin-top: 144px;
  }
}

/* mobile mid screen range */
@media screen and (min-width: 375px) and (max-width: 424px) {
  .image404 {
    height: 150px;
    width: 295px;
    margin-top: 150px;
  }

  .titleText {
    font-size: 30px;
    font-weight: 700;
    margin-top: 44px;
    margin-bottom: 16px;
  }

  .subTitleText {
    font-size: 18px;
    margin-bottom: 32px;
  }

  .footerText {
    font-size: 16px;
    max-width: 270px;
    margin-top: 120px;
  }
}

/* mobile tiny screen range */
@media screen and (min-width: 320px) and (max-width: 374px) {
  .image404 {
    height: 145px;
    width: 290px;
    margin-top: 15px;
  }

  .titleText {
    font-size: 28px;
    font-weight: 700;
    margin-top: 44px;
    margin-bottom: 16px;
  }

  .subTitleText {
    font-size: 16px;
    margin-bottom: 32px;
  }

  .footerText {
    font-size: 14px;
    max-width: 270px;
    margin-top: 80px;
  }
}

@media (min-width: 250px) and (max-width: 560px) {
  .signupPagenavBar:first-child {
    background-color: #fff;
    max-height: 9%;
    width: auto;
    padding-bottom: 15px;
  }

  .signupPagenavBar > div:last-child {
    display: none;
  }

  .signupPageformContainer {
    min-height: 812px;
    padding-top: 30px;
    margin: auto;
  }

  .signupPagesignupModal {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signupPagetitleSignup {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    padding-top: 40px;
  }

  .signupPagesignupForm {
    position: relative;
    margin: auto;
    width: 95%;
  }

  .signupPageformPlacement {
    width: 100%;
    height: 100vh;
    margin: auto;
  }

  .signupPageentityText {
    padding-bottom: 120px;
    margin: auto;
    text-align: center;
    font-weight: 600;
  }

  .signupPagestepOne {
    width: 100%;
    margin: auto;
  }

  .signupPagesignupForm .signupPagestepOne .signupPagenextButton {
    top: 105px;
    left: 160px;
  }

  .signupPagepreviousButton {
    width: 165px;
    height: 44px;
    background-color: #faf9f9;
    color: blue;
    text-align: center;
    border-radius: 16px;
    border: 2px solid #2c77e7;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagepreviousButton {
    color: #2c77e7;
  }

  .signupPagenextButton {
    position: relative;
    width: 165px;
    height: 44px;
    background-color: rgba(44, 119, 231, 0.7);
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagenextButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .signupPagepaginationButtons {
    min-width: 378px;
    display: flex;
    flex-direction: row;
    grid-column-gap: 6%;
    -webkit-column-gap: 6%;
            column-gap: 6%;
  }

  .signupPagepaginationButtons.entitySignupMoreInfo {
    min-width: 378px;
    display: flex;
    flex-direction: row;
    grid-column-gap: 6%;
    -webkit-column-gap: 6%;
            column-gap: 6%;
    left: 16px;
  }

  .signupPageindividualDiv {
    margin: auto;
    width: 345px;
    height: 60px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-bottom: 28px;
  }

  .signupPageindividualDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageindividualDiv:focus,
  .signupPageindividualDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageindividualDiv p {
    background-color: #fff;
    margin-left: 17%;
    width: 30%;
    height: 30px;
    position: relative;
    bottom: 33px;
    overflow-y: hidden;
  }

  .signupPageindividualDiv img {
    width: 32px;
    height: 32px;
    margin-left: 15px;
    margin-top: 15px;
  }

  .signupPagenonProfitDiv {
    margin: auto;
    width: 345px;
    height: 60px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-bottom: 28px;
  }

  .signupPagenonProfitDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPagenonProfitDiv:focus,
  .signupPagenonProfitDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPagenonProfitDiv p {
    background-color: #fff;
    margin-left: 18%;
    width: 30%;
    height: 28px;
    position: relative;
    bottom: 32px;
    overflow-y: hidden;
  }

  .signupPagenonProfitDiv img {
    width: 32px;
    height: 32px;
    margin-left: 15px;
    margin-top: 15px;
  }

  .signupPageorganizationDiv {
    margin: auto;
    width: 345px;
    height: 60px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPageorganizationDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageorganizationDiv:focus,
  .signupPageorganizationDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageorganizationDiv p {
    background-color: #fff;
    margin-left: 16%;
    width: 35%;
    height: 44px;
    position: relative;
    bottom: 36px;
    overflow-y: hidden;
  }

  .signupPageorganizationDiv img {
    width: 39px;
    height: 39px;
    margin-left: 8px;
    margin-top: 9px;
  }

  .signupPageOAuthSection {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .signupPageOAuthButtons {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .fancyLines {
    border: 1px solid #000000;
    height: 3px;
    width: 140px;
  }

  .wordBetweenLines {
    position: relative;
    bottom: 1px;
  }

  .signupPagelines {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-column-gap: 22px;
    -webkit-column-gap: 22px;
            column-gap: 22px;
    padding-top: 2px;
    height: 10px;
  }

  .googleIcon {
    height: 20px;
    width: 21px;
    position: relative;
    top: 33px;
    left: 15px;
  }

  .appleIcon {
    height: 20px;
    width: 21px;
    position: relative;
    top: 32px;
    left: 15px;
  }

  .googleOAuthButton {
    width: 345px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .appleOAuthButton {
    width: 345px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .signupPageuserInfoForm {
    min-height: 448px;
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 25px;
    row-gap: 25px;
  }

  .signupPageuserInfoForm .signupPagepaginationButtons {
    position: relative;
    top: 50px;
    left: 15px;
    margin: 0 auto;
    width: 50%;
  }

  .signupPageuserInfoForm .verticalLayoutSignup {
    margin-left: 17px;
  }

  .userSignupInfo {
    position: relative;
    top: 55px;
  }

  .userSignupInfo.usersInfoFormSubClass {
    position: relative;
    margin: auto;
  }

  .userSignupMoreInfo.signupPagepaginationButtons {
    margin: 0 auto;
    width: 50%;
    left: 5px;
    top: 10px;
  }

  .signupPagepaginationButtons.userSignupInfo.usersInfoFormSubClass {
    margin: 0 auto;
    width: 50%;
  }

  .entitySignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    margin: 0 auto;
    width: 50%;
    bottom: 200px;
  }

  .entitySignupForm.signupPagepaginationButtons {
    position: relative;
    top: 45px;
    left: 5%;
    margin: 0 auto;
    width: 50%;
  }

  .signupPageemailInput {
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .signupPagepasswordInput {
    display: flex;
    flex-direction: column;
  }

  .verticalLayoutSignup {
    display: flex;
    flex-direction: column;
  }

  .signupPageuserInfoForm input {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    width: 345px;
    height: 44px;
    padding-left: 2%;
  }

  .signupPageuserInfoForm label {
    font-weight: 400;
    font-size: 18px;
  }

  .columnEntityAdjustment {
    margin-left: 17px;
  }

  .columnEntityAdjustment input {
    width: 345px;
  }

  .signupPageentityLocationForm {
    min-height: 812px;
    display: flex;
    flex-direction: column;
  }

  .signupPageentityLocationForm > div:nth-child(1) {
    display: flex;
    margin-bottom: 10px;
    justify-content: center;
    margin-left: 12px;
  }

  .signupPageentityLocationForm > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .signupPageentityLocationForm > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .signupPageentityLocationForm > div:nth-child(2) > div:first-child {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    justify-content: center;
  }

  .signupPageentityLocationForm > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    grid-row-gap: 5px;
    row-gap: 5px;
  }

  .signupPageentityLocationForm > div:nth-child(3) {
    margin: auto;
    position: relative;
    bottom: 5px;
    left: 5px;
  }

  .signupPageentityLocationForm input {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    width: 345px;
    height: 44px;
    padding-left: 2%;
  }

  .signupPageentityLocationForm label {
    font-weight: 400;
    font-size: 18px;
  }

  .signupPagecausesExtends > div:nth-child(1) {
    font-weight: 600;
    font-size: 16px;
  }

  .signupPageuserSignUpForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 25px;
    row-gap: 25px;
  }

  .signupPageuserSignUpForm input {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    width: 345px;
    height: 44px;
    padding-left: 2%;
  }

  /* Space out each form block */
  .signupPageuserSignUpForm > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
    row-gap: 25px;
  }

  .signupPageuserSignUpForm > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
  }

  .signupPageuserSignUpForm > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
    row-gap: 25px;
  }

  .signupPageuserSignUpForm > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
  }

  .signupPageuserSignUpForm > div:nth-child(4) {
    display: flex;
    flex-direction: row;
    position: relative;
    bottom: 25px;
    width: 95%;
  }

  .signupPageuserSignUpForm > div:nth-child(4) > div:nth-child(1) input {
    min-width: 135px;
    margin-right: 15px !important;
    padding-left: 15px;
  }

  .signupPageuserSignUpForm > div:nth-child(4) > div:nth-child(n + 1) input {
    width: 90px;
    margin-right: 15px !important;
    padding-left: 15px;
  }

  .signupPageuserSignUpForm .signupPagepaginationButtons {
    position: relative;
    top: 105px;
  }

  .signupPagecauseExtends {
    margin: 0 auto;
    width: 50%;
  }

  .signupPagecausesText {
    margin: 0 auto;
    width: 80%;
  }

  .signupPagecausesDiv {
    margin: 0 auto;
    width: 95%;
  }

  .signupPagecausesExtends .signupPagepaginationButtons {
    position: relative;
    bottom: 25px;
    left: 5px;
    margin: 0 auto;
    width: 50%;
  }

  .signupPageavailabilityFormInput .signupPagepaginationButtons {
    margin: 0 auto;
  }

  .signupPageavailabilityFormInput {
    padding-left: 37px;
  }

  /* Set the causes display as a column flex */
  .signupPagecausesDiv .causeRender {
    width: auto;
    display: flex;
    flex-direction: column;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-row-gap: 16px;
    row-gap: 16px;
  }

  /* Select each individual div within a cause block, flex it */
  .causeRender > div:nth-child(n) > div {
    border: 2px solid #d8d8d8;
    background: #fff;
    border-radius: 8px;
    height: 44px;
    color: #717070;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    margin-right: 15px;
  }

  /* Then make those individual cause blocks, flex rows */
  .causeRender > div:nth-child(n) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-row-gap: 16px;
    row-gap: 16px;
  }

  /* Add some spacing before submit buttons */
  .causeRender > div:last-child {
    margin-bottom: 105px;
  }

  .signupPagecheckboxAll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    bottom: 85px;
  }

  .signupPagecheckboxAll > div:first-child input {
    height: 23px;
    width: 23px;
    margin-right: 8px;
  }

  .signupPagecheckboxAll > div:last-child label {
    position: relative;
    bottom: 2px;
  }

  .signupPageavailabilityDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 2px;
    row-gap: 2px;
    justify-content: center;
  }

  .signupPageavailabilityDisplay input {
    width: 94px;
    height: 40px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPageavailabilityDisplay label {
    font-size: 18px;
    color: #717070;
  }

  .signupPageavailabilityDisplay input[type='checkbox'] {
    width: 24px;
    height: 24px;
    position: relative;
    top: 8px;
  }

  /* The individual day/time rows & spacing between */
  .signupPageavailabilityDisplay > div:nth-child(n) {
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 25px;
    margin-right: 0;
  }

  /* The checkbox day container section */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child {
    display: flex;
  }

  /* Inputs on the left side of each row */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child input {
    padding-right: 0;
    margin-right: 9px;
  }

  /* Labels on the left side of each row */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child label {
    padding-right: 0;
    margin-right: 15px;
    position: relative;
    top: 9px;
    font-size: 14px;
  }

  /* Inputs on the right side of each row */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child {
    display: flex;
    font-size: 15px;
  }

  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child input {
    padding-left: 2%;
  }

  /* Labels on the right side of each row */
  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    input:first-child {
    display: flex;
    margin-right: 8px;
  }

  /* Middle string between both time pickers */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child label {
    display: flex;
    margin-right: 8px;
  }

  /* Next following selectors divide up space between each different day string and time picker */
  .signupPageavailabilityDisplay > div:nth-child(1) > div:first-child {
    margin-right: 23px;
  }
  .signupPageavailabilityDisplay > div:nth-child(2) > div:first-child {
    margin-right: 21px;
  }
  .signupPageavailabilityDisplay > div:nth-child(3) > div:first-child {
    margin-right: 0 !important;
  }
  .signupPageavailabilityDisplay > div:nth-child(4) > div:first-child {
    margin-right: 15px;
  }
  .signupPageavailabilityDisplay > div:nth-child(5) > div:first-child {
    margin-right: 36px;
  }
  .signupPageavailabilityDisplay > div:nth-child(6) > div:first-child {
    margin-right: 16px;
  }
  .signupPageavailabilityDisplay > div:nth-child(7) > div:first-child {
    margin-right: 25px;
  }

  .signupPageavailabilityDisplay > div:last-child {
    margin-bottom: 60px;
  }

  .userSignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    margin: auto;
  }

  .signupPagedobUser {
    position: relative;
    right: 123px;
  }

  .loginPagepasswordIcon {
    position: relative;
    margin-top: 5px;
    left: 310px;
    height: 22px;
    width: 22px;
  }

  .causesPickedBlue {
    background-color: #2c77e7;
    color: #fff;
  }

  .stepOnesignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 109px;
    padding-top: 10px;
    width: 40%;
  }

  .stepTwosignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 109px;
    padding-top: 10px;
    width: 40%;
  }

  .stepTwosignUpFormUser {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 109px;
    padding-top: 10px;
    width: 40%;
  }

  .stepThreesignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 115px;
    padding-top: 10px;
    width: 40%;
  }

  .stepThreesignUpForm.entity {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 105px;
    padding-top: 10px;
    width: 40%;
  }

  .stepFoursignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 165px;
    padding-top: 10px;
    width: 30%;
  }

  .stepFivesignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 118px;
    padding-top: 10px;
    width: 40%;
  }

  .signupPagemonth .dropDownArrowPlacement {
    position: relative;
    bottom: 38px;
    left: 107px;
    height: 10px;
  }

  .signupPageyear .dropDownArrowPlacement {
    position: relative;
    bottom: 38px;
    left: 63px;
    height: 10px;
  }

  .signupPageday .dropDownArrowPlacement {
    position: relative;
    bottom: 38px;
    left: 63px;
    height: 10px;
  }

  .signupPagemonth .dropDownContent {
    max-height: 138px;
    width: 135px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: relative;
    bottom: 32px;
  }

  .signupPageyear .dropDownContent {
    max-height: 138px;
    width: 90px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: relative;
    bottom: 32px;
  }

  .signupPageday .dropDownContent {
    max-height: 138px;
    width: 90px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: relative;
    bottom: 32px;
  }

  .dropDownContent {
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-width: auto;
    z-index: 5;
  }

  .dropDownContent p {
    margin-left: 14px;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.am {
    max-height: 128px;
    width: 95px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: absolute;
    margin: 37px 35px 0px 0px;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.pm {
    max-height: 128px;
    width: 95px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: absolute;
    margin: 37px 0px 0px 148px;
  }

  .amdropDownArrowPlacement {
    position: relative;
    left: -29px;
    top: 16px;
    height: 10px;
  }

  .pmdropDownArrowPlacement {
    position: relative;
    left: -47px;
    top: 16px;
    height: 10px;
  }

  .signupPageentityLocationForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageuserSignUpForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .entitySignupForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageuserForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .blueText {
    color: #2c77e7;
  }
}

@media (min-width: 561px) and (max-width: 1049px) {
  .signupPagenavBar:first-child {
    max-height: 119px;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: hidden;
  }

  .signupPagenavBarContents {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
    margin: auto;
  }

  .signupPagesignUpArea {
    margin: auto;
    display: flex;
  }

  .signupPageactionText {
    padding-top: 16px;
    padding-right: 24px;
    font-weight: 500;
    -webkit-columns: #263238;
            columns: #263238;
  }

  .signupPagesignUpArea p {
    font-size: 18px;
  }

  .signupPagelogo {
    display: inline-flex;
    position: relative;
  }

  .signupPagenavbarLogo {
    margin-left: 36px;
  }

  .signupPageloginButton {
    width: 178px;
    height: 44px;
    background-color: #faf9f9;
    color: blue;
    text-decoration: underline;
    text-align: center;
    border-radius: 16px;
    border: none;
    outline: none;
    margin: 6px;
    font-size: 20px;
  }

  .signupPageformContainer {
    min-height: 812px;
    padding-top: 30px;
    margin: auto;
  }

  .signupPagesignupModal {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signupPagetitleSignup {
    font-weight: bold;
    font-size: 32px;
    line-height: 44px;
    padding-top: 40px;
  }

  .signupPagesignupForm {
    position: relative;
    margin: auto;
    width: 95%;
  }

  .signupPageformPlacement {
    width: 100%;
    margin: auto;
  }

  .signupPageentityText {
    padding-bottom: 120px;
    margin: auto;
    text-align: center;
    font-weight: 600;
  }

  .signupPagestepOne {
    width: 100%;
    margin: auto;
  }

  .signupPagesignupForm .signupPagestepOne .signupPagenextButton {
    float: right;
    right: -7%;
    top: 220px;
  }

  .signupPagepreviousButton {
    width: 165px;
    height: 44px;
    background-color: #faf9f9;
    color: blue;
    text-align: center;
    border-radius: 16px;
    border: 2px solid #2c77e7;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagepreviousButton {
    color: #2c77e7;
  }

  .signupPagenextButton {
    position: relative;
    width: 165px;
    height: 44px;
    background-color: rgba(44, 119, 231, 0.7);
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagenextButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    line-height: 23px;
    font-weight: 700;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .signupPagepaginationButtons {
    min-width: 378px;
    display: flex;
    flex-direction: row;
    grid-column-gap: 6%;
    -webkit-column-gap: 6%;
            column-gap: 6%;
    margin-top: 185px;
    margin-left: 180px;
  }

  .signupPageindividualDiv {
    margin: auto;
    width: 345px;
    height: 60px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-bottom: 28px;
  }

  .signupPageindividualDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageindividualDiv:focus,
  .signupPageindividualDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageindividualDiv p {
    background-color: #fff;
    margin-left: 17%;
    width: 30%;
    height: 30px;
    position: relative;
    bottom: 33px;
    overflow-y: hidden;
  }

  .signupPageindividualDiv img {
    width: 32px;
    height: 32px;
    margin-left: 15px;
    margin-top: 15px;
  }

  .signupPagenonProfitDiv {
    margin: auto;
    width: 345px;
    height: 60px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-bottom: 28px;
  }

  .signupPagenonProfitDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPagenonProfitDiv:focus,
  .signupPagenonProfitDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPagenonProfitDiv p {
    background-color: #fff;
    margin-left: 18%;
    width: 30%;
    height: 28px;
    position: relative;
    bottom: 32px;
    overflow-y: hidden;
  }

  .signupPagenonProfitDiv img {
    width: 32px;
    height: 32px;
    margin-left: 15px;
    margin-top: 15px;
  }

  .signupPageorganizationDiv {
    margin: auto;
    width: 345px;
    height: 60px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPageorganizationDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageorganizationDiv:focus,
  .signupPageorganizationDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageorganizationDiv p {
    background-color: #fff;
    margin-left: 16%;
    width: 35%;
    height: 44px;
    position: relative;
    bottom: 36px;
    overflow-y: hidden;
  }

  .signupPageorganizationDiv img {
    width: 39px;
    height: 39px;
    margin-left: 8px;
    margin-top: 9px;
  }

  .signupPageOAuthSection {
    width: 377px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .signupPageOAuthButtons {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .fancyLines {
    border: 1px solid #000000;
    height: 2px;
    width: 150px;
  }

  .wordBetweenLines {
    position: relative;
    bottom: 1px;
  }

  .signupPagelines {
    display: flex;
    flex-direction: row;
    grid-column-gap: 24px;
    -webkit-column-gap: 24px;
            column-gap: 24px;
    padding-top: 2px;
    height: 10px;
    justify-content: center;
  }

  .googleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 33px;
    left: 15px;
  }

  .appleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 32px;
    left: 15px;
  }

  .googleOAuthButton {
    width: 372px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .appleOAuthButton {
    width: 372px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .verticalLayoutSignup {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    row-gap: 10px;
    margin-left: 17px;
  }

  .signupPageformPlacement .signupPagepaginationButtons {
    position: relative;
    bottom: 45px;
    right: 83px;
  }

  .signupPagepaginationButtons.userSignupInfo.usersInfoFormSubClass {
    position: relative;
    left: 20px;
    top: 100px;
    margin: 0 auto;
    width: 50%;
  }

  .signupPageuserForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .entitySignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    top: 100px;
    right: 15px;
    margin: 0 auto;
    width: 50%;
  }

  .entitySignupForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .entitySignupForm.signupPagepaginationButtons {
    position: relative;
    left: -96px !important;
  }

  .userSignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    top: 50px;
    left: 20px;
    margin: 0 auto;
    width: 50%;
  }

  .signupPageuserInfoForm {
    min-height: 448px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
    row-gap: 25px;
    align-items: center;
  }

  .signupPageuserInfoForm .wordBetweenLines {
    top: 0.5px;
  }

  .signupPageuserSignUpForm .signupPagedobUser {
    position: relative;
    right: 97%;
  }

  .signupPageemailInput {
    display: flex;
    flex-direction: column;
  }

  .signupPagepasswordInput {
    display: flex;
    flex-direction: column;
  }

  .loginPagepasswordIcon {
    position: relative;
    margin-top: 5px;
    margin-left: 25px;
    height: 22px;
    width: 22px;
  }

  .entitySignupForm.signupPagepaginationButtons {
    position: relative;
    left: 40px;
  }

  .entitySignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    left: -10px;
  }

  .signupPageuserInfoForm input {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    width: 372px;
    height: 44px;
    padding-left: 2%;
    margin-left: 15px;
  }

  .signupPageuserInfoForm label {
    font-weight: 400;
    font-size: 18px;
    margin-left: 15px;
  }

  .signupPageuserSignUpForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageentityLocationForm {
    margin-top: 14px;
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0px;
  }

  .signupPageentityLocationForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageuserSignUpForm .signupPagepaginationButtons {
    position: relative;
    right: 83px;
    bottom: 15px;
  }

  .signupPageentityLocationForm > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .signupPageentityLocationForm > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    row-gap: 20px;
  }

  .signupPageentityLocationForm > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
  }

  .signupPageentityLocationForm > div:nth-child(3) {
    margin: auto;
    position: relative;
    left: 7px;
    top: 297px;
  }

  .signupPageentityLocationForm input {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    width: 353px;
    height: 44px;
    padding-left: 2%;
  }

  .signupPageentityLocationForm label {
    font-weight: 400;
    font-size: 18px;
  }

  .signupPagecausesExtends {
    margin: 0px 110px 0px 110px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signupPagecausesExtends .signupPagepaginationButtons {
    position: relative;
    right: 86px;
    bottom: 84px;
  }

  .signupPagecausesExtends > div:nth-child(1) {
    font-weight: 600;
    font-size: 16px;
  }

  .signupPagecause:hover {
    background: #ffffff;
    border: 1px solid #2c77e7;
    box-sizing: border-box;
    border-radius: 5px;
    color: #2c77e7;
    height: 80%;
  }

  .signupPageuserSignUpForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    grid-row-gap: 20px;
    row-gap: 20px;
    margin-top: 1px;
    margin-left: 22px;
  }

  .signupPageuserSignUpForm .signupPagepaginationButtons {
    margin-top: 27px;
  }

  .signupPageuserSignUpForm input {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    width: 345px;
    height: 44px;
    padding-left: 2%;
  }

  /* Space out each form block */
  .signupPageuserSignUpForm > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
    row-gap: 25px;
  }

  .signupPageuserSignUpForm > div:nth-child(1) > div {
    display: flex;
    flex-direction: column;
  }

  .signupPageuserSignUpForm > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    grid-row-gap: 25px;
    row-gap: 25px;
  }

  .signupPageuserSignUpForm > div:nth-child(2) > div {
    display: flex;
    flex-direction: column;
  }

  .signupPageuserSignUpForm > div:nth-child(4) {
    display: flex;
    flex-direction: row;
    position: relative;
    bottom: 25px;
    width: 95%;
  }

  .signupPageuserSignUpForm > div:nth-child(4) > div:nth-child(1) input {
    min-width: 135px;
    margin-right: 15px !important;
    padding-left: 15px;
  }

  .signupPageuserSignUpForm > div:nth-child(4) > div:nth-child(n + 1) input {
    width: 90px;
    margin-right: 15px !important;
    padding-left: 15px;
  }

  /* Set the causes display as a column flex */
  .signupPagecausesDiv .causeRender {
    width: auto;
    display: flex;
    flex-direction: column;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    grid-row-gap: 16px;
    row-gap: 16px;
  }

  /* Select each individual div within a cause block, flex it */
  .causeRender > div:nth-child(n) > div {
    border: 2px solid #d8d8d8;
    background: #fff;
    border-radius: 8px;
    height: 44px;
    color: #717070;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 6px;
    margin-right: 15px;
  }

  /* Then make those individual cause blocks, flex rows */
  .causeRender > div:nth-child(n) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    grid-row-gap: 16px;
    row-gap: 16px;
  }

  .signupPagecheckboxAll {
    display: flex;
    flex-direction: row;
    align-self: start;
    position: relative;
    top: 25px;
  }

  .signupPagecheckboxAll > div:first-child input {
    height: 23px;
    width: 23px;
    margin-right: 8px;
  }

  .signupPagecheckboxAll > div:last-child label {
    position: relative;
    bottom: 2px;
  }

  .signupPageavailabilityDisplay {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .signupPageavailabilityDisplay input {
    width: 94px;
    height: 40px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPageavailabilityDisplay label {
    font-size: 18px;
    color: #717070;
  }

  .signupPageavailabilityDisplay input[type='checkbox'] {
    width: 24px;
    height: 24px;
    position: relative;
    top: 8px;
  }

  /* The individual day/time rows & spacing between */
  .signupPageavailabilityDisplay > div:nth-child(n) {
    display: flex;
    flex-direction: row;
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 25px;
    margin-right: 0;
  }

  /* The checkbox day container section */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child {
    display: flex;
  }

  /* Inputs on the left side of each row */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child input {
    padding-right: 0;
    margin-right: 9px;
  }

  /* Labels on the left side of each row */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child label {
    padding-right: 0;
    margin-right: 15px;
    position: relative;
    font-size: 14px;
  }

  /* Inputs on the right side of each row */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child {
    display: flex;
    font-size: 15px;
  }

  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child input {
    padding-left: 2%;
  }

  /* Labels on the right side of each row */
  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    input:first-child {
    display: flex;
    margin-right: 8px;
  }

  /* Middle string between both time pickers */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child label {
    display: flex;
    margin-right: 8px;
  }

  /* Next following selectors divide up space between each different day string and time picker */
  .signupPageavailabilityDisplay > div:nth-child(1) > div:first-child {
    margin-right: 23px;
  }
  .signupPageavailabilityDisplay > div:nth-child(2) > div:first-child {
    margin-right: 21px;
  }
  .signupPageavailabilityDisplay > div:nth-child(3) > div:first-child {
    margin-right: 0 !important;
  }
  .signupPageavailabilityDisplay > div:nth-child(4) > div:first-child {
    margin-right: 15px;
  }
  .signupPageavailabilityDisplay > div:nth-child(5) > div:first-child {
    margin-right: 36px;
  }
  .signupPageavailabilityDisplay > div:nth-child(6) > div:first-child {
    margin-right: 16px;
  }
  .signupPageavailabilityDisplay > div:nth-child(7) > div:first-child {
    margin-right: 25px;
  }

  .signupPageavailabilityDisplay > div:last-child {
    margin-bottom: 60px;
  }

  .signupPageavailabilityFormInput .signupPagepaginationButtons {
    justify-content: center;
    position: relative;
    right: 105px;
    bottom: 65px;
  }

  .causesPickedBlue {
    background: #2c77e7;
    color: #fff;
  }

  .stepOnesignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    left: 127px;
    padding-top: 10px;
    width: 40%;
  }

  .stepTwosignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 175px;
    padding-top: 10px;
    width: 35%;
  }

  .stepTwosignUpFormUser {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 130px;
    padding-top: 10px;
    width: 35%;
  }

  .stepThreesignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 121px;
    padding-top: 10px;
    width: 40%;
  }

  .stepThreesignUpForm.entity {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 105px;
    padding-top: 10px;
    width: 40%;
  }

  .stepFoursignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    padding-top: 10px;
    width: 30%;
  }

  .entityFinalStepForm {
    margin-top: 20px;
  }

  .stepFivesignUpForm {
    width: 46px;
    height: 25px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 185px;
    padding-top: 10px;
    width: 30%;
  }

  .signupPagemonth .dropDownArrowPlacement {
    position: relative;
    bottom: 38px;
    left: 107px;
    height: 10px;
  }

  .signupPageyear .dropDownArrowPlacement {
    position: relative;
    bottom: 38px;
    left: 63px;
    height: 10px;
  }

  .signupPageday .dropDownArrowPlacement {
    position: relative;
    bottom: 38px;
    left: 63px;
    height: 10px;
  }

  .signupPagemonth .dropDownContent {
    max-height: 138px;
    width: 135px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: relative;
    bottom: 32px;
  }

  .signupPageyear .dropDownContent {
    max-height: 138px;
    width: 90px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: relative;
    bottom: 32px;
  }

  .signupPageday .dropDownContent {
    max-height: 138px;
    width: 90px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: relative;
    bottom: 32px;
  }

  .dropDownContent {
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-width: auto;
    z-index: 5;
  }

  .dropDownContent p {
    margin-left: 14px;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.am {
    max-height: 128px;
    width: 95px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: absolute;
    margin: 37px 35px 0px 0px;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.pm {
    max-height: 128px;
    width: 95px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    position: absolute;
    margin: 37px 0px 0px 148px;
  }

  .amdropDownArrowPlacement {
    position: relative;
    left: -29px;
    top: 16px;
    height: 10px;
  }

  .pmdropDownArrowPlacement {
    position: relative;
    left: -47px;
    top: 16px;
    height: 10px;
  }

  .blueText {
    color: #2c77e7;
  }
}

@media (min-width: 1050px) {
  /* This entails the shell of the signup process, contents of those components are below */
  .signupPage {
    padding: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .signupPagenavBar {
    max-height: 119px;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: hidden;
  }

  .signupPagenavBarContents {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
    margin: auto;
  }

  .signupPagesignUpArea {
    margin: auto;
    display: flex;
    height: 119px;
    padding-top: 30px;
  }

  .signupPageactionText {
    padding-top: 16px;
    padding-right: 24px;
    font-weight: 500;
    -webkit-columns: #263238;
            columns: #263238;
  }

  .signupPagesignUpArea p {
    font-size: 18px;
  }

  .signupPagelogo {
    display: inline-flex;
    position: relative;
    height: 119px;
  }

  .signupPagenavbarLogo {
    margin-left: 36px;
  }

  .signupPageloginButton {
    width: 178px;
    height: 44px;
    background-color: #faf9f9;
    color: blue;
    text-decoration: underline;
    text-align: center;
    border-radius: 16px;
    border: none;
    outline: none;
    margin: 6px;
    margin-right: 48px;
    font-size: 20px;
  }

  .signupPageformContainer {
    height: 90%;
    margin-bottom: 3%;
    padding-top: 2.25%;
    background: url(/static/media/cta2.f204006a.jpeg) no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.5);
  }

  .signupPagesignupModal {
    margin-left: 9%;
    margin-right: 9%;
    border-radius: 24px;
    background-color: #faf9f9;
    display: flex;
    flex-direction: column;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
    align-items: center;
    font-size: 20px;
    width: 81%;
    height: 91%;
  }

  .signupPagetitleSignup {
    height: 54px;
    width: 900px;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .signupPagetitleSignup p {
    font-size: 38px;
    line-height: 52px;
    font-weight: 600;
    margin-bottom: 0px;
    justify-content: center;
  }

  .signupPagesignupForm {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    min-width: 660px;
  }

  .signupPageformPlacement {
    margin: auto;
  }

  .columnEntityAdjustment input {
    position: relative;
    max-width: 296px !important;
    margin-top: 45px;
  }

  .signupPageformPlacement label {
    position: relative;
    right: 9px;
    padding-bottom: 16px;
  }

  .signupPageemailInput {
    height: 105px;
    float: left;
    font-weight: 600;
  }

  .signupPagepasswordInput {
    height: 112px;
    float: left;
    font-weight: 600;
  }

  .signupPagenextButton {
    width: 178px;
    height: 44px;
    background-color: #2c77e7;
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagenextButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 20px;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .signupPagenextButton:hover {
    background-color: #2c77e8;
  }

  .signupPagepasswordIcon {
    background-color: white;
    float: right;
    height: 22px;
    width: 19px;
    position: relative;
    bottom: 33px;
    right: 12px;
  }

  .signupPagevalidEmailLogin {
    color: #088f26;
    border-color: #088f26;
    border-width: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHJSURBVHgBnZU9TsMwGIZfO6EyTAEuEARIbMAJCFtHGNngBBBxgIod1HbrVrgBa6e2N+iIWpDCAQqZwP1JjB3Tyi1p0/BKkeLYfvz5+wtBhpyy63AOR70zhjD0g3DZerIQAvtaTnv6MTeIjohRBR21uB8EmcCN+92zyLLqcsLBcgWIxR2/7T2aHy1zwMp7JUGtmoQxZMsBIWd2cRPjxkf7j4XsYf8SlNTxD1FE51/+2/MUyMoHLhA35aubg/MEdW2gJCDCdYx2VMBoMhVHXl4Y97uXDMOKAEIC4vC4cKOtVWZScp0XpjJhgLXmNHhUnCQsnSKFz8lqfeLCCM/ABMiROSkt3qQczNwcEAyPJfYqL0yJS0NomhncV7k1A82ETUQZuFlKLlBosrLrGtCVYUy7S6XNfnOuxAJgeDoprVVg0vedgd89tpNRTNoy3l6Kpafq1CyYkqzxanLlxEKq82lujaugq8D0jUatKVBluCzqtMi6K8CkZJP4dc+0OYwa/Re7uK186iGfJKxXmQxmus240W/Zxa13aKuWti/lIgvRxbf/WjO/pzZY3SwijwhyIwgOZ0kygES0VB2nde9UoCldmrqaVM5m/QJ+AAAGzx8yLXqdAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .signupPagevalidPassLogin {
    color: #088f26;
    border-color: #088f26;
    border-width: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAHJSURBVHgBnZU9TsMwGIZfO6EyTAEuEARIbMAJCFtHGNngBBBxgIod1HbrVrgBa6e2N+iIWpDCAQqZwP1JjB3Tyi1p0/BKkeLYfvz5+wtBhpyy63AOR70zhjD0g3DZerIQAvtaTnv6MTeIjohRBR21uB8EmcCN+92zyLLqcsLBcgWIxR2/7T2aHy1zwMp7JUGtmoQxZMsBIWd2cRPjxkf7j4XsYf8SlNTxD1FE51/+2/MUyMoHLhA35aubg/MEdW2gJCDCdYx2VMBoMhVHXl4Y97uXDMOKAEIC4vC4cKOtVWZScp0XpjJhgLXmNHhUnCQsnSKFz8lqfeLCCM/ABMiROSkt3qQczNwcEAyPJfYqL0yJS0NomhncV7k1A82ETUQZuFlKLlBosrLrGtCVYUy7S6XNfnOuxAJgeDoprVVg0vedgd89tpNRTNoy3l6Kpafq1CyYkqzxanLlxEKq82lujaugq8D0jUatKVBluCzqtMi6K8CkZJP4dc+0OYwa/Re7uK186iGfJKxXmQxmus240W/Zxa13aKuWti/lIgvRxbf/WjO/pzZY3SwijwhyIwgOZ0kygES0VB2nde9UoCldmrqaVM5m/QJ+AAAGzx8yLXqdAAAAAElFTkSuQmCC);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .signupPagevalidPassLogin + img {
    right: 50px;
  }

  .signupPageinvalidEmailLogin {
    color: #f00;
    border-color: #f00;
    border-width: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADYSURBVHgBrZXtEYMgDECD1wFwAzpJV3AENmg3oJvVTlA7ASPQDWhyTfUOQeJH7p7+ML4jQqKCSkQADT8oPgqBtUESxCEPJCa8EIsYqaxDQkaU4klckzmBKMWVZHaD7E+XygyXUHrhxpSehzhtnGh1hlnKuZOrYecV9seFLoqXGirJZ777Sl5LK9RwXOiTMNEI88QlS6NtuDf7SuIbeVZyhrHPacsPODZ2VPMwCDsOtp8NCx4KcSM2+xEEpRc7pBjchl4gCrOhsCA1LB4yop4ryTaEEshX/QK+Y/pVkTZ7PfcAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .signupPageinvalidPassLogin {
    color: #f00;
    border-color: #f00;
    border-width: 3px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADYSURBVHgBrZXtEYMgDECD1wFwAzpJV3AENmg3oJvVTlA7ASPQDWhyTfUOQeJH7p7+ML4jQqKCSkQADT8oPgqBtUESxCEPJCa8EIsYqaxDQkaU4klckzmBKMWVZHaD7E+XygyXUHrhxpSehzhtnGh1hlnKuZOrYecV9seFLoqXGirJZ777Sl5LK9RwXOiTMNEI88QlS6NtuDf7SuIbeVZyhrHPacsPODZ2VPMwCDsOtp8NCx4KcSM2+xEEpRc7pBjchl4gCrOhsCA1LB4yop4ryTaEEshX/QK+Y/pVkTZ7PfcAAAAASUVORK5CYII=);
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .signupPageinvalidPassLogin + img {
    right: 50px;
  }

  .signupPage input[type='text'] {
    width: 432px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .signupPage input[type='password'] {
    overflow-y: hidden;
    width: 432px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 30px;
  }

  .signupPage input[type='email'] {
    width: 432px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .signupPage input,
  label {
    margin: auto;
    display: block;
  }

  /* Below here is the first step in the form, user, nonprofit, or organization */

  .signupPagestepOne {
    margin: auto;
    display: flex;
    flex-direction: column;
    grid-row-gap: 15px;
    row-gap: 15px;
  }

  .signupPageentityText {
    height: 30px;
    width: 100%;
    padding-bottom: 19%;
    text-align: center;
  }

  .signupPageentityText p {
    font-weight: 600;
    font-size: 19px;
  }

  .signupPagenonProfitDiv {
    width: 434px;
    height: 61px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
    display: grid;
    background-color: #fff;
    overflow-y: hidden;
  }

  .signupPagenonProfitDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPagenonProfitDiv:focus,
  .signupPagenonProfitDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPagenonProfitDiv p {
    background-color: #fff;
    margin-left: 14%;
    width: 30%;
    height: 28px;
    position: relative;
    bottom: 32px;
    overflow-y: hidden;
  }

  .signupPagenonProfitDiv img {
    width: 32px;
    height: 32px;
    margin-left: 15px;
    margin-top: 13px;
  }

  .signupPageindividualDiv {
    width: 434px;
    height: 61px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
    display: grid;
    background-color: #fff;
    overflow-y: hidden;
  }

  .signupPageindividualDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageindividualDiv:focus,
  .signupPageindividualDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageindividualDiv p {
    background-color: #fff;
    margin-left: 13%;
    width: 30%;
    height: 25px;
    position: relative;
    bottom: 28px;
    overflow-y: hidden;
  }

  .signupPageindividualDiv img {
    width: 32px;
    height: 32px;
    margin-left: 13px;
    margin-top: 13px;
  }

  .signupPageorganizationDiv {
    width: 434px;
    height: 61px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
    display: grid;
    background-color: #fff;
    overflow-y: hidden;
  }

  .signupPageorganizationDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageorganizationDiv:focus,
  .signupPageorganizationDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageorganizationDiv p {
    background-color: #fff;
    margin-left: 13%;
    width: 30%;
    height: 28px;
    position: relative;
    bottom: 33px;
    overflow-y: hidden;
  }

  .signupPageorganizationDiv img {
    width: 37px;
    height: 37px;
    margin-left: 11px;
    margin-top: 10px;
  }

  .signupPageOAuthButtons {
    display: flex;
    flex-direction: column;
    grid-row-gap: 1px;
    row-gap: 1px;
  }

  .fancyLines {
    border: 1px solid #000000;
    height: 144px;
    width: 2px;
  }

  .wordBetweenLines {
    position: relative;
    top: 9px;
    right: 9px;
  }

  .signupPagelines {
    display: flex;
    flex-direction: column;
    grid-row-gap: 9px;
    row-gap: 9px;
    height: 10px;
  }

  .googleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 33px;
    left: 15px;
  }

  .appleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 32px;
    left: 15px;
  }

  .googleOAuthButton {
    width: 296px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .appleOAuthButton {
    width: 296;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .signupPageOAUthButtonVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .signupPageuserInfoForm.entitySignupForm .signupPageOAUthButtonVertical {
    margin-bottom: 10px;
  }

  .signupPagenextButtonEntry {
    margin-top: 16%;
    float: right;
  }

  .signupPagepreviousButton {
    width: 178px;
    height: 44px;
    background-color: #faf9f9;
    color: blue;
    text-align: center;
    border-radius: 16px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.25);
    border: 2px solid #2c77e7;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagepreviousButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 20px;
    width: 56px;
    height: 20px;
    color: #2c77e7;
  }

  .signupPagepreviousButton:hover {
    border: 2px solid #1553b0;
  }

  .signupPagepreviousButton:hover p {
    color: #1553b0;
  }

  .signupPagepaginationButtons {
    max-width: 440px;
    display: flex;
    flex-direction: row;
    grid-column-gap: 16%;
    -webkit-column-gap: 16%;
            column-gap: 16%;
    margin-left: 85px;
    margin-top: 30px;
  }

  .signupPagebuttonLeftColumn {
    margin-top: 16%;
    margin-left: 2%;
    padding-right: 15px;
  }

  .signupPagebuttonRightColumn {
    margin-top: 16%;
    padding-right: 3%;
  }

  .signupPageuserForm input[type='text'] {
    width: 266px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .signupPageuserForm input[type='password'] {
    overflow-y: hidden;
    width: 266px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 30px;
  }

  .signupPageuserForm input[type='email'] {
    width: 266px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .signupPageuserForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageuserInfoForm {
    width: 660px;
    display: flex;
    flex-direction: row;
    grid-column-gap: 34px;
    -webkit-column-gap: 34px;
            column-gap: 34px;
  }

  .signupPageuserInfoForm label {
    margin-left: 10px;
  }

  .signupPageuserInfoForm .signupPageOAUthButtonVertical {
    margin-top: 60px;
  }

  .signupPageuserSignUpForm {
    width: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    justify-content: center;
  }

  .signupPageuserSignUpForm label {
    justify-self: start;
    margin-top: 0;
    padding: 11% 4% 3% 3%;
  }

  .signupPageuserSignUpForm > .signupPagerow:not(:nth-last-child(2)) input {
    padding-left: 10px;
    width: 88%;
  }

  .signupPageuserSignUpForm > .signupPagerow:nth-last-child(2) input {
    padding-left: 10px;
    width: 80%;
  }

  .signupPageuserSignUpForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageentityLocationForm {
    padding-bottom: 20px;
  }

  .signupPageentityLocationForm > .signupPagerow:not(:nth-last-child(2)) input {
    padding-left: 10px;
    width: 88%;
  }

  .signupPageentityLocationForm > .signupPagerow:not(:nth-last-child(2)) label {
    padding-left: 10px;
  }

  .signupPageentityLocationForm > .signupPagerow:nth-last-child(2) input {
    padding-left: 10px;
    width: 432px;
  }

  .signupPageentityLocationForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .userSignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    right: 60px;
    top: 5px;
  }

  .signupPageentityLocationForm {
    width: 465px;
  }

  .entitySignupForm.signupPagepaginationButtons {
    position: relative;
    left: 48px;
    bottom: 15px;
  }

  .entitySignupForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPagerow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .signupPagecolumn {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1 1;
  }

  .signupPagedobUser {
    margin-left: 1%;
    padding-top: 30px !important;
  }

  .signupPagecheckboxAll {
    min-width: 103px;
    min-height: 24px;
    padding-left: 1%;
    display: flex;
    flex-direction: row;
    grid-column-gap: 5px;
    -webkit-column-gap: 5px;
            column-gap: 5px;
  }

  .signupPagecheckboxAll input {
    max-width: 24px;
    max-height: 24px;
    margin-right: 8px;
  }

  .signupPagecheckboxAll p {
    font-size: 16px;
  }

  .signupPagecausesExtends .signupPagepaginationButtons {
    position: relative;
    right: 77px;
    bottom: 1px;
  }

  .signupPagecausesExtends {
    margin: auto;
    width: 837px;
  }

  .signupPagecausesText {
    text-align: center;
    font-weight: 600px;
  }

  .signupPagecausesDiv {
    width: 100%;
    margin-bottom: 5%;
  }

  .causeRender {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    grid-row-gap: 0.75em;
    row-gap: 0.75em;
  }

  .rowOne {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }

  .rowOne p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.25%;
  }

  .rowOne .rowItem3 p {
    margin-top: 2.5%;
  }

  .rowOne div {
    min-height: 44px;
    min-width: 136px;
  }

  .rowTwo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .rowTwo p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.5%;
  }

  .rowTwo div {
    min-height: 44px;
    min-width: 136px;
  }

  .rowThree {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .rowThree p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.5%;
  }

  .rowThree div {
    min-height: 44px;
    min-width: 136px;
  }

  .rowThree.rowItem3 p {
    margin-top: 5% !important;
  }

  .rowFour {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .rowFour p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.5%;
  }

  .rowFour div {
    min-height: 44px;
    min-width: 136px;
  }

  .rowFive {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .rowFive p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.5%;
  }

  .rowFive div {
    min-height: 44px;
    min-width: 136px;
  }

  .signupPagebuttonLeftColumnCauses {
    margin-left: 40%;
  }

  .signupPagebuttonRightColumnCauses {
    margin-left: 12%;
  }

  .signupPagecause {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPagecause:hover {
    cursor: pointer;
  }

  .signupPageEntityName label {
    position: relative;
    right: 160px;
  }

  .signupPageEntityName {
    padding-bottom: 55px;
  }

  .entitySignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    right: 70px;
    top: 50px;
  }

  .signupPageblueText {
    color: #2c77e7;
  }

  .signupPagefirstName input[type='text'] {
    height: 44px;
    width: 193px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
  }

  .signupPagefirstName label {
    margin-left: 7%;
  }

  .signupPagelastName input[type='text'] {
    height: 44px;
    width: 193px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
  }

  .signupPagelastName label {
    margin-left: 7%;
  }

  .signupPagecity input[type='text'] {
    height: 44px;
    width: 193px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
  }
  .signupPagecity label {
    margin-left: 7%;
  }

  .signupPagezipcode input[type='list'] {
    height: 44px;
    width: 193px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
  }

  .signupPagezipcode label {
    margin-left: 7%;
  }

  .signupPagemonth .dropDownContent {
    max-height: 168px;
    width: 141px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
    position: absolute;
  }

  .signupPageday .dropDownContent {
    height: 168px;
    width: 141px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
    position: absolute;
  }

  .signupPageyear .dropDownContent {
    max-height: 168px;
    width: 141px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
    position: absolute;
  }

  .signupPageyear input[type='text'] {
    max-height: 44px;
    width: 133px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
  }

  .signupPageday input[type='text'] {
    max-height: 44px;
    width: 133px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
  }

  .signupPagemonth input[type='text'] {
    max-height: 44px;
    width: 133px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
  }

  .dropDownContent {
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 41px;
    min-width: auto;
    z-index: 5;
  }

  .dropDownContent p {
    margin-left: 14px;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .dropDownArrowPlacement {
    height: 13px;
    width: 19px;
    position: relative;
    left: 125px;
    bottom: 27px;
  }

  .signupPagecause div {
    width: 100%;
    height: 44px;
    background-color: #fff;
    color: #717070;
    text-align: center;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
    outline: none;
    font-size: 16px;
  }

  .signupPagecause {
    margin-left: 5px;
    margin-right: 5px;
  }

  .signupPagecause p {
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 0;
  }

  .blueText {
    color: #2c77e7;
  }

  .signupPageavailabilityFormInput {
    width: 555px;
  }

  .signupPageavailabilityFormInput .signupPagepaginationButtons {
    position: relative;
    right: 55px;
    bottom: 15px;
  }

  .signupPagesignupForm {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    margin: auto;
    min-width: 900px;
    height: 600px;
  }

  .signupPageavailabilityDisplay {
    padding-top: 30px;
    overflow: auto;
  }

  .signupPageavailabilityDisplay input {
    width: 122px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPageavailabilityDisplay label {
    font-size: 18px;
    color: #717070;
  }

  .signupPageavailabilityDisplay input[type='checkbox'] {
    width: 24px;
    height: 24px;
  }

  /* The individual day/time rows & spacing between */
  .signupPageavailabilityDisplay > div:nth-child(n) {
    display: flex;
    flex-direction: row;
    grid-column-gap: 20px;
    -webkit-column-gap: 20px;
            column-gap: 20px;
    margin: auto;
    padding-left: 15px;
    padding-right: 55px;
  }

  /* The spacing between checkbox and the day string */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child label {
    padding-left: 16px;
    padding-top: 16px;
  }

  /* Distance from day string to dropdown time selection */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child {
    position: relative;
    left: 55px;
  }

  /* Select the individual boxes in the row as a flex container */
  .signupPageavailabilityDisplay > div:nth-child(n) > div {
    display: flex;
    padding: 5px 5px 55px 5px;
    margin: auto;
  }

  /* Spacing between time pickers and string "To" */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:nth-child(2) label {
    padding: 4px 16px 0px 16px;
  }

  /* Below selectors target individual timeslots to align to design specs
       Follows the pattern of the display div, the nth-child, and the last child of that child
    */
  .signupPageavailabilityDisplay > div:nth-child(3) > div:last-child {
    left: 27px;
  }

  .signupPageavailabilityDisplay > div:nth-child(4) > div:last-child {
    left: 47px;
  }

  .signupPageavailabilityDisplay > div:nth-child(5) > div:last-child {
    left: 74px;
  }

  .signupPageavailabilityDisplay > div:nth-child(6) > div:last-child {
    left: 51px;
  }

  .signupPageavailabilityDisplay > div:nth-child(7) > div:last-child {
    left: 65px;
  }
  /* End of time selector adjustments */

  .signupPagesignupForm {
    width: 650px;
    overflow-y: scroll;
    scrollbar-width: 50%;
  }

  .usersInfoFormSubClass.signupPagepaginationButtons {
    position: relative;
    left: 45px;
    margin-top: 50px;
  }

  .userInfoSignUpInputs input {
    width: 296px !important;
  }

  .loginPagepasswordIcon {
    position: relative;
    left: 262px;
    bottom: 38px !important;
  }

  .causesPickedBlue {
    background: #2c77e7;
    color: #fff;
    border-radius: 8px;
  }

  .stepTwosignUpForm {
    width: 46px;
    height: 15px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 274px;
    width: 28%;
  }

  .stepTwosignUpFormUser {
    width: 46px;
    height: 15px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 274px;
    width: 28%;
  }

  .stepThreesignUpForm {
    width: 46px;
    height: 15px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 20px;
    left: 170px;
    width: 30%;
  }

  .stepFoursignUpForm {
    width: 186px !important;
    height: 15px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 20px;
    left: 330px;
    width: 50%;
  }

  .stepFivesignUpForm {
    width: 186px !important;
    height: 15px !important;
    align-items: center;
    position: relative;
    margin: 0 auto;
    top: 10px;
    left: 185px;
    width: 50%;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.am {
    max-height: 168px;
    width: 121px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 1px;
    position: absolute;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.pm {
    max-height: 168px;
    width: 121px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 195px;
    position: absolute;
  }

  .amdropDownArrowPlacement {
    position: relative;
    left: -27px;
    top: 16px;
  }

  .pmdropDownArrowPlacement {
    position: relative;
    left: -27px;
    top: 16px;
  }
}



.notifications {
  background: #d8d8d8;
  height: -webkit-max-content;
  height: max-content;
  position: relative;
}

#nav-alone {
  width: 100%;
  height: 120px;
  margin-left: 0em;
  z-index: 1;
}

#notification-stand-alone {
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  height: 75px;
  background: #faf9f9;
  border: 1px solid #dedede;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0em;
}

#notification-stand-alone h4 {
  position: relative;
  margin: 1em 0.7em;
  color: black;
}

.notification-card {
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  margin-left: 0.9em;
  height: -webkit-min-content;
  height: min-content;
  background: #faf9f9;
  border: 1px solid #dedede;
  transition: vertical ease 2s;
}

#notification-img {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 30px;
  top: 15px;
}

#notification-type {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 50px;
  top: 40px;
}

#card-title-row {
  display: flex;
  justify-content: start;
  margin-left: 0.75em;
  margin-top: -0.5em;
}

#card-title {
  white-space: nowrap;
  font-size: 13px;
}

#card-text-row {
  display: flex;
  justify-content: start;
  margin-left: 1em;
  width: 95%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #676767;
}

#card-text {
  display: -webkit-box;
  width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#card-text-row a {
  font-size: 10px;
  color: blue;
  font-weight: bold;
  margin-left: 1em;
}

#time {
  position: absolute;
  width: 70px;
  right: 20px;
  top: 10px;
  text-align: center;
}

#notification-dropdown-btn {
  position: absolute;
  background: transparent;
  color: black;
  border: none;
  right: 2px;
  top: 35px;
  width: -webkit-max-content;
  width: max-content;
  height: -webkit-max-content;
  height: max-content;
}

#notification-dropdown {
  position: absolute;
  background-color: #f1f1f1;
  right: 25px;
  top: 55px;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10%;
}

#notification-dropdown a {
  color: black;
  font-weight: 400;
  padding: 5px 5px;
  text-decoration: none;
  text-align: center;
  display: block;
}

.modal-div-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 5;
  background: rgba(0, 0, 0, 0.3);
}

.modal-div {
  position: fixed;
  width: 275px;
  height: 150px;
  border-radius: 5px 5px 5px 5px;
  background-color: white;
  border: grey solid 1px;
  padding: 0.5em;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.modal-div h4 {
  font-size: small;
  text-align: center;
  margin-top: 1em;
  color: black;
}

#modal-btn-yes {
  border: 1px solid #c4c4c4;
  color: #717070;
  width: 75px;
  margin: 2em;
  border-radius: 5px 5px 5px 5px;
  background: transparent;
  padding: 2%;
}

#modal-btn-yes:active {
  background: #088f26;
  color: white;
}

#modal-btn-cancel {
  border: 1px solid #c4c4c4;
  color: #717070;
  width: 75px;
  border-radius: 5px 5px 5px 5px;
  background: transparent;
  padding: 2%;
}

#modal-btn-cancel:active {
  background: #ff0000;
  color: white;
}

.notification-cards {
  height: -webkit-max-content;
  height: max-content;
}

.footer {
  position: absolute;
  bottom: 0;
  margin-left: 0em;
}

.nav-menu-mobile {
  height: 80px;
  min-width: 375px;
  width: 100%;
  background-color: #faf9f9;
  box-shadow: 0px -2px 18px 2px #00000040;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 16px;
  -webkit-user-select: none;
          user-select: none;
}

.nav-menu-mobile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #263238;
  cursor: pointer;
}

.nav-menu-mobile-item p {
  font-family: Open Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: -0.23999999463558197px;
  text-align: center;
  margin: 0;
  color: #c4c4c4;
}

.nav-menu-mobile-item svg {
  height: 22.5px;
  width: 22.5px;

  color: #c4c4c4;
}

/* Active */

.nav-menu-mobile-item-active svg {
  color: #2c77e7;
}
.nav-menu-mobile-item-active p {
  color: #263238;
}

.rounded {
  background: #ff5f2a;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 15%;
  top: 20%;
}

#nav-bar-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.profile-page {
  position: relative;
  background-color: #d8d8d8;
  height: 100vh;
  margin: auto;
}

.header {
  position: absolute;
  top: 0;
}

.search-bar {
  position: absolute;
  width: 100vw;
  top: 125px;
  left: 0;
  background-color: #faf9f9;
}

.summary-carousel {
  position: absolute;
  height: 125px;
  top: 183px;
}

.user-feed {
  position: absolute;
  top: 420px;
  padding-bottom: 15%;
}

.footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
}

.carousel-slide-label {
  color: #000;
  font-family: 'Open Sans';
  font-size: 17px;
  font-weight: 700;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 8px;
}

.carousel-number-container {
  border: 1px solid #fff;
  border-radius: 8px;
  padding-inline: 20px;
  padding-block: 10px;
  -webkit-filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.15));
  background-color: #fff;
  color: #000;
  font-size: 25px;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 700;
}

.carousel-number-title {
  color: #000;
  font-size: 14px;
  font-family: 'Roboto';
  font-weight: 400;
  width: 70px;
  text-align: center;
  margin: auto;
  padding-top: 8px;
}
.prc-dotGroup {
  position: absolute;
  text-align: center;
  bottom: 0;
  padding-top: 8px;
  padding-bottom: 16px;
}
.carousel__dot {
  height: 15px;
  width: 15px;
  margin: 4px;
  border-radius: 50%;
  border: 1px solid black;
}
.carousel__dot--selected {
  background-color: black;
}

.cta-button {
  padding: 12px 16px;
  border: 2px solid #d8d8d8;
  color: #717070;
  box-sizing: border-box;
  height: 45px;
  width: 103px;
  border-radius: 16px;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.cta-button p {
  text-align: center;
  position: relative;
  top: -5px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
}

.cta-active {
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 2px solid #2c77e7;
  background-color: #2c77e7;
  color: #faf9f9;
}

/* icon-button button styles */

.icon-button {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;

  padding: 0;
  border-radius: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background: none;
}

.icon-button-icon-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.icon-button-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button-circle svg {
  width: 24px;
  height: 24px;
  fill: #ffffff;
  stroke-width: 1px;
  stroke: #263238;
}

.icon-button-icon {
  position: relative;
  right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-button-icon svg {
  height: 9px;
  width: 10px;
  left: 1px;
  top: 1px;
  border-radius: 0px;

  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 16.67%;
  fill: #263238;
}

.icon-button-text {
  height: 21px;
  position: relative;
  right: 4px;
}

/* Hover over button */

.icon-button:hover .icon-button-icon svg {
  fill: #ffffff;
}

.icon-button:hover .icon-button-circle svg {
  fill: #263238;
}

/* Active */

.icon-button-active .icon-button-circle svg {
  fill: #263238;
  stroke-width: 1px;
  stroke: #263238;
}

.icon-button-active .icon-button-icon svg {
  fill: #ffffff;
}

.postInteraction {
  font-family: Roboto;
  min-width: 343px;
  width: 100%;
  padding: 8px 16px 8px 16px;
  background-color: #faf9f9;
  -webkit-user-select: none;
          user-select: none;
}

/* top portion of postInteraction section */
.postInteraction-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.postInteraction-info p {
  margin: 0;
}

.postInteraction-likes {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.postInteraction-comments {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* likes count icon */
.postInteraction-likes-icon-wrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
}

.postInteraction-likes-circle {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postInteraction-likes-circle svg {
  width: 24px;
  height: 24px;
  fill: #ff5f2a;
  stroke-width: 1px;
  stroke: #ff5f2a;
}

.postInteraction-likes-icon {
  position: relative;
  right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.postInteraction-likes-icon svg {
  height: 9px;
  width: 10px;
  left: 1px;
  top: 1px;
  border-radius: 0px;

  left: 8.33%;
  right: 8.33%;
  top: 8.33%;
  bottom: 16.67%;
  fill: #ffffff;
}

/* bottom portion of postInteraction */
.postInteraction-buttons {
  display: flex;
  justify-content: space-between;
  padding-top: 17px;
  border-top: 1px solid #263238;
}

.postInteraction-buttons > div + div:hover {
  background-color: #eaeaea;
}

@media (min-width: 250px) and (max-width: 560px) {
  .shareModal {
    height: inherit;
    width: 255px;
    background: #fff;
    border-radius: 8px;
    position: relative;
    bottom: 205px;
    left: 90px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 6px;
    row-gap: 6px;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 1px solid gray;
  }
  .shareLinks {
    display: flex;
    flex-direction: row;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
            column-gap: 15px;
    justify-content: flex-start;
    padding-left: 21px;
  }
  .shareLinks span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
  }
  .shareIcon {
    height: 24px;
    width: 24px;
    background: #fff;
  }
}



@media (min-width: 250px) and (max-width: 560px) {
  .userFeed {
    display: flex;
    flex-direction: column;
    grid-row-gap: 8px;
    row-gap: 8px;
    width: 100vw;
    margin: auto;
    background: #d8d8d8;
  }

  .feedCardOutline {
    display: flex;
    flex-direction: column;
    margin: 0;
    grid-row-gap: 0px;
    row-gap: 0px;
    background-color: #faf9f9;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
  }

  .feedProfileComponent {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid black;
  }

  .feedProfileComponent img {
    width: 28px;
    height: 28px;
    border-radius: 45%;
    margin-bottom: 3px;
  }

  .feedCardTopLine {
    display: flex;
    flex-direction: row;
    grid-row-gap: 15px;
    row-gap: 15px;
    height: 42px;
    width: 344px;
    margin: auto;
  }

  .feedUserInformationComponent {
    position: relative;
    left: 10px;
  }

  .userInformationComponentBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .userInformationComponentBlock > div:first-child {
    width: 251px;
    height: 21px;
  }

  .userInformationComponentBlock > div:first-child p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .userInformationDatePosted {
    height: 21px;
    width: 141px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #717070;
  }

  .userInformationDatePosted p {
    position: relative;
    bottom: 5px;
  }

  .userInformationDatePosted {
    width: 251px;
    height: 21px;
  }

  .userInformationUsername {
    width: 70px;
    height: 21px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .feedContentInteractionComponents {
    margin-bottom: 5px !important;
  }

  .feedContentTypeComponent {
    width: 344px;
    height: 100%;
    margin: auto;
  }

  .feedContentInteractionComponents {
    max-width: 343px;
    max-height: 95px;
    margin: auto;
  }

  .feedContentInteractionComponents p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #717070;
  }

  .feedCardOnlyText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #263238;
    display: inline-block;
  }

  .feedCardOnlyPhoto {
    display: block;
    overflow-x: visible;
  }

  .feedCardOnlyPhoto > div {
    position: relative;
    left: -16px;
    width: 375px;
  }

  .feedCardOnlyPhoto > div img {
    object-fit: contain;
    height: 100%;
    width: 375px;
  }

  .feedCardTextWithPhoto {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .feedCardTextWithPhoto > div:first-child p {
    position: relative;
    margin: auto;
    left: 16px;
    padding-bottom: 8px;
  }

  .feedCardTextWithPhoto > div {
    position: relative;
    left: -16px;
    width: 375px;
  }

  .feedCardTextWithPhoto > div img {
    object-fit: contain;
    height: 100%;
    width: 375px;
  }

  .feedCardMultiplePhotos > div {
    position: relative;
    left: -16px;
    width: 375px;
  }

  .feedCardMultiplePhotos > div img {
    object-fit: contain;
    height: 100%;
    width: 375px;
  }

  .feedCardTextWithMultiplePhotos {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .feedCardTextWithMultiplePhotos > div:first-child p {
    padding-bottom: 8px;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
  }

  .feedCardTextWithMultiplePhotos > div {
    position: relative;
    left: -16px;
    width: 375px;
  }

  .feedCardTextWithMultiplePhotos > div img {
    object-fit: contain;
    height: 100%;
    width: 375px;
  }

  .feedCardErrorLoadingContent {
    border: 2px solid red;
    border-radius: 50%;
  }

  .feedCardPhotoDots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    grid-column-gap: 8px;
    -webkit-column-gap: 8px;
            column-gap: 8px;
    height: 8px;
    margin: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .feedCardPhotoDots > div:nth-child(n) {
    width: 8px;
    height: 8px;
    background: #ffffff;
    border: 1px solid #263238;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .feedCardPhotoDots > div:nth-child(n):active {
    background: #263238;
  }

  .feedCardPhotoDotsActiveSelection {
    background: #263238 !important;
  }

  .seeMoreSpan {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #7a7979;
  }

  .hideSeeMoreSpan {
    display: none;
  }

  .constrictedTextBox {
    max-height: 56px;
  }
}

@media (min-width: 250px) and (max-width: 560px) {
  .searchBarComponent {
    display: flex;
    flex-direction: column;
  }

  .searchBar {
    border: 2px solid gray;
    width: 375px;
    display: flex;
    flex-direction: row;
    grid-column-gap: 4px;
    -webkit-column-gap: 4px;
            column-gap: 4px;
    justify-content: center;
    margin: auto;
  }

  .searchBarProfilePicture img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    top: 5px;
  }

  .searchInputArea {
    display: flex;
    flex-direction: row;
    width: 256px;
  }

  .searchBarLabel {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    position: relative;
    left: 40px;
  }

  .searchBarLabel span {
    position: relative;
    left: 3px;
    bottom: 1px;
  }

  .magnifyingGlass {
    height: 17px;
    width: 17px;
    margin: auto;
  }

  .searchInputArea input[type='text'] {
    width: 256px;
    height: 32px;
    background: #fff;
    border: 1px solid #263238;
    border-radius: 4px;
    font-size: 13px;
    padding-left: 44px;
    font-weight: 400;
    margin-top: 5px;
  }

  .emailIconSearchBar {
    width: 18px;
    height: 14px;
    position: relative;
    top: 15px;
  }

  .grayPlaceholder {
    width: 375px;
    height: 8px;
    background: #d8d8d8;
    margin: auto;
  }

  .searchResults {
    padding: 10px;
    display: flex;
    flex-direction: column;
    grid-row-gap: 16px;
    row-gap: 16px;
    width: 375px;
    height: 586px;
    overflow-y: auto;
    margin: auto;
  }

  .matchingUser {
    display: flex;
    flex-direction: row;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    border: 1px solid #000;
    height: 48px;
    width: auto;
    border-radius: 4px;
  }

  .searchBarProfilePictureResults img {
    width: 50px;
    height: 48px;
    border-radius: 50%;
    position: relative;
    margin: auto;
    left: 5px;
  }

  .matchingUserInformation {
    display: flex;
    flex-direction: column;
  }

  .matchingUserFullName {
    float: left;
    position: relative;
    height: 22px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000;
    left: 6px;
    top: 4px;
  }

  .matchingUserLocation {
    float: left;
    position: relative;

    width: auto;
    height: 16px;
    left: 82px;
    top: 198px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    color: #000000;

    left: 5px;
    top: 4px;
  }

  .showMoreResults {
    width: 200px;
    height: 22px;
    left: 16px;
    top: 494px;

    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;

    color: #2c77e7;
  }
}



.chatFeedCon {
  height: 508px;
  margin: 8px 0 0 0;
  padding: 0 4% 10px 4%;
  background: #ffffff;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
}

.messageBubble {
  background-color: #eaeaea;
  padding: 8px;
  max-width: 290px;
  border-radius: 4px;
  margin: 0 0 0 0;
}

.you {
  align-self: flex-end;
}

.other {
  align-self: flex-start;
}

.you,
.other {
  margin: 9px 0;
}

.otherFlex {
  display: flex;
}

.messageBubble p {
  margin-bottom: 0;
  font-size: 12px;
  line-height: 14.06px;
  font-family: 'Roboto';
}

.mTimeStamp {
  margin-bottom: 0;
  font-size: 12px;
  text-align: start;
}

.other .mTimeStamp {
  margin-left: 58px;
}

.you .mTimeStamp {
  text-align: end;
}

.mProfile {
  border-radius: 50%;
  margin-right: 8px;
}

.formBackDrop {
  background: #ffffff;
  height: 90px;
}

.form-pad {
  padding: 0;
  display: flex;
  margin: 0 auto;
}

.messageInput {
  border: 1px black solid;
  border-radius: 5px;
  margin-right: 8px;
  padding: 0 0 0 10px;
}

.messageBtn {
  border: none;
  background: rgba(0, 0, 0, 0);
}

.convoCont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 16px;
}

.chatProfile {
  border-radius: 50%;
}

.entityCont {
  width: 240px;
}

.entityName,
.entityDesc {
  margin: 0 0 0 0;
}

.chatCont {
  margin: 128px 0 0 0;
}

.mailto {
  text-decoration: underline;
}

.btn {
  padding: 0px;
  background: #ff531a;
  color: #f8f8ff;
  width: 178px;
  height: 44px;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
  border: 0px;
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.image404 {
  display: flex;
  margin: auto;
  height: 400px;
  width: 625px;
  margin-top: 140px;
  object-fit: contain;
}

.titleText {
  font-family: 'Open Sans';
  font-size: 60px;
  font-weight: 700;
  text-align: center;
  margin-top: 60px;
  margin-bottom: 16px;
}

.subTitleText {
  font-family: 'Open Sans';
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 24px;
}

.footerText {
  font-family: 'Roboto';
  font-size: 18px;
  text-align: center;
  margin-inline: auto;
  margin-top: 96px;
  margin-bottom: 65px;
}

/* tablet small/mobile large screen range */
@media screen and (min-width: 425px) and (max-width: 991px) {
  .image404 {
    height: 325px;
    width: 600px;
    margin-top: 150px;
  }

  .titleText {
    font-size: 38px;
  }

  .subTitleText {
    font-size: 20px;
    margin-bottom: 32px;
  }

  .footerText {
    font-size: 18px;
    margin-top: 144px;
  }
}

/* mobile mid screen range */
@media screen and (min-width: 375px) and (max-width: 424px) {
  .image404 {
    height: 150px;
    width: 295px;
    margin-top: 150px;
  }

  .titleText {
    font-size: 30px;
    font-weight: 700;
    margin-top: 44px;
    margin-bottom: 16px;
  }

  .subTitleText {
    font-size: 18px;
    margin-bottom: 32px;
  }

  .footerText {
    font-size: 16px;
    max-width: 270px;
    margin-top: 120px;
  }
}

/* mobile tiny screen range */
@media screen and (min-width: 320px) and (max-width: 374px) {
  .image404 {
    height: 145px;
    width: 290px;
    margin-top: 15px;
  }

  .titleText {
    font-size: 28px;
    font-weight: 700;
    margin-top: 44px;
    margin-bottom: 16px;
  }

  .subTitleText {
    font-size: 16px;
    margin-bottom: 32px;
  }

  .footerText {
    font-size: 14px;
    max-width: 270px;
    margin-top: 80px;
  }
}

body {
  font-family: 'Open Sans';
}

.CreatePostPlaneIconBorder {
  border: 1px solid #263238;
  color: #263238;
  background-color: #fff;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  position: relative;
  z-index: inherit;
}

/* .CreatePostPlaneIconBorder.active {
  border: 1px solid #263238;
  color: #FFF;
  background-color: #263238;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
} */

.CreatePostPlaneIcon {
  position: absolute;
  top: 0.34rem;
  left: 0.37rem;
  width: 0.75rem;
  height: 0.75rem;
  z-index: inherit;
}

@media (max-width: 560px) {
  .CreatePostPageWrapper {
    display: flex;
    flex-direction: column;
  }

  .CreatePostGrayBar {
    height: 8rem;
  }

  .CreatePostContainer {
    position: relative;
  }

  .CreatePostBody {
    padding: 1rem;
    height: 70vh;
    width: 100%;
  }

  .CreatePostHeader {
    display: flex;
    justify-content: space-between;
  }

  .CreatePostTitle {
    font-weight: 700;
    font-size: 1.25rem;
  }

  .CreatePostBackButton {
  }

  .CreatePostXIcon {
    color: #000;
    height: 1.25rem;
    width: 1.25rem;
  }

  .CreatePostDetails {
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 0 0 0;
  }

  .CreatePostProfileImgWrapper {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    overflow: hidden;
  }

  .CreatePostProfileImg {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .CreatePostUserDetails {
    margin: 0;
    padding: 0 0 0 0.5rem;
  }

  .CreatePostUsername {
    font-size: 1rem;
    color: #000;
    margin: 0;
    padding: 0;
  }

  .CreatePostTime {
    font-size: 0.75rem;
    color: #717070;
    margin: 0;
    padding: 0;
  }

  .CreatePostTextComponentWrapper {
    width: 100%;
    padding: 1rem 0;
  }

  .CreatePostButtons {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .CreatePostCameraWrapper {
    position: relative;
  }

  .CreatePostCameraButton {
    height: 2.25rem;
    width: 1.25rem;
    margin-left: 0.5rem;
  }

  .CreatePostCameraInner {
    position: absolute;
    left: 0.875rem;
    top: 0.95rem;
  }

  .CreatePostImageSelectButton {
    display: flex;
    padding: 0 0.5rem;
    margin: 0;
  }

  .CreatePostImageSelectText {
    margin: 0;
    padding: 0 0.5rem;
  }

  .CreatePostImageModalWrapper-Visible {
    -webkit-filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.25));
    top: -10rem;
    height: 10rem;
    width: 15rem;
    position: absolute;
    z-index: 2;
  }

  .CreatePostImageModalWrapper-Hidden {
    display: none;
  }

  .CreatePostImageModal {
    height: 9rem;
    background-color: #fff;
    padding: 1rem 0.5rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .CreatePostImageModalTextBubble {
    height: 1.5rem;
    width: 1.5rem;
    top: 8.2rem;
    left: 1rem;
    border-radius: 1px;
    -webkit-transform: matrix(0.7, 0.6, -0.7, 0.7, 0, 0);
            transform: matrix(0.7, 0.6, -0.7, 0.7, 0, 0);
    position: absolute;
    background-color: #fff;
    z-index: 1;
  }

  .CreatePostSubmitButton-Inactive {
    /* text */
    color: #717070;
    font-weight: 400;

    /* body */
    border: 2px solid #d8d8d8;
    background: #fff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 16px;
    padding: 0.5rem 2rem;
  }

  .CreatePostSubmitButton-Active {
    /* text */
    color: #faf9f9;
    font-weight: 700;

    /* body */
    border: none;
    background: #2c77e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 0.5rem 2rem;
  }
}


.CreatePostContainer {
  position: relative;
}

.CreatePostBody {
  background-color: #fff;
  padding: 1rem;
  height: 70vh;
  width: 100%;
}

.CreatePostHeader {
  display: flex;
  justify-content: space-between;
}

.CreatePostTitle {
  font-weight: 700;
  font-size: 1.25rem;
}

.CreatePostBackButton {
}

.CreatePostXIcon {
  color: #000;
  height: 1.25rem;
  width: 1.25rem;
}

.CreatePostDetails {
  display: flex;
  align-items: flex-start;
  padding: 0.5rem 0 0 0;
}

.CreatePostProfileImgWrapper {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  overflow: hidden;
}

.CreatePostProfileImg {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.CreatePostUserDetails {
  margin: 0;
  padding: 0 0 0 0.5rem;
}

.CreatePostUsername {
  font-size: 1rem;
  color: #000;
  margin: 0;
  padding: 0;
}

.CreatePostTime {
  font-size: 0.75rem;
  color: #717070;
  margin: 0;
  padding: 0;
}

.CreatePostTextComponentWrapper {
  width: 100%;
  padding: 1rem 0;
}

.CreatePostButtons {
  display: flex;
  justify-content: space-between;
  position: relative;
}

.CreatePostCameraButton {
  height: 2.25rem;
  width: 1.25rem;
  /* padding: 0 0 0 0.5rem; */
  margin-left: 0.5rem;
}

.CreatePostImageSelectButton {
  display: flex;
  padding: 0 0.5rem;
  margin: 0;
}

.CreatePostImageSelectText {
  margin: 0;
  padding: 0 0.5rem;
}

.CreatePostImageModalWrapper-Visible {
  -webkit-filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.25));
  top: -10rem;
  height: 10rem;
  width: 15rem;
  position: absolute;
  z-index: 2;
}

.CreatePostImageModalWrapper-Hidden {
  display: none;
}

.CreatePostImageModal {
  height: 9rem;
  background-color: #fff;
  padding: 1rem 0.5rem;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.CreatePostImageModalTextBubble {
  height: 1.5rem;
  width: 1.5rem;
  top: 8.2rem;
  left: 1rem;
  border-radius: 1px;
  -webkit-transform: matrix(0.7, 0.6, -0.7, 0.7, 0, 0);
          transform: matrix(0.7, 0.6, -0.7, 0.7, 0, 0);
  position: absolute;
  background-color: #fff;
  z-index: 1;
}

.CreatePostSubmitButton-Inactive {
  /* text */
  color: #717070;
  font-weight: 400;

  /* body */
  border: 2px solid #d8d8d8;
  background: #fff;
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
  border-radius: 16px;
  padding: 0.5rem 2rem;
}

.CreatePostSubmitButton-Active {
  /* text */
  color: #faf9f9;
  font-weight: 700;

  /* body */
  border: none;
  background: #2c77e7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding: 0.5rem 2rem;
}

.CreatePostTextAreaWrapper {
  border: solid 1px #263238;
  border-radius: 8px;
  width: 100%;
  box-sizing: border-box;
  padding: 0.5rem;
}

.CreatePostTextArea {
  background-color: transparent;
  color: #000;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
  font-family: 'Roboto';
  max-height: 15rem;

  width: 100%;

  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;

  resize: none;
}

.CreatePostTextArea {
  border: none;
}

.CreatePostTextArea::-webkit-input-placeholder {
  color: #717070;
}

.CreatePostTextArea::placeholder {
  color: #717070;
}

.CreatePostImagesWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
}

.CreatePostImageUpload {
  height: 5rem;
  width: 5rem;
  padding: 0.25rem;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  position: relative;
  /* overflow: hidden; */
}

.CreatePostImageUploadPic {
  height: 100%;
  width: 100%;
  object-fit: fill;
}

.CreatePostRemoveImageUpload {
  position: absolute;
  height: 1.5rem;
  width: 1.5rem;
  background-color: #f00;
  right: -0.2rem;
  top: -0.1rem;
  border-radius: 50%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 600;
}

