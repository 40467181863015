:root {
  --blueBold: #2c77e7;
  --background: #faf9f9;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 200px;
}

body {
  background-color: var(--background);
  font-family: 'Open Sans';
}

h4 {
  color: var(--blueBold);
  font-family: 'Open Sans';
}

.tri {
  font-family: 'Roboto';
}

@media screen and (min-width: 751px) and (max-width: 752px) {
  html,
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 425px) {
  html,
  body {
    overflow-x: hidden;
  }
}

@media screen and (max-width: 375px) {
  html,
  body {
    overflow-x: hidden;
  }
}
