@media (min-width: 250px) and (max-width: 560px) {
  .shareModal {
    height: inherit;
    width: 255px;
    background: #fff;
    border-radius: 8px;
    position: relative;
    bottom: 205px;
    left: 90px;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    justify-content: center;
    padding-top: 6px;
    padding-bottom: 6px;
    border: 1px solid gray;
  }
  .shareLinks {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    justify-content: flex-start;
    padding-left: 21px;
  }
  .shareLinks span {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
  }
  .shareIcon {
    height: 24px;
    width: 24px;
    background: #fff;
  }
}
