@media (max-width: 560px) {
  .CreatePostPageWrapper {
    display: flex;
    flex-direction: column;
  }

  .CreatePostGrayBar {
    height: 8rem;
  }

  .CreatePostContainer {
    position: relative;
  }

  .CreatePostBody {
    padding: 1rem;
    height: 70vh;
    width: 100%;
  }

  .CreatePostHeader {
    display: flex;
    justify-content: space-between;
  }

  .CreatePostTitle {
    font-weight: 700;
    font-size: 1.25rem;
  }

  .CreatePostBackButton {
  }

  .CreatePostXIcon {
    color: #000;
    height: 1.25rem;
    width: 1.25rem;
  }

  .CreatePostDetails {
    display: flex;
    align-items: flex-start;
    padding: 0.5rem 0 0 0;
  }

  .CreatePostProfileImgWrapper {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    overflow: hidden;
  }

  .CreatePostProfileImg {
    height: 100%;
    width: 100%;
    object-fit: fill;
  }

  .CreatePostUserDetails {
    margin: 0;
    padding: 0 0 0 0.5rem;
  }

  .CreatePostUsername {
    font-size: 1rem;
    color: #000;
    margin: 0;
    padding: 0;
  }

  .CreatePostTime {
    font-size: 0.75rem;
    color: #717070;
    margin: 0;
    padding: 0;
  }

  .CreatePostTextComponentWrapper {
    width: 100%;
    padding: 1rem 0;
  }

  .CreatePostButtons {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  .CreatePostCameraWrapper {
    position: relative;
  }

  .CreatePostCameraButton {
    height: 2.25rem;
    width: 1.25rem;
    margin-left: 0.5rem;
  }

  .CreatePostCameraInner {
    position: absolute;
    left: 0.875rem;
    top: 0.95rem;
  }

  .CreatePostImageSelectButton {
    display: flex;
    padding: 0 0.5rem;
    margin: 0;
  }

  .CreatePostImageSelectText {
    margin: 0;
    padding: 0 0.5rem;
  }

  .CreatePostImageModalWrapper-Visible {
    filter: drop-shadow(0px 0px 18px rgba(0, 0, 0, 0.25));
    top: -10rem;
    height: 10rem;
    width: 15rem;
    position: absolute;
    z-index: 2;
  }

  .CreatePostImageModalWrapper-Hidden {
    display: none;
  }

  .CreatePostImageModal {
    height: 9rem;
    background-color: #fff;
    padding: 1rem 0.5rem;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .CreatePostImageModalTextBubble {
    height: 1.5rem;
    width: 1.5rem;
    top: 8.2rem;
    left: 1rem;
    border-radius: 1px;
    transform: matrix(0.7, 0.6, -0.7, 0.7, 0, 0);
    position: absolute;
    background-color: #fff;
    z-index: 1;
  }

  .CreatePostSubmitButton-Inactive {
    /* text */
    color: #717070;
    font-weight: 400;

    /* body */
    border: 2px solid #d8d8d8;
    background: #fff;
    /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */
    border-radius: 16px;
    padding: 0.5rem 2rem;
  }

  .CreatePostSubmitButton-Active {
    /* text */
    color: #faf9f9;
    font-weight: 700;

    /* body */
    border: none;
    background: #2c77e7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 16px;
    padding: 0.5rem 2rem;
  }
}
