.Cta2-hero {
  background-image: linear-gradient(
      92.55deg,
      rgba(44, 119, 231, 0.79) 99.99%,
      rgba(44, 119, 231, 0) 100%
    ),
    url('../../../../assets/img/cta2.png');
  height: 376px;
  background-size: cover;
  margin-top: 168px;
}

.cta2 {
  padding-top: 8rem;
  font-family: 'Open sans';
  font-size: 38px;
  line-height: 51.75px;
}

.cta2-form {
  font-size: 20px;
  line-height: 30px;
  position: relative;
  left: 0px;
  top: 0px;
  padding-left: 30px;
}

.cta2-h2 {
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
}

.cta2-h3 {
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  letter-spacing: 0em;
  text-align: left;
}

.cta2-mobile {
  padding-left: 48px;
}
/* .concen {
  justify-content: unset;
} */

@media screen and (min-width: 1200px) and (max-width: 1350px) {
  .cta2-form {
    padding-left: 0;
    margin-left: 0;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1199px) {
  .cta2 {
    padding-top: 0;
    width: 100%;
  }

  .cta2-mobile {
    margin-top: 40px;
    text-align-last: center;
    width: max-content;
  }

  .cta2-form {
    text-align: -webkit-center;
    margin-top: 90px;
  }

  .Cta2-hero {
    width: 100%;
  }
  /* .concen {
    justify-content: center;
  } */
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .Cta2-hero {
    height: 379px;
    background-position-x: center;
    padding-bottom: 450px;
    padding-top: 50px;
  }

  .cta2 {
    padding-top: 2rem;
    width: 768px;
    padding-left: 32px;
  }

  .cta2-h2,
  .cta2-h3 {
    margin: 0;
    padding: 0;
  }

  .cta2-mobile {
    margin-top: 25px;
    padding-left: 32px;
  }

  .cta2-form {
    margin-top: 60px;
    padding: 0 0 0 30px;
    text-align: unset;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .cta2 {
    padding: 40px 15px;
    width: 100%;
  }
  .cta2-h2 {
    font-size: 32px;
    line-height: 43px;
  }
  .cta2-h3 {
    font-size: 32px;
    line-height: 43px;
  }

  .Cta2-hero {
    height: 468px;
    background-position: 63%;
    width: 100%;
    margin-top: 96px;
  }

  .cta2-mobile {
    margin-top: 0;
    text-align-last: unset;
    padding-left: 30px;
  }

  .cta2-form {
    margin-top: 30px;
    padding: 0 30px;
    text-align: unset;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .cta2 {
    padding: 40px 15px;
    width: 375px;
  }
  .cta2-h2 {
    font-size: 32px;
    line-height: 43px;
  }
  .cta2-h3 {
    font-size: 32px;
    line-height: 43px;
  }
  .Cta2-hero {
    height: 458px;
    background-position: 63%;
    width: 100%;
    margin-top: 48px;
  }
  .cta2-mobile {
    padding: 0;
    margin: 40px 15px;
  }

  .cta2-form {
    margin-top: 0;
    padding: 0 15px;
  }
}

@media screen and (min-width: 320px) and (max-width: 375px) {
  .cta2 {
    padding: 40px 15px;
    width: 375px;
  }
  .cta2-h2 {
    font-size: 30px;
    line-height: 43px;
  }
  .cta2-h3 {
    font-size: 30px;
    line-height: 43px;
  }
  .Cta2-hero {
    height: 458px;
    background-position: 63%;
    width: 100%;
  }
  .cta2-mobile {
    margin-bottom: 50px;
    margin-top: 0;
    padding-left: 5px;
  }

  .cta2-form {
    margin-top: 0;
    padding: 0 15px;
  }
}
