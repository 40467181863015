.CreatePostPlaneIconBorder {
  border: 1px solid #263238;
  color: #263238;
  background-color: #fff;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
  position: relative;
  z-index: inherit;
}

/* .CreatePostPlaneIconBorder.active {
  border: 1px solid #263238;
  color: #FFF;
  background-color: #263238;
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 50%;
} */

.CreatePostPlaneIcon {
  position: absolute;
  top: 0.34rem;
  left: 0.37rem;
  width: 0.75rem;
  height: 0.75rem;
  z-index: inherit;
}
