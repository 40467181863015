.sectionWwd {
  margin: auto;
}

.card {
  border-style: solid;
  border-color: var(--blueBold);
  border-width: 0 0 0 4px;
  border-radius: 0px 16px 16px 0;
  margin: -10% auto;
  padding: 32px 32px 32px 64px;
  height: 200px;
  width: 380px;
  background-color: #ffffff;
}

.auto {
  position: relative;
  left: 2vw;
  margin: 120px 125px;
  margin-bottom: 67px;
}

.tri {
  font-family: 'Roboto';
}

.concen {
  justify-content: center;
}

.shead {
  font-size: 20px;
  font-weight: 600;
}

.bhead {
  font-size: 38px;
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .concen {
    justify-content: center;
  }

  .card {
    margin: -10% 10%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .sectionWwd {
    margin: 100px 0;
  }

  .bhead {
    padding: 0;
    margin: 35px 15px 0 10px;
    font-weight: 500;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: 3px;
  }

  .shead {
    font-weight: 600;
    font-size: 20px;
    line-height: 27px;
  }

  .card {
    margin: 20px auto;
    margin-bottom: 0;
    text-align: left;
    width: 410px;
  }

  .auto {
    margin: 121px 307px 24px 190px;
  }

  .concen {
    text-align: center;
    justify-content: center;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .sectionWwd {
    margin: auto;
  }

  .auto {
    left: 0;
    margin: 20% 30%;
  }

  .card {
    width: 80%;
    height: 164px;
    margin: -10% 10%;
    text-align: left;
    padding: 16px 40px 20px 24px;
  }

  .concen {
    text-align: center;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .sectionWwd {
    margin: auto;
  }

  .auto {
    left: 0;
    margin: 32px 100px;
  }

  .card {
    margin: 0;
    width: 100%;
    padding: 32px;
    padding-left: 28px;
  }

  .shead {
    font-size: 16px;
  }

  .bhead {
    font-size: 32px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .sectionWwd {
    margin: auto;
  }

  .auto {
    left: 0;
    margin: 20% 28%;
  }

  .card {
    margin: 0;
    width: 99%;
    padding: 8px 30px;
  }
}
