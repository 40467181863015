.jumbotron {
  background: linear-gradient(
      90deg,
      #2c77e7 40.46%,
      rgba(30, 108, 226, 0.145595) 54.17%,
      rgba(27, 106, 225, 0) 104.18%,
      rgba(31, 109, 226, 0.216167) 130.95%
    ),
    url('../../../../assets/img/hero-img.svg');
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  height: 645px;
  border: 0px;
  border-radius: 0%;
  padding-left: 0rem;
}

.text-container {
  color: #f8f8ff;
  margin: 32px 0 0 3rem;
  width: 541px;
  height: 305px;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
}

.headline {
  font-style: normal;
  font-size: 38px;
  line-height: 52px;
  margin-bottom: 24px;
}

.headline b {
  font-style: normal;
  font-weight: bolder;
}

.subheading {
  margin-bottom: 24px;
  font-family: 'Roboto';
}

.email-updates {
  font-style: normal;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 24px;
}

input {
  width: 318px;
  height: 44px;
  margin-right: 24px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .jumbotron {
    background: linear-gradient(
        0deg,
        #2c77e7 45.16%,
        rgba(30, 108, 226, 0.145595) 87.49%
      ),
      url('../../../../assets/img/hero-img-new.png');
    height: 949px;
    width: 100%;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .text-container {
    margin: 455px 0 0 48px;
  }

  .headline {
    font-size: 35px;
    line-height: 47.66px;
  }

  .subheading {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 32px;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .jumbotron {
    background: linear-gradient(
        0deg,
        #2c77e7 68.88%,
        rgba(30, 108, 226, 0.145595) 87.49%
      ),
      url('../../../../assets/img/hero-img-new.png');
    width: 100%;
    height: 811.52px;
    background-size: contain;

    /* pushes this down so the navbar doesn't partially 
    cover it */
    margin-top: 90px;
  }

  .text-container {
    margin: 260px 0 0 16px;
    width: 90%;
  }
}

@media screen and (min-width: 375px) and (max-width: 424px) {
  .jumbotron {
    background: linear-gradient(
        0deg,
        #2c77e7 68.88%,
        rgba(30, 108, 226, 0.145595) 87.49%
      ),
      url('../../../../assets/img/hero-img-new.png');
    height: 811px;
    width: 100%;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: contain;
    background-repeat: no-repeat;

    /* pushes this down so the navbar doesn't partially 
    cover it */
    margin-top: 90px;
  }

  .text-container {
    margin: 240px 0 0 16px;
    width: 345px;
    height: 305px;
  }

  .headline {
    font-size: 32px;
    width: 345px;
    margin-inline-start: 0;
  }

  .subheading {
    width: 345px;
    font-size: 16px;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .jumbotron {
    background: linear-gradient(
        0deg,
        #2c77e7 68.88%,
        rgba(30, 108, 226, 0.145595) 87.49%
      ),
      url('../../../../assets/img/hero-img-new.png');
    height: 811px;
    width: 100%;
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: contain;
    background-repeat: no-repeat;
    /* pushes this down so the navbar doesn't partially 
    cover it */
    margin-top: 90px;
  }

  .text-container {
    margin: 210px 0 0 10px;
    width: 300px;
    height: 305px;
  }

  .headline {
    font-size: 26px;
    width: 300px;
    margin-inline-start: 0;
  }

  .subheading {
    width: 300px;
    font-size: 16px;
  }
}
