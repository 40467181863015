.formBackDrop {
  background: #ffffff;
  height: 90px;
}

.form-pad {
  padding: 0;
  display: flex;
  margin: 0 auto;
}

.messageInput {
  border: 1px black solid;
  border-radius: 5px;
  margin-right: 8px;
  padding: 0 0 0 10px;
}

.messageBtn {
  border: none;
  background: rgba(0, 0, 0, 0);
}
