.rounded {
  background: #ff5f2a;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 15%;
  top: 20%;
}

#nav-bar-icon:focus {
  outline: none;
  border: none;
  box-shadow: none;
}
