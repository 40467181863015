nav {
  background-color: #ffffff;
}
/* nav con is how we style the navbar container */
.navbar {
  padding: 32px 42.75px 32px 48px;
  box-shadow: 0px -75px 100px;
}
/* have to use the id to space the navbar */
#basic-navbar-nav {
  justify-content: right;
}

span.navbar-brand {
  padding: 0px;
  margin-left: 0px;
}

.nav-link {
  color: #263238 !important;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
  background: transparent;
  margin-left: 24px;
  transition: 80ms;
}

.nav-link:hover {
  font-weight: 600;
  border-style: solid;
  border-width: 0 0 3px 0;
  border-color: #ff531a;
  border-radius: 0 0 3px 3px;
  height: 42px;
}

.nav-act {
  color: #263238 !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  background: transparent;
  margin-left: 24px;
  border-style: solid;
  border-width: 0 0 3px 0;
  border-color: #ff531a;
  border-radius: 0 0 3px 3px;
  height: 42px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar {
    padding: 24px 48px 24px 48px;
    align-items: center;
  }

  .NavCon {
    margin: 15px;
  }

  .navbar-nav {
    margin: 0px;
    text-align: center;
    justify-content: center;
  }

  .navbar-collapse {
    width: 100vw;
    height: 100vh;
    transition: linear;
    margin-top: 95px;
  }

  .navbar-toggler {
    padding: 0px;
    margin: 0px;
  }

  .nav-link {
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #2c77e7;
  }

  .nav-link:hover {
    font-weight: 600;
    height: 88px;
  }

  span.navbar-brand {
    margin: 0px;
    padding: 0px;
  }

  #no-bottom {
    border-bottom: none;
  }

  html {
    scroll-padding-top: 125px;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .navbar {
    padding: 15px 16px;
    position: fixed;
    top: 0;
    width: 100%;
    /* margin-bottom: 20px; */
  }

  .navbar-collapse {
    width: 100vw;
    height: 100vh;
    transition: linear;
    margin-top: 95px;
  }

  .navbar-toggler {
    border: none;
    font-weight: bolder;
    color: #000000;
  }

  .navbar-nav {
    margin: 0px;
    text-align: center;
    justify-content: center;
  }

  .nav-link {
    margin: 0px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: solid 1px #2c77e7;
  }

  .nav-link:hover {
    height: 88px;
  }
}
