.notifications {
  background: #d8d8d8;
  height: max-content;
  position: relative;
}

#nav-alone {
  width: 100%;
  height: 120px;
  margin-left: 0em;
  z-index: 1;
}

#notification-stand-alone {
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  height: 75px;
  background: #faf9f9;
  border: 1px solid #dedede;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 0em;
}

#notification-stand-alone h4 {
  position: relative;
  margin: 1em 0.7em;
  color: black;
}

.notification-card {
  box-sizing: border-box;
  position: relative;
  width: 100vw;
  margin-left: 0.9em;
  height: min-content;
  background: #faf9f9;
  border: 1px solid #dedede;
  transition: vertical ease 2s;
}

#notification-img {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 30px;
  top: 15px;
}

#notification-type {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 50px;
  top: 40px;
}

#card-title-row {
  display: flex;
  justify-content: start;
  margin-left: 0.75em;
  margin-top: -0.5em;
}

#card-title {
  white-space: nowrap;
  font-size: 13px;
}

#card-text-row {
  display: flex;
  justify-content: start;
  margin-left: 1em;
  width: 95%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #676767;
}

#card-text {
  display: -webkit-box;
  width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#card-text-row a {
  font-size: 10px;
  color: blue;
  font-weight: bold;
  margin-left: 1em;
}

#time {
  position: absolute;
  width: 70px;
  right: 20px;
  top: 10px;
  text-align: center;
}

#notification-dropdown-btn {
  position: absolute;
  background: transparent;
  color: black;
  border: none;
  right: 2px;
  top: 35px;
  width: max-content;
  height: max-content;
}

#notification-dropdown {
  position: absolute;
  background-color: #f1f1f1;
  right: 25px;
  top: 55px;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 10%;
}

#notification-dropdown a {
  color: black;
  font-weight: 400;
  padding: 5px 5px;
  text-decoration: none;
  text-align: center;
  display: block;
}

.modal-div-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 5;
  background: rgba(0, 0, 0, 0.3);
}

.modal-div {
  position: fixed;
  width: 275px;
  height: 150px;
  border-radius: 5px 5px 5px 5px;
  background-color: white;
  border: grey solid 1px;
  padding: 0.5em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-div h4 {
  font-size: small;
  text-align: center;
  margin-top: 1em;
  color: black;
}

#modal-btn-yes {
  border: 1px solid #c4c4c4;
  color: #717070;
  width: 75px;
  margin: 2em;
  border-radius: 5px 5px 5px 5px;
  background: transparent;
  padding: 2%;
}

#modal-btn-yes:active {
  background: #088f26;
  color: white;
}

#modal-btn-cancel {
  border: 1px solid #c4c4c4;
  color: #717070;
  width: 75px;
  border-radius: 5px 5px 5px 5px;
  background: transparent;
  padding: 2%;
}

#modal-btn-cancel:active {
  background: #ff0000;
  color: white;
}

.notification-cards {
  height: max-content;
}

.footer {
  position: absolute;
  bottom: 0;
  margin-left: 0em;
}
