@media (min-width: 250px) and (max-width: 560px) {
  .userFeed {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    width: 100vw;
    margin: auto;
    background: #d8d8d8;
  }

  .feedCardOutline {
    display: flex;
    flex-direction: column;
    margin: 0;
    row-gap: 0px;
    background-color: #faf9f9;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 15px;
  }

  .feedProfileComponent {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid black;
  }

  .feedProfileComponent img {
    width: 28px;
    height: 28px;
    border-radius: 45%;
    margin-bottom: 3px;
  }

  .feedCardTopLine {
    display: flex;
    flex-direction: row;
    row-gap: 15px;
    height: 42px;
    width: 344px;
    margin: auto;
  }

  .feedUserInformationComponent {
    position: relative;
    left: 10px;
  }

  .userInformationComponentBlock {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .userInformationComponentBlock > div:first-child {
    width: 251px;
    height: 21px;
  }

  .userInformationComponentBlock > div:first-child p {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .userInformationDatePosted {
    height: 21px;
    width: 141px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #717070;
  }

  .userInformationDatePosted p {
    position: relative;
    bottom: 5px;
  }

  .userInformationDatePosted {
    width: 251px;
    height: 21px;
  }

  .userInformationUsername {
    width: 70px;
    height: 21px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
  }

  .feedContentInteractionComponents {
    margin-bottom: 5px !important;
  }

  .feedContentTypeComponent {
    width: 344px;
    height: 100%;
    margin: auto;
  }

  .feedContentInteractionComponents {
    max-width: 343px;
    max-height: 95px;
    margin: auto;
  }

  .feedContentInteractionComponents p {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #717070;
  }

  .feedCardOnlyText {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #263238;
    display: inline-block;
  }

  .feedCardOnlyPhoto {
    display: block;
    overflow-x: visible;
  }

  .feedCardOnlyPhoto > div {
    position: relative;
    left: -16px;
    width: 375px;
  }

  .feedCardOnlyPhoto > div img {
    object-fit: contain;
    height: 100%;
    width: 375px;
  }

  .feedCardTextWithPhoto {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .feedCardTextWithPhoto > div:first-child p {
    position: relative;
    margin: auto;
    left: 16px;
    padding-bottom: 8px;
  }

  .feedCardTextWithPhoto > div {
    position: relative;
    left: -16px;
    width: 375px;
  }

  .feedCardTextWithPhoto > div img {
    object-fit: contain;
    height: 100%;
    width: 375px;
  }

  .feedCardMultiplePhotos > div {
    position: relative;
    left: -16px;
    width: 375px;
  }

  .feedCardMultiplePhotos > div img {
    object-fit: contain;
    height: 100%;
    width: 375px;
  }

  .feedCardTextWithMultiplePhotos {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .feedCardTextWithMultiplePhotos > div:first-child p {
    padding-bottom: 8px;
    margin-left: 5%;
    margin-right: 5%;
    justify-content: center;
  }

  .feedCardTextWithMultiplePhotos > div {
    position: relative;
    left: -16px;
    width: 375px;
  }

  .feedCardTextWithMultiplePhotos > div img {
    object-fit: contain;
    height: 100%;
    width: 375px;
  }

  .feedCardErrorLoadingContent {
    border: 2px solid red;
    border-radius: 50%;
  }

  .feedCardPhotoDots {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 8px;
    height: 8px;
    margin: auto;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  .feedCardPhotoDots > div:nth-child(n) {
    width: 8px;
    height: 8px;
    background: #ffffff;
    border: 1px solid #263238;
    border-radius: 50%;
    box-sizing: border-box;
  }

  .feedCardPhotoDots > div:nth-child(n):active {
    background: #263238;
  }

  .feedCardPhotoDotsActiveSelection {
    background: #263238 !important;
  }

  .seeMoreSpan {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: #7a7979;
  }

  .hideSeeMoreSpan {
    display: none;
  }

  .constrictedTextBox {
    max-height: 56px;
  }
}
