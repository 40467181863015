@media (min-width: 1050px) {
  /* This entails the shell of the signup process, contents of those components are below */
  .signupPage {
    padding: 0;
    overflow-y: hidden;
    width: 100%;
    height: 100vh;
  }

  .signupPagenavBar {
    max-height: 119px;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: hidden;
  }

  .signupPagenavBarContents {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
    margin: auto;
  }

  .signupPagesignUpArea {
    margin: auto;
    display: flex;
    height: 119px;
    padding-top: 30px;
  }

  .signupPageactionText {
    padding-top: 16px;
    padding-right: 24px;
    font-weight: 500;
    columns: #263238;
  }

  .signupPagesignUpArea p {
    font-size: 18px;
  }

  .signupPagelogo {
    display: inline-flex;
    position: relative;
    height: 119px;
  }

  .signupPagenavbarLogo {
    margin-left: 36px;
  }

  .signupPageloginButton {
    width: 178px;
    height: 44px;
    background-color: #faf9f9;
    color: blue;
    text-decoration: underline;
    text-align: center;
    border-radius: 16px;
    border: none;
    outline: none;
    margin: 6px;
    margin-right: 48px;
    font-size: 20px;
  }

  .signupPageformContainer {
    height: 90%;
    margin-bottom: 3%;
    padding-top: 2.25%;
    background: url('../../../assets/img/cta2.jpeg') no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    box-shadow: inset 0 0 0 300px rgba(0, 0, 0, 0.5);
  }

  .signupPagesignupModal {
    margin-left: 9%;
    margin-right: 9%;
    border-radius: 24px;
    background-color: #faf9f9;
    display: flex;
    flex-direction: column;
    column-gap: 4px;
    align-items: center;
    font-size: 20px;
    width: 81%;
    height: 91%;
  }

  .signupPagetitleSignup {
    height: 54px;
    width: 900px;
    margin: auto;
    display: flex;
    justify-content: center;
  }

  .signupPagetitleSignup p {
    font-size: 38px;
    line-height: 52px;
    font-weight: 600;
    margin-bottom: 0px;
    justify-content: center;
  }

  .signupPagesignupForm {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    margin-bottom: 0px;
    min-width: 660px;
  }

  .signupPageformPlacement {
    margin: auto;
  }

  .columnEntityAdjustment input {
    position: relative;
    max-width: 296px !important;
    margin-top: 45px;
  }

  .signupPageformPlacement label {
    position: relative;
    right: 9px;
    padding-bottom: 16px;
  }

  .signupPageemailInput {
    height: 105px;
    float: left;
    font-weight: 600;
  }

  .signupPagepasswordInput {
    height: 112px;
    float: left;
    font-weight: 600;
  }

  .signupPagenextButton {
    width: 178px;
    height: 44px;
    background-color: #2c77e7;
    color: white;
    text-align: center;
    border-radius: 16px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.25);
    border: none;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagenextButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 20px;
    width: 56px;
    height: 20px;
    color: #f8f8ff;
  }

  .signupPagenextButton:hover {
    background-color: #2c77e8;
  }

  .signupPagepasswordIcon {
    background-color: white;
    float: right;
    height: 22px;
    width: 19px;
    position: relative;
    bottom: 33px;
    right: 12px;
  }

  .signupPagevalidEmailLogin {
    color: #088f26;
    border-color: #088f26;
    border-width: 3px;
    background-image: url('../../../assets/img/success.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .signupPagevalidPassLogin {
    color: #088f26;
    border-color: #088f26;
    border-width: 3px;
    background-image: url('../../../assets/img/success.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .signupPagevalidPassLogin + img {
    right: 50px;
  }

  .signupPageinvalidEmailLogin {
    color: #f00;
    border-color: #f00;
    border-width: 3px;
    background-image: url('../../../assets/img/warning.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .signupPageinvalidPassLogin {
    color: #f00;
    border-color: #f00;
    border-width: 3px;
    background-image: url('../../../assets/img/warning.png');
    background-repeat: no-repeat;
    background-position: center;
    background-position-x: 390px;
  }

  .signupPageinvalidPassLogin + img {
    right: 50px;
  }

  .signupPage input[type='text'] {
    width: 432px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .signupPage input[type='password'] {
    overflow-y: hidden;
    width: 432px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 30px;
  }

  .signupPage input[type='email'] {
    width: 432px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .signupPage input,
  label {
    margin: auto;
    display: block;
  }

  /* Below here is the first step in the form, user, nonprofit, or organization */

  .signupPagestepOne {
    margin: auto;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
  }

  .signupPageentityText {
    height: 30px;
    width: 100%;
    padding-bottom: 19%;
    text-align: center;
  }

  .signupPageentityText p {
    font-weight: 600;
    font-size: 19px;
  }

  .signupPagenonProfitDiv {
    width: 434px;
    height: 61px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
    display: grid;
    background-color: #fff;
    overflow-y: hidden;
  }

  .signupPagenonProfitDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPagenonProfitDiv:focus,
  .signupPagenonProfitDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPagenonProfitDiv p {
    background-color: #fff;
    margin-left: 14%;
    width: 30%;
    height: 28px;
    position: relative;
    bottom: 32px;
    overflow-y: hidden;
  }

  .signupPagenonProfitDiv img {
    width: 32px;
    height: 32px;
    margin-left: 15px;
    margin-top: 13px;
  }

  .signupPageindividualDiv {
    width: 434px;
    height: 61px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
    display: grid;
    background-color: #fff;
    overflow-y: hidden;
  }

  .signupPageindividualDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageindividualDiv:focus,
  .signupPageindividualDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageindividualDiv p {
    background-color: #fff;
    margin-left: 13%;
    width: 30%;
    height: 25px;
    position: relative;
    bottom: 28px;
    overflow-y: hidden;
  }

  .signupPageindividualDiv img {
    width: 32px;
    height: 32px;
    margin-left: 13px;
    margin-top: 13px;
  }

  .signupPageorganizationDiv {
    width: 434px;
    height: 61px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
    display: grid;
    background-color: #fff;
    overflow-y: hidden;
  }

  .signupPageorganizationDiv:hover {
    border: 3px solid #2c77e7;
  }

  .signupPageorganizationDiv:focus,
  .signupPageorganizationDiv:focus p {
    color: #fff;
    background: #2c77e7;
    border-radius: 8px;
  }

  .signupPageorganizationDiv p {
    background-color: #fff;
    margin-left: 13%;
    width: 30%;
    height: 28px;
    position: relative;
    bottom: 33px;
    overflow-y: hidden;
  }

  .signupPageorganizationDiv img {
    width: 37px;
    height: 37px;
    margin-left: 11px;
    margin-top: 10px;
  }

  .signupPageOAuthButtons {
    display: flex;
    flex-direction: column;
    row-gap: 1px;
  }

  .fancyLines {
    border: 1px solid #000000;
    height: 144px;
    width: 2px;
  }

  .wordBetweenLines {
    position: relative;
    top: 9px;
    right: 9px;
  }

  .signupPagelines {
    display: flex;
    flex-direction: column;
    row-gap: 9px;
    height: 10px;
  }

  .googleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 33px;
    left: 15px;
  }

  .appleIcon {
    height: 24px;
    width: 24px;
    position: relative;
    top: 32px;
    left: 15px;
  }

  .googleOAuthButton {
    width: 296px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .appleOAuthButton {
    width: 296;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    color: #717070;
  }

  .signupPageOAUthButtonVertical {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .signupPageuserInfoForm.entitySignupForm .signupPageOAUthButtonVertical {
    margin-bottom: 10px;
  }

  .signupPagenextButtonEntry {
    margin-top: 16%;
    float: right;
  }

  .signupPagepreviousButton {
    width: 178px;
    height: 44px;
    background-color: #faf9f9;
    color: blue;
    text-align: center;
    border-radius: 16px;
    box-shadow: 1px 3px rgba(0, 0, 0, 0.25);
    border: 2px solid #2c77e7;
    outline: none;
    margin-bottom: 15px;
    font-size: 20px;
  }

  .signupPagepreviousButton p {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
      Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    line-height: 20px;
    width: 56px;
    height: 20px;
    color: #2c77e7;
  }

  .signupPagepreviousButton:hover {
    border: 2px solid #1553b0;
  }

  .signupPagepreviousButton:hover p {
    color: #1553b0;
  }

  .signupPagepaginationButtons {
    max-width: 440px;
    display: flex;
    flex-direction: row;
    column-gap: 16%;
    margin-left: 85px;
    margin-top: 30px;
  }

  .signupPagebuttonLeftColumn {
    margin-top: 16%;
    margin-left: 2%;
    padding-right: 15px;
  }

  .signupPagebuttonRightColumn {
    margin-top: 16%;
    padding-right: 3%;
  }

  .signupPageuserForm input[type='text'] {
    width: 266px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .signupPageuserForm input[type='password'] {
    overflow-y: hidden;
    width: 266px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 30px;
  }

  .signupPageuserForm input[type='email'] {
    width: 266px;
    border-radius: 8px;
    height: 44px;
    padding-left: 10px;
    font-size: 20px;
  }

  .signupPageuserForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageuserInfoForm {
    width: 660px;
    display: flex;
    flex-direction: row;
    column-gap: 34px;
  }

  .signupPageuserInfoForm label {
    margin-left: 10px;
  }

  .signupPageuserInfoForm .signupPageOAUthButtonVertical {
    margin-top: 60px;
  }

  .signupPageuserSignUpForm {
    width: 480px;
    height: 100%;
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    justify-content: center;
  }

  .signupPageuserSignUpForm label {
    justify-self: start;
    margin-top: 0;
    padding: 11% 4% 3% 3%;
  }

  .signupPageuserSignUpForm > .signupPagerow:not(:nth-last-child(2)) input {
    padding-left: 10px;
    width: 88%;
  }

  .signupPageuserSignUpForm > .signupPagerow:nth-last-child(2) input {
    padding-left: 10px;
    width: 80%;
  }

  .signupPageuserSignUpForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPageentityLocationForm {
    padding-bottom: 20px;
  }

  .signupPageentityLocationForm > .signupPagerow:not(:nth-last-child(2)) input {
    padding-left: 10px;
    width: 88%;
  }

  .signupPageentityLocationForm > .signupPagerow:not(:nth-last-child(2)) label {
    padding-left: 10px;
  }

  .signupPageentityLocationForm > .signupPagerow:nth-last-child(2) input {
    padding-left: 10px;
    width: 432px;
  }

  .signupPageentityLocationForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .userSignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    right: 60px;
    top: 5px;
  }

  .signupPageentityLocationForm {
    width: 465px;
  }

  .entitySignupForm.signupPagepaginationButtons {
    position: relative;
    left: 48px;
    bottom: 15px;
  }

  .entitySignupForm input:focus::-webkit-input-placeholder {
    color: transparent;
  }

  .signupPagerow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .signupPagecolumn {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex: 1;
  }

  .signupPagedobUser {
    margin-left: 1%;
    padding-top: 30px !important;
  }

  .signupPagecheckboxAll {
    min-width: 103px;
    min-height: 24px;
    padding-left: 1%;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
  }

  .signupPagecheckboxAll input {
    max-width: 24px;
    max-height: 24px;
    margin-right: 8px;
  }

  .signupPagecheckboxAll p {
    font-size: 16px;
  }

  .signupPagecausesExtends .signupPagepaginationButtons {
    position: relative;
    right: 77px;
    bottom: 1px;
  }

  .signupPagecausesExtends {
    margin: auto;
    width: 837px;
  }

  .signupPagecausesText {
    text-align: center;
    font-weight: 600px;
  }

  .signupPagecausesDiv {
    width: 100%;
    margin-bottom: 5%;
  }

  .causeRender {
    width: 100%;
    display: grid;
    grid-template-rows: repeat(5, 1fr);
    row-gap: 0.75em;
  }

  .rowOne {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
  }

  .rowOne p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.25%;
  }

  .rowOne .rowItem3 p {
    margin-top: 2.5%;
  }

  .rowOne div {
    min-height: 44px;
    min-width: 136px;
  }

  .rowTwo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .rowTwo p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.5%;
  }

  .rowTwo div {
    min-height: 44px;
    min-width: 136px;
  }

  .rowThree {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .rowThree p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.5%;
  }

  .rowThree div {
    min-height: 44px;
    min-width: 136px;
  }

  .rowThree.rowItem3 p {
    margin-top: 5% !important;
  }

  .rowFour {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .rowFour p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.5%;
  }

  .rowFour div {
    min-height: 44px;
    min-width: 136px;
  }

  .rowFive {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .rowFive p {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    margin-top: 4.5%;
  }

  .rowFive div {
    min-height: 44px;
    min-width: 136px;
  }

  .signupPagebuttonLeftColumnCauses {
    margin-left: 40%;
  }

  .signupPagebuttonRightColumnCauses {
    margin-left: 12%;
  }

  .signupPagecause {
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPagecause:hover {
    cursor: pointer;
  }

  .signupPageEntityName label {
    position: relative;
    right: 160px;
  }

  .signupPageEntityName {
    padding-bottom: 55px;
  }

  .entitySignupMoreInfo.signupPagepaginationButtons {
    position: relative;
    right: 70px;
    top: 50px;
  }

  .signupPageblueText {
    color: #2c77e7;
  }

  .signupPagefirstName input[type='text'] {
    height: 44px;
    width: 193px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
  }

  .signupPagefirstName label {
    margin-left: 7%;
  }

  .signupPagelastName input[type='text'] {
    height: 44px;
    width: 193px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
  }

  .signupPagelastName label {
    margin-left: 7%;
  }

  .signupPagecity input[type='text'] {
    height: 44px;
    width: 193px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
  }
  .signupPagecity label {
    margin-left: 7%;
  }

  .signupPagezipcode input[type='list'] {
    height: 44px;
    width: 193px;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
  }

  .signupPagezipcode label {
    margin-left: 7%;
  }

  .signupPagemonth .dropDownContent {
    max-height: 168px;
    width: 141px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
    position: absolute;
  }

  .signupPageday .dropDownContent {
    height: 168px;
    width: 141px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
    position: absolute;
  }

  .signupPageyear .dropDownContent {
    max-height: 168px;
    width: 141px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
    position: absolute;
  }

  .signupPageyear input[type='text'] {
    max-height: 44px;
    width: 133px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
  }

  .signupPageday input[type='text'] {
    max-height: 44px;
    width: 133px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
  }

  .signupPagemonth input[type='text'] {
    max-height: 44px;
    width: 133px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 13px;
  }

  .dropDownContent {
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 41px;
    min-width: auto;
    z-index: 5;
  }

  .dropDownContent p {
    margin-left: 14px;
    margin-top: 3px;
    margin-bottom: 0px;
  }

  .dropDownArrowPlacement {
    height: 13px;
    width: 19px;
    position: relative;
    left: 125px;
    bottom: 27px;
  }

  .signupPagecause div {
    width: 100%;
    height: 44px;
    background-color: #fff;
    color: #717070;
    text-align: center;
    border-radius: 8px;
    border: 2px solid #d8d8d8;
    outline: none;
    font-size: 16px;
  }

  .signupPagecause {
    margin-left: 5px;
    margin-right: 5px;
  }

  .signupPagecause p {
    margin-right: 16px;
    margin-left: 16px;
    margin-bottom: 0;
  }

  .blueText {
    color: #2c77e7;
  }

  .signupPageavailabilityFormInput {
    width: 555px;
  }

  .signupPageavailabilityFormInput .signupPagepaginationButtons {
    position: relative;
    right: 55px;
    bottom: 15px;
  }

  .signupPagesignupForm {
    display: flex;
    flex-direction: column;
    align-content: space-evenly;
    margin: auto;
    min-width: 900px;
    height: 600px;
  }

  .signupPageavailabilityDisplay {
    padding-top: 30px;
    overflow: auto;
  }

  .signupPageavailabilityDisplay input {
    width: 122px;
    height: 44px;
    background: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
  }

  .signupPageavailabilityDisplay label {
    font-size: 18px;
    color: #717070;
  }

  .signupPageavailabilityDisplay input[type='checkbox'] {
    width: 24px;
    height: 24px;
  }

  /* The individual day/time rows & spacing between */
  .signupPageavailabilityDisplay > div:nth-child(n) {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    margin: auto;
    padding-left: 15px;
    padding-right: 55px;
  }

  /* The spacing between checkbox and the day string */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:first-child label {
    padding-left: 16px;
    padding-top: 16px;
  }

  /* Distance from day string to dropdown time selection */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:last-child {
    position: relative;
    left: 55px;
  }

  /* Select the individual boxes in the row as a flex container */
  .signupPageavailabilityDisplay > div:nth-child(n) > div {
    display: flex;
    padding: 5px 5px 55px 5px;
    margin: auto;
  }

  /* Spacing between time pickers and string "To" */
  .signupPageavailabilityDisplay > div:nth-child(n) > div:nth-child(2) label {
    padding: 4px 16px 0px 16px;
  }

  /* Below selectors target individual timeslots to align to design specs
       Follows the pattern of the display div, the nth-child, and the last child of that child
    */
  .signupPageavailabilityDisplay > div:nth-child(3) > div:last-child {
    left: 27px;
  }

  .signupPageavailabilityDisplay > div:nth-child(4) > div:last-child {
    left: 47px;
  }

  .signupPageavailabilityDisplay > div:nth-child(5) > div:last-child {
    left: 74px;
  }

  .signupPageavailabilityDisplay > div:nth-child(6) > div:last-child {
    left: 51px;
  }

  .signupPageavailabilityDisplay > div:nth-child(7) > div:last-child {
    left: 65px;
  }
  /* End of time selector adjustments */

  .signupPagesignupForm {
    width: 650px;
    overflow-y: scroll;
    scrollbar-width: 50%;
  }

  .usersInfoFormSubClass.signupPagepaginationButtons {
    position: relative;
    left: 45px;
    margin-top: 50px;
  }

  .userInfoSignUpInputs input {
    width: 296px !important;
  }

  .loginPagepasswordIcon {
    position: relative;
    left: 262px;
    bottom: 38px !important;
  }

  .causesPickedBlue {
    background: #2c77e7;
    color: #fff;
    border-radius: 8px;
  }

  .stepTwosignUpForm {
    width: 46px;
    height: 15px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 274px;
    width: 28%;
  }

  .stepTwosignUpFormUser {
    width: 46px;
    height: 15px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 30px;
    left: 274px;
    width: 28%;
  }

  .stepThreesignUpForm {
    width: 46px;
    height: 15px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 20px;
    left: 170px;
    width: 30%;
  }

  .stepFoursignUpForm {
    width: 186px !important;
    height: 15px;
    align-items: center;
    position: relative;
    margin: 0 auto;
    bottom: 20px;
    left: 330px;
    width: 50%;
  }

  .stepFivesignUpForm {
    width: 186px !important;
    height: 15px !important;
    align-items: center;
    position: relative;
    margin: 0 auto;
    top: 10px;
    left: 185px;
    width: 50%;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.am {
    max-height: 168px;
    width: 121px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 1px;
    position: absolute;
  }

  .signupPageavailabilityDisplay
    > div:nth-child(n)
    > div:last-child
    > .dropDownContent.pm {
    max-height: 168px;
    width: 121px;
    background-color: #fff;
    border: 2px solid #d8d8d8;
    border-radius: 8px;
    margin-left: 195px;
    position: absolute;
  }

  .amdropDownArrowPlacement {
    position: relative;
    left: -27px;
    top: 16px;
  }

  .pmdropDownArrowPlacement {
    position: relative;
    left: -27px;
    top: 16px;
  }
}
