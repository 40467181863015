.cta-button {
  padding: 12px 16px;
  border: 2px solid #d8d8d8;
  color: #717070;
  box-sizing: border-box;
  height: 45px;
  width: 103px;
  border-radius: 16px;
  cursor: pointer;
  user-select: none;
}

.cta-button p {
  text-align: center;
  position: relative;
  top: -5px;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0px;
}

.cta-active {
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 2px solid #2c77e7;
  background-color: #2c77e7;
  color: #faf9f9;
}
